import React, {useState, useEffect, useCallback} from 'react';
import {
    Text,
    View,
    StyleSheet,
    Modal,
    ActivityIndicator,
    Image,
    ScrollView,
    SafeAreaView, TouchableOpacity
} from 'react-native';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";
import CustomButton from "./CustomButton";
import {ROOT_URL} from "../constants/api";
import he from "he";
import DefiService from "../services/DefiService";
import UserService from "../services/UserService";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import ModalComponent from "./ModalComponent";
import {DefisTypes} from "../constants/defis";
import ModalSearchProfile from "./ModalSearchProfile";
import ErrorNotification from "./ErrorNotification";

function DefierAmiModal({handleCloseModal}) {
    const [error, setError] = useState(null);
    const [listAmis, setListAmis] = useState([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalSearchProfilVisible, setModalSearchProfilVisible] = useState(false);

    const navigation = useNavigation();

    const handleOpenConfirmModal = (user_id) => {
        setOpenConfirmModal(true)
        const user = listAmis.find(item => item.user_id === user_id)

        setSelectedUser(user)
    }

    const handleCloseConfirmModal = (user_id) => {
        setOpenConfirmModal(false)
        setSelectedUser(null)
    }

    const startDefi = async (user_id) => {
        try {
            const getDefiData = await DefiService.createDefi(DefisTypes.DEFIS_FRIEND, user_id)

            if(getDefiData && getDefiData.error){
                setError({code: '3015', message: getDefiData.error})
            }

            if(getDefiData && getDefiData.canStart){
                navigation.navigate('PlayDéfis', { defi: getDefiData });
            }

            setOpenConfirmModal(false)
            handleCloseModal();

        } catch (error) {
            // console.log(error)
            setError({code: 3013})
        }
    };

    useEffect( () => {
        fetchListAmis();
    }, [])



    const handleOpenModalSearchProfil = () => {
        setModalSearchProfilVisible(true)
    }

    const handleCloseSearchProfil = () => {
        setModalSearchProfilVisible(false)
    }


    const fetchListAmis = async () => {
        try {
            const listAmisData = await UserService.getListFriends()
            setListAmis(listAmisData)
        } catch (error) {
            // console.log(error)
            setError({code: 3014})
        }
    }

    const renderAmis = (item, index) => {
        return (
            <View key={index} style={styles.itemRecentDefi}>
                <View style={[styles.containerAvatar]}>
                    {item.user_avatar && (
                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${item.user_avatar.replace('.svg', '.png')}`}}/>
                    )}
                </View>
                <View style={{flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <View>
                        <Text style={styles.nameRecentDefi}>{item.user_name}</Text>
                        <Text style={styles.textInfo}>Pounts : <Text style={styles.textBlue}>{item.user_points}</Text></Text>
                        <Text style={styles.textInfo}>Rang : <Text style={styles.textBlue}>{item.user_rank}</Text></Text>
                    </View>

                    { item.user_defi_en_cours === 1 ? (
                        <Text style={[styles.textBlue, {color: COLORS.red}]}>Défi en cours</Text>
                    ) : (
                        <TouchableOpacity onPress={ () => handleOpenConfirmModal(item.user_id) } style={[styles.buttonRecentDefi, styles.buttonRecentDefiDefier]}>
                            <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black}}>Défier</Text>
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        )
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                <View style={styles.modalContainer}>
                    <View>
                        <View>
                            <Text style={[styles.resultatText]}>Défier un ami</Text>
                            <Text style={[styles.descriptionModal, {color: COLORS.black1}]}>Qui de toi ou de ton ami gagnera le duel ?</Text>
                            <Text style={[styles.descriptionModal]}> 5 questions vous attendent, en cas d’égalité, celui qui aura le meilleur temps remportera le défi. Aucun point n’est à gagner dans ce mode.</Text>
                        </View>
                        <View style={{alignItems: "center", marginTop: 15}}>
                            <View style={{width: "100%", maxWidth: 400, marginBottom: 30}}>
                                <CustomButton
                                    onPress={handleOpenModalSearchProfil}
                                    title="Ajouter des amis"
                                />
                            </View>

                            <View>
                                {listAmis.map((ami, index) => (
                                    renderAmis(ami, index)
                                ))}
                            </View>
                        </View>
                    </View>





                    <CustomButton
                        title={"Fermer"}
                        backgroundColor={COLORS.gray}
                        textColor={COLORS.darkerGray}
                        shadowColor={COLORS.darkGray}
                        borderColor={COLORS.lightGray}
                        onPress={handleCloseModal}
                    />
                </View>

                <ModalComponent
                    visible={openConfirmModal}
                    onClose={handleCloseConfirmModal}
                    title="Lancer le défi ?"
                    titleValid="Démarrer"
                    onValid={() => startDefi(selectedUser?.user_id)}
                >
                    <View style={{justifyContent: "space-between", marginTop: 20, paddingHorizontal: 15}}>
                        <Text style={[styles.resultatText, {color: COLORS.darkerGray}]}>
                            Êtes-vous sûr de vouloir lancer le défi ?
                        </Text>

                        <Text style={{color: COLORS.black, textAlign: "center"}}>
                            <Text style={{textDecorationLine: "underline"}}>{ selectedUser?.user_name } </Text>
                            aura 10 minutes pour répondre à votre demande.
                        </Text>
                    </View>
                </ModalComponent>
            </ScrollView>


            <Modal visible={modalSearchProfilVisible}>
                <ModalSearchProfile onClose={handleCloseSearchProfil} friends={listAmis} />
            </Modal>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    )
}


const styles = StyleSheet.create({
    modalContainer: {
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        paddingVertical: 15
    },
    titleModal: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.lg : SIZES.xl,
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 15 : 20,
        textAlign: 'center'
    },
    descriptionModal: {
        color: COLORS.darkerGray,
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        textAlign: 'center'
    },
    containerResult: {
        position: "relative",
        flex: 1,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: "#FAFAFA",
        paddingVertical: helper.isLittleScreen() ? 15 : 20,
        borderRadius: ROUNDED.lg,
        justifyContent: "center",
        width: "100%"
    },
    boxAvatar: {
        position: "absolute",
        left: 5,
        width: 28,
        height: 28,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
    },
    boxAvatarOpponent: {
        position: "absolute",
        right: 5,
        width: 28,
        height: 28,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
    },
    resultatText: {
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        textAlign: "center"
    },
    containerQuestion: {
        marginBottom: 30
    },
    question: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        fontWeight: FONTS.bold,
        color: COLORS.black,
        marginBottom: 10,
    },
    proposition: {
        textAlign: "center",
        paddingHorizontal: 40,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        marginBottom: 5,
        flexDirection: "row",
        alignItems: "center"
    },
    itemRecentDefi: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        borderRadius: ROUNDED.md,
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginBottom: 15,
    },
    containerAvatar: {
        width: 50,
        height: 50,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: 'hidden',
        marginRight: 10,
        borderWidth: 1,
        borderColor: COLORS.gray
    },
    nameRecentDefi: {
        fontSize: SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    pointsRecentDefi: {
        paddingHorizontal: 7,
        paddingVertical: 2,
        fontSize: SIZES.xs,
        borderRadius: ROUNDED.full,
        color: COLORS.white,
        fontWeight: FONTS.semibold,
    },
    buttonRecentDefi: {
        paddingVertical: 8,
        borderRadius: ROUNDED.md,
        width: 65,
        borderWidth: 1,
    },
    buttonRecentDefiVoir: {
        backgroundColor: COLORS.white,
        borderColor: COLORS.darkGray,
        marginRight: 5
    },
    buttonRecentDefiDefier: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
    textInfo:{
        fontSize: SIZES.xs,
        color: COLORS.black1
    },
    textBlue:{
        fontSize: SIZES.xs,
        fontWeight: FONTS.semibold,
        color: COLORS.blue
    }
});

export default DefierAmiModal;
