export const DefisTypes = {
    DEFIS_RANKED: 1,
    DEFIS_FRIEND: 2,
    DEFIS_TRAIN: 3,
};

export const DefisStatus = {
    STATUT_EN_ATTENTE: 1,
    STATUT_EN_COURS: 2,
    STATUT_FINISHED: 3,
    STATUT_CANCELED: 4,
};

export const getTypeName = (type_id) => {
    if(type_id === DefisTypes.DEFIS_RANKED) {
        return 'Défi classé'
    }
    else if(type_id === DefisTypes.DEFIS_FRIEND) {
        return 'Défi ami'
    }
    else if(type_id === DefisTypes.DEFIS_TRAIN) {
        return 'Entrainement'
    }
    else {
        return '-'
    }
}
