import React, { useRef, useState } from "react";
import { Animated, Dimensions, Easing, Image, Text, View, StyleSheet } from "react-native";
import * as helper from "../utils/helpers";
import {COLORS, FONTS, SIZES} from "../constants/theme";
import CustomButton from "./CustomButton";

const Roue = ({handleTourner, handleClose}) => {
    const spinValue = useRef(new Animated.Value(0)).current;
    const [result, setResult] = useState("-");
    const [hasSpun, setHasSpun] = useState(false)
    const bonus = {1: 'Switch', 2: 'Cible'};

    const wheelValues = [
        {id: 2, quantity: 5},
        {id: 1, quantity: 5},
        {id: 2, quantity: 2},
        {id: 1, quantity: 1},
        {id: 2, quantity: 10},
        {id: 1, quantity: 2},
        {id: 2, quantity: 1},
        {id: 1, quantity: 10}
    ];

    const startSpinning = () => {
        setHasSpun(true);

        spinValue.setValue(0);

        let rotations = Math.floor(Math.random() * (10 - 5 + 1) + 5);
        let randomDegree = Math.floor(Math.random() * 360);
        let finalValue = (rotations * 360 + randomDegree); // Compute the final degree value

        const indexValue = Math.floor((finalValue + 90) % 360 / 45);
        let resultBonus = wheelValues[indexValue]

        handleTourner(resultBonus.id, resultBonus.quantity)

        Animated.timing(spinValue, {
            toValue: (finalValue - 22.5) / 360, // Convert to rotations
            duration: 5000,
            easing: Easing.out(Easing.exp),
            useNativeDriver: true,
        }).start(() => {

            setResult(`Vous avez gagné ${resultBonus.quantity} « ${bonus[resultBonus.id]} »`); // Set the result to the value of the corresponding section
        });
    };



    const spin = spinValue.interpolate({
        inputRange: [0, 1], // Update the input range to reflect one complete rotation
        outputRange: ["0deg", "360deg"], // Update the output range to one complete rotation
    });


    return (
        <View style={styles.container}>
            { result === "-" ? (
                <Text style={styles.title}>Roue de la chance</Text>
            ) : (
                <Text style={[styles.title, {opacity: result.length > 1 ? 1 : 0}]}>{result}</Text>
            )}

            <View style={styles.innerContainer}>
                <View style={styles.absoluteView}>
                    <Image style={styles.targetImage} source={require("../../assets/general/roue_target.png")}/>
                </View>
                <Animated.View style={[styles.animatedView, {transform: [{ rotate: spin }]}]}>
                    <Image style={styles.wheelImage} source={require("../../assets/general/roue_change.png")}/>
                </Animated.View>
            </View>
            <View style={[styles.buttonRow, {justifyContent: hasSpun ? "center" : "space-between"}]}>
                <CustomButton
                    title={'Fermer'}
                    textColor={COLORS.white}
                    backgroundColor={COLORS.red}
                    shadowColor={COLORS.darkRed}
                    borderColor={COLORS.lightRed}
                    width={hasSpun ? '48%' : '48%'}
                    onPress={handleClose}
                />

                { !hasSpun && (
                    <CustomButton
                        title={'Tourner'}
                        width={hasSpun ? '70%' : '48%'}
                        onPress={startSpinning}
                    />
                )}
            </View>
        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        position: "relative",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        maxWidth: 700,
    },
    title: {
        fontWeight: FONTS.bold,
        color: COLORS.white,
        marginBottom: 65,
        fontSize: helper.isLittleScreen() ? SIZES['xl'] : SIZES['2xl']
    },
    innerContainer: {
        position: "relative"
    },
    absoluteView: {
        position: "absolute",
        top: -60,
        left: 0,
        width: 300,
        zIndex: 1,
        alignItems: "center"
    },
    targetImage: {
        width: 14,
        height: 82,
    },
    animatedView: {
        width: 300,
        height: 300,
        borderRadius: (300) / 2,
    },
    wheelImage: {
        width: "100%",
        height: "100%",
        borderRadius: (300) / 2,
    },
    buttonRow: {
        flexDirection: "row",
        width:'100%',
        marginTop: 10
    }
});

export default Roue;
