import axios from 'axios';
import {API_URL} from '../constants/api';
import AsyncStorage from "@react-native-async-storage/async-storage";

const QuestionService = {
    getCategories: async () => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/get_categories`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getQuestionFromLevel: async (levelId) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/question/level/${levelId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setResultQuestion: async (levelId, reponse) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/question/set_reponse`, {
                user_niveau_id: levelId,
                reponse: reponse,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
};

export default QuestionService;
