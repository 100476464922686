import React, {useCallback, useContext, useEffect, useState} from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image, Modal, ScrollView, Linking} from 'react-native';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import {COLORS, SIZES, ROUNDED, FONTS} from "../constants/theme";
import * as helper from "../utils/helpers";
import SoloService from "../services/SoloService";
import {ROOT_URL} from "../constants/api";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import {isLittleScreen} from "../utils/helpers";
import CustomButton from "../components/CustomButton";
import ModalComponent from "../components/ModalComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext  } from "../utils/AuthContext";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import UserService from "../services/UserService";
import ProfileModal from "../components/ProfileModal";
import ModalListFriends from "../components/ModalListFriends";
import ErrorNotification from "../components/ErrorNotification";

function Compte() {
    const [error, setError] = useState(null);
    const { checkLoginStatus } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [friends, setFriends] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleFriends, setModalFriendsVisible] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState('');
    const [toggleMusic, setToggleMusic] = useState(true);
    const [toggleSoundEffect, setToggleSoundEffect] = useState(true);
    const [toggleSoundVoice, setToggleSoundVoice] = useState(true);
    const [checkNotificationPermission, setCheckNotificationPermission] = useState(false);
    const navigation = useNavigation();

    useFocusEffect(
        useCallback(() => {
            setCheckNotificationPermission(('Notification' in window) && ('ServiceWorker' in navigator))
            fetchUserData();
            checkEnabledAudio('isMusiqueMute')
            checkEnabledAudio('isEffectMute')
            checkEnabledAudio('isVoiceMute')

        }, [])
    );

    const handleOpenModalFriends = () => {
        fetchFriendData().then(() => {
            setModalFriendsVisible(true)
        });
    }

    const handleCloseModalFriends = () => {
        setModalFriendsVisible(false)
    }

    const handleCloseModal = () => {
        setModalVisible(false)
        setSelectedSetting('');
    }
    const handleButtonPress = (setting) => {
        setSelectedSetting(setting);
        setModalVisible(true);
    };

    const checkEnabledAudio = async (type) => {
        const enable = await helper.getGlobalBoolean(type)

        switch (type) {
            case 'isMusiqueMute':
                setToggleMusic(enable);
                break;
            case 'isEffectMute':
                setToggleSoundEffect(enable);
                break;
            case 'isVoiceMute':
                setToggleSoundVoice(enable);
                break;
        }
    }

    const handleLogout = async () => {
        try {
            // Clear access token from AsyncStorage
            await AsyncStorage.removeItem('token');
            checkLoginStatus()
        } catch (error) {
            setError({code: 5004})
        }
    };
    const handleToggleAudio = async (type, value) => {
        await helper.setGlobalBooleanValue(type, value)
        await checkEnabledAudio(type)
    }


    const fetchUserData = async () => {
        try {
            const userData = await SoloService.getUser();
            if(userData){
                setUser(userData.user)
            }
            else{
                setError({code: 5003, message: "Les informations du profil n'ont pas pu être chargées. Veuillez réessayer plus tard."})
            }

        } catch (error) {
            setError({code: 5002})
        }
    }

    const fetchFriendData = async () => {
        try {
            const friendsData = await UserService.getListFriends(user.id);
            setFriends(friendsData)

        } catch (error) {
            setError({code: 5001})
        }
    }

    const handleOpenNotification = async () => {
        const permission = await Notification.requestPermission()
    }

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
            <View style={{alignItems: "center"}}>
                 <View style={styles.container}>
                    <Text style={styles.title}>Paramètres</Text>
                    <View style={styles.containerUserAvatar}>
                        {user && user.avatar_path && (
                            <Image
                                style={{width: '100%', height: '100%', resizeMode: "contain"}}
                                source={{ uri: `${ROOT_URL}/storage/${user.avatar_path.replace('.svg', '.png')}` }}
                            />
                        )}
                    </View>

                    <Text style={styles.textName}>{user?.name}</Text>

                    <TouchableOpacity
                        style={styles.containerNav}
                        onPress={() =>  Linking.openURL('https://objectifofficiersp.com/profil')} >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <View style={styles.containerNavIcon}>
                                <FontAwesome name="user" color={COLORS.darkerGray} size={helper.isLittleScreen() ? 12 : 17} />
                            </View>
                            <Text style={styles.navText}>Modifier mon compte</Text>
                            <FontAwesome5 name="external-link-alt" color={COLORS.darkerGray} size={helper.isLittleScreen() ? 8 : 12} style={{marginRight: 5}} />
                        </View>

                        <FontAwesome name="angle-right" color={COLORS.darkGray} size={helper.isLittleScreen() ? 12 : 17} style={{marginRight: 5}} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={styles.containerNav}
                        onPress={handleOpenModalFriends}
                    >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <View style={styles.containerNavIcon}>
                                <FontAwesome
                                    name="users"
                                    color={COLORS.darkerGray}
                                    size={isLittleScreen() ? 12 : 17}
                                />
                            </View>
                            <Text style={styles.navText}>Mes amis</Text>
                        </View>
                        <FontAwesome name="angle-right" color={COLORS.darkGray} size={helper.isLittleScreen() ? 12 : 17} style={{marginRight: 5}} />
                    </TouchableOpacity>

                     {/*<TouchableOpacity*/}
                     {/*    style={styles.containerNav}*/}
                     {/*    onPress={handleOpenNotification}*/}
                     {/*>*/}
                     {/*    <View style={{flexDirection: "row", alignItems: "center"}}>*/}
                     {/*        <View style={styles.containerNavIcon}>*/}
                     {/*            <FontAwesome*/}
                     {/*                name="bell"*/}
                     {/*                color={COLORS.darkerGray}*/}
                     {/*                size={isLittleScreen() ? 12 : 17}*/}
                     {/*            />*/}
                     {/*        </View>*/}
                     {/*        <Text style={styles.navText}>Activer les notifications</Text>*/}
                     {/*    </View>*/}
                     {/*    <FontAwesome name="angle-right" color={COLORS.darkGray} size={helper.isLittleScreen() ? 12 : 17} style={{marginRight: 5}} />*/}
                     {/*</TouchableOpacity>*/}


                    <TouchableOpacity
                        style={styles.containerNav}
                        onPress={() =>  Linking.openURL('https://objectifofficiersp.com/contact')}
                    >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <View style={styles.containerNavIcon}>
                                <FontAwesome
                                    name="envelope"
                                    color={COLORS.darkerGray}
                                    size={isLittleScreen() ? 12 : 17}
                                />
                            </View>
                            <Text style={styles.navText}>Nous contacter</Text>
                            <FontAwesome5 name="external-link-alt" color={COLORS.darkerGray} size={helper.isLittleScreen() ? 8 : 12} style={{marginRight: 5}} />
                        </View>

                        <FontAwesome name="angle-right" color={COLORS.darkGray} size={helper.isLittleScreen() ? 12 : 17} style={{marginRight: 5}} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={styles.containerNav}
                        onPress={() =>  Linking.openURL('https://objectifofficiersp.com/reglement')}
                    >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <View style={styles.containerNavIcon}>
                                <FontAwesome
                                    name="lock"
                                    color={COLORS.darkerGray}
                                    size={isLittleScreen() ? 12 : 17}
                                />
                            </View>
                            <Text style={styles.navText}>Confidentialités - règlement - CGU</Text>
                            <FontAwesome5 name="external-link-alt" color={COLORS.darkerGray} size={helper.isLittleScreen() ? 8 : 12} style={{marginRight: 5}} />
                        </View>

                        <FontAwesome name="angle-right" color={COLORS.darkGray} size={helper.isLittleScreen() ? 12 : 17} style={{marginRight: 5}} />
                    </TouchableOpacity>

                    <View style={{width: '100%'}}>
                        <Text style={styles.titleSection}>Audio</Text>
                        <View style={{ width: '100%'}}>
                            <View style={styles.rowAudio}>
                                <Text style={styles.textRowAudio}>Effet</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isEffectMute', !toggleSoundEffect)}>
                                    <FontAwesome5 name={toggleSoundEffect ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                            <View style={styles.rowAudio}>
                                <Text style={styles.textRowAudio}>Voix</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isVoiceMute', !toggleSoundVoice)}>
                                    <FontAwesome5 name={toggleSoundVoice ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                            <View style={styles.rowAudio}>
                                <Text style={styles.textRowAudio}>Musique</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isMusiqueMute', !toggleMusic)}>
                                    <FontAwesome5 name={toggleMusic ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>


                    <View style={{ alignItems: 'center', width: '100%', marginTop: helper.isLittleScreen() ? 10 : 15}}>
                        <CustomButton
                            onPress={handleLogout}
                            title={'Se déconnecter'}
                            width={'70%'}
                            backgroundColor={COLORS.red}
                            borderColor={COLORS.lightRed}
                            shadowColor={COLORS.darkRed}
                            textColor={COLORS.white}
                        />
                    </View>

                    <View style={{ alignItems: 'center', width: '100%', marginTop: helper.isLittleScreen() ? 10 : 15}}>
                        <TouchableOpacity
                            style={{marginVertical: 30, flexDirection: "row", alignItems: "center"}}
                            onPress={() =>  Linking.openURL('https://objectifofficiersp.com/contact?supprimer')}
                        >
                            <Text style={{textDecorationLine: "underline", marginRight: 5, color: COLORS.darkRed}}>Supprimer mon compte</Text>
                            <FontAwesome5 name="external-link-alt" color={COLORS.darkRed} size={helper.isLittleScreen() ? 8 : 12} style={{marginRight: 5}} />
                        </TouchableOpacity>
                    </View>


                     <Modal visible={modalVisibleFriends}>
                         <ModalListFriends visible={modalVisibleFriends} onClose={handleCloseModalFriends} friends={friends} />
                     </Modal>

                    <ModalComponent
                        visible={modalVisible}
                        onClose={handleCloseModal}
                        title="Mon compte"
                    >
                        {selectedSetting === 'Mon compte' && (
                            // Content for 'Mon compte' button
                            <Text>This is the content of Mon compte.</Text>
                        )}

                        {selectedSetting === 'Mes amis' && (
                            // Content for 'Mes amis' button
                            <Text>This is the content of Mes amis.</Text>
                        )}

                        {selectedSetting === 'Notifications' && (
                            // Content for 'Notifications' button
                            <Text>This is the content of Notifications.</Text>
                        )}

                        {selectedSetting === 'Audio' && (
                            // Content for 'Audio' button
                            <Text>This is the content of Audio.</Text>
                        )}

                        {selectedSetting === 'Nous contacter' && (
                            // Content for 'Nous contacter' button
                            <Text>This is the content of Nous contacter.</Text>
                        )}

                        {selectedSetting === 'Confidentialités' && (
                            // Content for 'Confidentialités' button
                            <Text>This is the content of Confidentialités.</Text>
                        )}
                    </ModalComponent>
                </View>
            </View>


            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 30,
        width: "100%",
        maxWidth: 500,
    },
    title: {
        fontSize: helper.isLittleScreen() ? SIZES['xl'] : SIZES['2xl'],
        fontWeight: FONTS.semibold,
        paddingVertical: helper.isLittleScreen() ? 5 : 8,
        color: COLORS.darkerGray,
        marginBottom: helper.isLittleScreen() ? 4 : 8
    },
    containerUserAvatar: {
        width: helper.isLittleScreen() ? 60 : 70,
        height: helper.isLittleScreen() ? 60 : 70,
        marginBottom: helper.isLittleScreen() ? 5 : 10,
        borderRadius: ROUNDED.full,
        padding: 5,
        borderWidth: 1,
        backgroundColor: COLORS.white,
        borderColor: COLORS.gray,
        overflow: "hidden"
    },
    textName: {
        color: COLORS.black1,
        fontSize: SIZES.xl,
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 15,
    },
    containerNav: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: COLORS.white,
        width: "100%",
        borderRadius: ROUNDED.full,
        paddingVertical: helper.isLittleScreen() ? 8 : 12,
        paddingHorizontal: 10,
        marginBottom: helper.isLittleScreen() ? 5 : 10
    },
    containerNavIcon: {
        width: helper.isLittleScreen() ? 30 : 40,
        height: helper.isLittleScreen() ? 30 : 40,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ROUNDED.full,
        padding: helper.isLittleScreen() ? 4 : 7,
        borderWidth: 1,
        borderColor: COLORS.darkerGray,
        marginRight: 10,
    },
    navText: {
        color: COLORS.darkerGray,
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        marginRight: 5
    },
    containerFriends: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    boxInfoFriend: {
        flexDirection: "row",
        alignItems: "center",
    },
    friendName:{
        fontSize: SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    friendRank:{
        fontSize: SIZES.xs,
        color: COLORS.darkerGray
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.gray,
        marginVertical: helper.isLittleScreen() ? 15 : 20,
        width: "100%"
    },
    containerImageProfil: {
        position: "relative",
        flexDirection: "row",
        width: helper.isLittleScreen() ? 40 : 50,
        height: helper.isLittleScreen() ? 40 : 50,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    avatarContainer: {
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 30 : 40,
        height: helper.isLittleScreen() ? 30 : 40,
        aspectRatio: 1,
        backgroundColor: COLORS.darkGray,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    },
    buttonProfil: {
        paddingVertical: 6,
        borderRadius: ROUNDED.md,
        width: 70,
        borderWidth: 1,
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
    titleSection: {
        fontSize: SIZES.xl,
        fontWeight: FONTS.bold,
        marginTop:10,
        marginBottom:5,
        textAlign: "center"
    },
    rowAudio: {
        flexDirection: "row",
        alignItems: 'center',
        width: '100%',
        justifyContent: "space-between",
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.full,
        marginBottom: 5,
        paddingHorizontal: 15,
        paddingVertical: 3
    },
    textRowAudio: {
        color: COLORS.darkerGray,
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    btnMusic: {
        backgroundColor: COLORS.darkGray,
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        aspectRatio: 1,
        borderRadius: ROUNDED.full
    }
});

export default Compte;
