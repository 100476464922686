import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';

const SelectWithGroups = ({ data, selectedValue, onValueChange }) => {
    return (
        <View>
            <Picker
                selectedValue={selectedValue}
                onValueChange={onValueChange}
                style={styles.picker}
            >
                { data.map((group, index) => (
                    <Picker.Item key={index} label={group.label} value={group.label} enabled={false}>
                        {group.options.map(option => (
                            <Picker.Item key={option.value} label={option.label} value={option.value} />
                        ))}
                    </Picker.Item>
                ))

                }
            </Picker>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        margin: 20,
    },
    picker: {
        width: 200,
        height: 44,
    },
});

export default SelectWithGroups;
