import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

const DefiService = {
    createDefi: async (type_id, user_id = null) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/defi/create`,{
                type: type_id,
                user_id: user_id,
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error createDefi:', error);
        }
    },
    setReponseQuestion: async (defi_question_id, reponse) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/defi/set_reponse`,{
                defi_question_id: defi_question_id,
                reponse: reponse,
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error setReponseQuestion login:', error);
        }
    },
    getRecentDefi: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/defis/recent`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error getRecentDefi login:', error);
        }
    },
    getCurrentDefi: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/defis/current`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error getCurrentDefi login:', error);
        }
    },
    reprendreDefi: async (defi_id, accept = null) => {
        // console.log(defi_id, accept)
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/defis/reprendre`,{
                defi_id: defi_id,
                accepte: accept,
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error reprendreDefi login:', error);
        }
    },
    demandeDefi: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/defis/demande`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error demandeDefi login:', error);
        }
    },
    reponseDemandeDefi: async (defi_id, accept) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/defi/reponse_defi`,{
                defi_id: defi_id,
                accepted: accept,
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error reponseDemandeDefi:', error);
        }
    },
}

export default DefiService;
