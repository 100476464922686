import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
const UserService = {
    checkMobileAccess: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/check_mobile_access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    check_plateau_access: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/check_plateau_access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    check_access: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/check_access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    searchUsers: async (search) => {
        try {
            const encodedSearch = encodeURIComponent(search);
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/search?search=${encodedSearch}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getProfil: async (user_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/get_profil/${user_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getListFriends: async (user_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/friends/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    sendDemandeAmis: async (user_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/send_friend_request`, {
                user_id: user_id
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setReponseDemandeAmis: async (user_id, statut) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/set_friend_request`, {
                user_id: user_id,
                statut: statut,
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
}

export default UserService;
