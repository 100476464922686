import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";

function CustomButton({ children, title, onPress, backgroundColor, borderColor, borderWidth, shadowColor, textColor, width }) {
    const styles = StyleSheet.create({
        container: {
            width: width ? width : '100%',
            position: "relative",
        },
        button: {
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: backgroundColor ? backgroundColor : COLORS.primary,
            borderRadius: ROUNDED.lg,
            width: '100%',
            paddingVertical: helper.isLittleScreen() ? 10 : 15,
            marginVertical: 5,
            borderWidth: borderWidth ? borderWidth : 2,
            borderColor: borderColor ? borderColor : COLORS.lightPrimary,
            position: "relative",
            elevation: 2,
            overflow: "hidden"
        },
        buttonText: {
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            color: textColor ? textColor : COLORS.white,
            fontWeight: FONTS.semibold,
        },
        buttonShadow: {
            position: "absolute",
            bottom: 2,
            backgroundColor: shadowColor ? shadowColor : COLORS.darkPrimary,
            width: '100%',
            borderRadius: ROUNDED.lg,
            paddingVertical: helper.isLittleScreen() ? 10 : 15,
            zIndex: -1,
        },
        buttonShadowText: {
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            fontWeight: FONTS.semibold,
            opacity: 0
        },
        bubble: {
            width: 18,
            height: 18,
            aspectRatio: 1,
            borderRadius: ROUNDED.full,
            backgroundColor: borderColor ? borderColor : COLORS.lightPrimary,
            position: "absolute",
            top: -11,
            left: -3
        },
    });

    return (
        <View style={styles.container}>
            <TouchableOpacity elevation={5} style={styles.button} onPress={onPress}>
                {children}
                <Text style={styles.buttonText}>{title}</Text>
                <View style={styles.bubble}></View>
            </TouchableOpacity>
            <View style={styles.buttonShadow}>
                <Text style={styles.buttonShadowText}>{title}</Text>
            </View>
        </View>
    );
}

export default CustomButton;
