import React, { useState, useEffect } from 'react';
import {View, Text, TouchableOpacity, Modal, Button, Image, StyleSheet} from 'react-native';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";
import FontAwesome from "react-native-vector-icons/FontAwesome";

function HeaderSolo({currentGroup}) {
    const [groupLevel, setGroupLevel] = useState(null);
    const [totalValidGroups, setTotalValidGroups] = useState(0);
    const [points, setPoints] = useState(0);

    useEffect(() => {
        if (currentGroup) {
            if(currentGroup.length > 0){
                const firstGroup = currentGroup[0];
                setGroupLevel(firstGroup);

                const validGroups = currentGroup.reduce((count, group) => {
                    return count + (group.can_open === 1 ? 1 : 0);
                }, 0);

                setTotalValidGroups(validGroups);

                const pointsCount = currentGroup.reduce((sum, group) => {
                    return sum + group.points;
                }, 0);
                setPoints(pointsCount ? pointsCount : 0);
            }
        }
    }, [currentGroup]);

    function renderLevelContent(index, color) {
        if (!currentGroup) {
            return null;
        }

        if (!currentGroup[index]) {
            return null;
        }

        return (
            <View style={[styles.level, {borderColor: color}]}>
                {
                    currentGroup[index]["is_valid"] === null && currentGroup[index]["can_open"] ? (
                        <FontAwesome name="star" size={helper.isLittleScreen() ? 12 : 15} color={color} />
                    ) : currentGroup[index]["is_valid"] === null && !currentGroup[index]["can_open"] ? (
                        <FontAwesome name="lock" size={helper.isLittleScreen() ? 12 : 15} color={COLORS.darkerGray} />
                    ) : currentGroup[index]["is_valid"] == 1 ? (
                        <FontAwesome name="check" size={helper.isLittleScreen() ? 12 : 15} color={color} />
                    ) : (
                        <FontAwesome name="close" size={helper.isLittleScreen() ? 12 : 15} color={color}  />
                    )
                }
            </View>
        );
    }


    return (
        <View style={styles.container}>
            <View style={styles.containerTop}>
                <View style={styles.containerTirelire}>
                    <Image style={{width: 39, height: 27}} source={require('../../assets/general/Tirelire.png')} resizeMode="cover" />
                    <Text style={styles.textTirelire}>{points > 0 ? points : '0'}pts</Text>
                </View>
                <View>
                    <Text style={styles.textNiveau}>Niveau {groupLevel?.group}</Text>
                </View>
                <View style={styles.containerCountLevels}>
                    <Text style={{textAlign: "right"}}>{totalValidGroups - 1} / {currentGroup?.length - 1}</Text>
                </View>
            </View>
            <View style={styles.containerLevels}>
                {renderLevelContent(5, COLORS.blue)}
                <View style={[styles.line, {backgroundColor: currentGroup[4]?.can_open === 1 ? COLORS.darkPrimary : COLORS.darkGray}]}></View>
                {renderLevelContent(4, COLORS.green)}
                <View style={[styles.line, {backgroundColor: currentGroup[3]?.can_open === 1 ? COLORS.darkPrimary : COLORS.darkGray}]}></View>
                {renderLevelContent(3, COLORS.orange)}
                <View style={[styles.line, {backgroundColor: currentGroup[2]?.can_open === 1 ? COLORS.darkPrimary : COLORS.darkGray}]}></View>
                {renderLevelContent(2, COLORS.purple)}
                <View style={[styles.line, {backgroundColor: currentGroup[1]?.can_open === 1 ? COLORS.darkPrimary : COLORS.darkGray}]}></View>
                {renderLevelContent(1, COLORS.red)}
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    container:{
        backgroundColor: COLORS.white,
        paddingHorizontal: 10,
        paddingVertical: helper.isLittleScreen() ? 8 : 15,
    },
    containerTop: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 5
    },
    containerTirelire:{
        alignItems: 'center',
        width: 50
    },
    containerCountLevels:{
        width: 50,
    },
    textTirelire: {
        color: COLORS.darkerGray,
        fontSize: SIZES.xs,
        paddingTop: 2,
        paddingRight: 5
    },
    textNiveau: {
        color: COLORS.darkerGray,
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES["2xl"],
        fontWeight: FONTS.bold,
        textAlign: 'center'
    },
    containerLevels: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    level: {
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 3,
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 25 : 30,
        aspectRatio: 1
    },
    levelBlue:{
        borderColor: COLORS.blue
    },
    line: {
        width: '10%',
        height:3,
        borderRadius: ROUNDED.xl
    }
});

export default HeaderSolo;
