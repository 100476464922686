import axios from 'axios';
import {API_URL} from '../constants/api';
import AsyncStorage from "@react-native-async-storage/async-storage";

const PlateauService = {
    startGame: async (playersInfo) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/start_game`, {
                players: playersInfo,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getCurrentGame: async () => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/plateau/current_game`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getQuestion: async (category_id, joueur) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/player/get_question`, {
                category_id: category_id,
                joueur: joueur,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getAction: async () => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/player/get_action`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setReponse: async (question_id, joueur_id, bonne_reponse) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/player/set_reponse_joueur`, {
                question_id: question_id,
                joueur_id: joueur_id,
                bonne_reponse: bonne_reponse,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setStopUsed: async (joueur_id) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/player/set_stop_used`, {
                joueur_id: joueur_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    endGame: async (joueur_id) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.post(`${API_URL}/plateau/player/end_game`, {
                joueur_id: joueur_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    }

};


export default PlateauService;
