import React, {useState} from 'react';
import {TouchableOpacity, StyleSheet, View, Image} from 'react-native';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import * as helper from "../utils/helpers";

function LevelButton({ level, colors, onClose, onPress }) {

    let backgroundColor = colors.backgroundColor;
    let darkColor = colors.darkColor;

    const openModal = async () => {
        onPress();
    };


    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={[
                    styles.levelButton,
                    { backgroundColor: backgroundColor },
                    level.category_id !== null && styles.classicLevelButton,
                    level.category_id === null && styles.blackLevelButton,
                    level.category_id !== null && backgroundColor === COLORS.darkGray && styles.disabledButton
                ]}
                onPress={openModal}
            >

                {level.category_id !== null && (
                    level.is_valid === null && level.can_open ? (
                        <FontAwesome name="star" size={helper.isLittleScreen() ? 28 : 33} color={darkColor} />
                    ) : level.is_valid === null && !level.can_open ? (
                        <FontAwesome name="lock" size={helper.isLittleScreen() ? 28 : 33} color={darkColor} />
                    ) : level.is_valid == 1 ? (
                        <FontAwesome name="check" size={helper.isLittleScreen() ? 28 : 33} color={darkColor} />
                    ) : (
                        <FontAwesome name="close" size={helper.isLittleScreen() ? 28 : 33} color={darkColor}  />
                    )
                )}
                {level.category_id === null && (
                    level.is_valid === null && level.can_open ? (
                        <Image style={styles.trophy} source={helper.isLittleScreen() ? require('../../assets/general/trophy_small.png') : require('../../assets/general/trophy.png')}/>
                    ) : level.is_valid === null && !level.can_open ? (
                        <FontAwesome name="lock" size={helper.isLittleScreen() ? 28 : 33} color={darkColor} />
                    ) : level.is_valid == 1 ? (
                        <FontAwesome name="check" size={helper.isLittleScreen() ? 28 : 33} color={darkColor} />
                    ) : (
                        <FontAwesome name="close" size={helper.isLittleScreen() ? 28 : 33} color={darkColor}  />
                    )
                )}
            </TouchableOpacity>
            {level.category_id === null && <View style={[styles.levelButtonValidationShadow, {backgroundColor: level.is_valid === null && !level.can_open ? COLORS.darkerGray : COLORS.black}]} /> }
            {level.category_id !== null && <View style={[styles.levelButtonShadow, {backgroundColor: darkColor}]} />}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
        height: helper.isLittleScreen() ? 70 : 98,
        marginVertical: 10,
    },
    levelButton: {
        padding: helper.isLittleScreen() ? 10 : 20,
        alignItems: 'center',
        marginHorizontal: 'auto',
        justifyContent: 'center',
        position: "relative",
        zIndex: 1,
    },
    levelButtonShadow: {
        padding: helper.isLittleScreen() ? 10 : 20,
        alignItems: 'center',
        marginHorizontal: 'auto',
        justifyContent: 'center',
        position: "absolute",
        bottom: -5,
        width: helper.isLittleScreen() ? 70 : 98,
        height: helper.isLittleScreen() ? 70 : 98,
        borderRadius: ROUNDED.full,
    },
    classicLevelButton: {
        borderRadius: ROUNDED.full,
        aspectRatio: 1,
        width: helper.isLittleScreen() ? 70 : 98,
        height: helper.isLittleScreen() ? 70 : 98,
    },
    blackLevelButton: {
        borderRadius: ROUNDED.lg,
        width: helper.isLittleScreen() ? 185 : 200,
        height: helper.isLittleScreen() ? 60 : 70
    },
    levelButtonValidationShadow: {
        borderRadius: ROUNDED.lg,
        width: helper.isLittleScreen() ? 185 : 200,
        height: helper.isLittleScreen() ? 60 : 70,
        bottom: 8,
        position: "absolute",
    },
    trophy: {
        height: helper.isLittleScreen() ? 41 : 56,
        width: helper.isLittleScreen() ? 46 : 63,
        resizeMode: "contain"
    }

});


export default LevelButton;
