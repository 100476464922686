import React, { useState, useEffect  } from 'react';
import {
    TouchableOpacity,
    Text,
    View,
    StyleSheet,
    Modal,
    Button,
    ActivityIndicator,
    Image,
    ScrollView,
    SafeAreaView
} from 'react-native';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import SelectJoueurActionModal from '../components/SelectJoueurActionModal';
import QuestionPlateauModal from "./QuestionPlateauModal";
import PlateauService from "../services/PlateauService";
import bandeauImage from "../../assets/bandeaux/bandeau_dos_carte_Action.png";
import * as helper from "../utils/helpers";
import ErrorNotification from "./ErrorNotification";

function ActionModal({carteAction, toggleSoundEffect, handleCloseModal, currentJoueur, joueurs, handleSelectJoueurToPass, avatars}) {
    const [error, setError] = useState(null);
    const [isVisibleModalSelectJoueur, setIsVisibleModalSelectJoueur] = useState(false)
    const [isVisibleModalDuel, setIsVisibleModalDuel] = useState(false)
    const [isSelectedPlayer, setIsSelectedPlayer] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [categoryId, setCategoryId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDuelPlayer, setSelectedDuelPlayer] = useState({});
    const [currentDuelPlayer, setCurrentDuelPlayer] = useState({});

    const bandeauImage = require('../../assets/bandeaux/bandeau_dos_carte_Action.png')

    const handleModalSelectJoueur = () => {
        setIsVisibleModalSelectJoueur(true)
    }

    const handleSelectJoueurAction = async (selectedJoueur) => {
        if(carteAction.name.toLowerCase().includes("duel")){
            setSelectedDuelPlayer(selectedJoueur)
            setCurrentDuelPlayer(selectedJoueur)

            setIsVisibleModalSelectJoueur(false);
            setIsVisibleModalDuel(true);
            setIsLoading(true);

            try {
                const categoryIdTemp = getRandomCategory();
                setCategoryId(getRandomCategory());
                const questionData = await PlateauService.getQuestion(categoryIdTemp, selectedJoueur);
                setCategoryId(categoryIdTemp)

                setCurrentQuestion(questionData);
            } catch (error) {
                setError({code: 4009})
            } finally {
                setIsLoading(false);
            }

        }
        else{
            if(carteAction.has_to_pass === 1){
                handleSelectJoueurToPass(selectedJoueur, true)
            }
        }
        setIsSelectedPlayer(true)
        setIsVisibleModalSelectJoueur(false)
    }

    const handleCloseQuestion = () => {
        setIsVisibleModalDuel(false);
    }

    const handleNextQuestion = async () => {
        setCurrentDuelPlayer(selectedDuelPlayer === currentDuelPlayer ? currentJoueur : selectedDuelPlayer)

        setIsVisibleModalDuel(true);
        setIsLoading(true);

        try {
            const categoryIdTemp = getRandomCategory();
            setCategoryId(getRandomCategory());
            const questionData = await PlateauService.getQuestion(categoryIdTemp, selectedDuelPlayer === currentDuelPlayer ? currentJoueur : selectedDuelPlayer);

            setCategoryId(categoryIdTemp)

            setCurrentQuestion(questionData);
        } catch (error) {
            setError({code: 4010})
        } finally {
            setIsLoading(false);
        }
    }

    const handleValidationReponse = (isCorrect) => {
        // Validation of question duel
    }

    const handleCloseModalSelectJoueur = () => {
        setIsVisibleModalSelectJoueur(false)
    }

    const getRandomCategory = () => {
        const categories = [1, 2, 3, 4, 5];
        const randomIndex = Math.floor(Math.random() * categories.length);
        return categories[randomIndex];
    }

   return (
       <SafeAreaView style={{flex: 1}}>
           <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
               <View style={styles.modalContainer}>
                   <View>
                       <View style={styles.categoryContainer}>
                           <View style={styles.containerCarte}>
                               <Image style={styles.carteImage} source={bandeauImage}/>
                           </View>

                           <View style={{paddingHorizontal: 20, paddingTop: 40}}>
                               <Text style={styles.categoryText}>Carte action</Text>
                               <View style={styles.containerAvatar}>
                                   <View style={styles.boxAvatar}>
                                       <Image source={avatars[currentJoueur?.avatar]} style={styles.image} resizeMode="cover" />
                                   </View>
                               </View>
                           </View>
                       </View>
                   </View>
                   <Text style={styles.textCurrentJoueur}>{currentJoueur.pseudo}</Text>
                   <View style={styles.container}>
                       <View style={styles.carte}>
                           <Image style={styles.imageCarte} source={require('../../assets/cartes/carte_action.png')}/>
                           <View style={{paddingHorizontal: 20, paddingVertical: 40,}}>
                               <Text style={styles.modalTitle}>{carteAction.name}</Text>
                               <Text style={styles.modalContent}>{carteAction.description}</Text>
                           </View>
                       </View>

                       <View style={{width: "100%", alignItems: "center"}}>
                           {carteAction.can_choose_player === 1 && !isSelectedPlayer ?
                               (
                                   <TouchableOpacity style={styles.modalButton} onPress={handleModalSelectJoueur}>
                                       <Text style={styles.modalButtonText}>Sélectionner joueur</Text>
                                   </TouchableOpacity>
                               ) : (<Text></Text>)
                           }

                           <TouchableOpacity style={styles.passButton} onPress={handleCloseModal}>
                               <Text style={styles.passButtonText}>Fermer</Text>
                           </TouchableOpacity>
                       </View>
                   </View>

                   <Modal visible={isVisibleModalDuel}>
                       {isLoading ? (
                           <View style={styles.activityIndicatorContainer}>
                               <ActivityIndicator size="large" color={COLORS.primary} />
                           </View>
                       ) : (
                           <QuestionPlateauModal
                               question={currentQuestion}
                               category_id={currentQuestion?.category_id}
                               handleValidationReponse={handleValidationReponse}
                               handleCloseModal={handleCloseQuestion}
                               handleNextQuestion={handleNextQuestion}
                               modeDuel={true}
                               player1={selectedDuelPlayer}
                               player2={currentJoueur}
                               currentPlayer={currentDuelPlayer}
                               toggleSoundEffect={toggleSoundEffect}
                           />
                       )}
                   </Modal>

                   <Modal visible={isVisibleModalSelectJoueur}
                          transparent={true}
                          animationType="slide"
                          style={{justifyContent: "center", alignItems: 'center'}}
                   >
                       <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                           <View style={styles.modalContainer}>
                               <SelectJoueurActionModal action={carteAction} joueurs={joueurs} currentJoueur={currentJoueur} handleCloseModal={handleCloseModalSelectJoueur} handleSelectJoueur={handleSelectJoueurAction} />
                           </View>
                       </ScrollView>
                   </Modal>
               </View>
           </ScrollView>

           <ErrorNotification
               isVisible={error !== null}
               code={error?.code}
               message={error?.message}
               onClose={() => setError(null)}
           />
       </SafeAreaView>
   );
}

const styles = StyleSheet.create({
    carte: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ROUNDED.xl,
        marginBottom: helper.isLittleScreen() ? 20 : 50,
        borderWidth: 2,
        borderColor: COLORS.black1,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        maxWidth: 400,
    },
    imageCarte: {
        width: '105%',
        height: '110%',
        resizeMode: "cover",
        position: "absolute",
        opacity: 0.05
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 60,
        paddingBottom: helper.isLittleScreen() ? 15 : 30,
    },
    textCurrentJoueur: {
        fontSize: helper.isLittleScreen() ? SIZES["xl"] : SIZES["2xl"],
        color: COLORS.black,
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        textAlign: "center"
    },
    categoryContainer: {
        backgroundColor: COLORS.black,
        position: "relative",
        marginBottom: helper.isLittleScreen() ? 30 : 50
    },
    containerCarte: {
        flex: 1,
        position: "absolute",
        width: '100%',
        height: '100%'
    },
    carteImage: {
        flex: 1,
        // height: '100%',
        width: '100%',
        resizeMode: 'stretch'
    },
    categoryText: {
        fontSize: helper.isLittleScreen() ? SIZES["lg"] : SIZES["2xl"],
        fontWeight: FONTS.bold,
        color: COLORS.white,
        textAlign: "center",
        position: "relative",
        marginTop: -20,
        marginBottom: 20,
    },
    containerAvatar:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: "center",
        height: helper.isLittleScreen() ? 20 : 30,
        marginTop: 5
    },
    boxAvatar: {
        borderColor: COLORS.black,
        borderWidth: 3,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ROUNDED.full,
        aspectRatio: 1,
        overflow: "hidden",
        width: helper.isLittleScreen() ? 65 : 80,
        height: helper.isLittleScreen() ? 65 : 80,
        marginTop: -20
    },
    modalContainer: {
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        flexDirection: "column",
        elevation: 5,
    },
    modalTitle: {
        fontSize: helper.isLittleScreen() ? SIZES["2xl"] : SIZES["3xl"],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 15 : 30,
        textAlign: "center"
    },
    modalContent: {
        marginBottom: helper.isLittleScreen() ? 15 : 20,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        textAlign: "center",
        fontWeight: FONTS.medium
    },
    modalButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: helper.isLittleScreen() ? 10 : 15,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        width: '100%',
        maxWidth: 400
    },
    modalButtonText: {
        color: COLORS.white,
        fontWeight: FONTS.semibold,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
    },
    passButton: {
        backgroundColor: COLORS.gray,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: helper.isLittleScreen() ? 10 : 15,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 400
    },
    passButtonText: {
        color: COLORS.darkerGray,
        fontWeight: FONTS.semibold,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
    },
    activityIndicatorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default ActionModal;
