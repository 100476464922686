import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
const SoloService = {
    getUser: async (user_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getContrats: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/get_contrats`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setContrat: async (contrat_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/user/set_contrat`, {
                contrat_id: contrat_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    getUserLevelsContrat: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/user/get_levels_contrat/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setContratFinishedViewed: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/user/set_contrat_finished_viewed/`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setResultRoue: async (bonus_id, quantity) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/user/set_roue_used`, {
                bonus_id: bonus_id,
                quantity: quantity,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
    setBonusUsed: async (bonus_id, level_id) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/user/set_bonus_used`, {
                bonus_id: bonus_id,
                level_id: level_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    }
}

export default SoloService;
