import React, {useEffect, useRef, useState} from 'react';
import {
    ActivityIndicator,
    Image,
    Modal,
    ScrollView,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View
} from 'react-native';
import {COLORS, FONTS, ROUNDED, SIZES} from "../../constants/theme";
import QuestionPlateauModal from "../../components/QuestionPlateauModal";
import ActionModal from "../../components/ActionModal";
import PlateauService from "../../services/PlateauService";
import Dice from '../../components/Dice';
import * as helper from "../../utils/helpers";
import {getAvatars, getGlobalBoolean, isLittleScreen} from "../../utils/helpers";
import HeaderPlateau from "../../components/HeaderPlateau";
import SelectJoueurActionModal from "../../components/SelectJoueurActionModal";
import {Audio} from 'expo-av';
import AsyncStorage from "@react-native-async-storage/async-storage";
import ErrorNotification from "../../components/ErrorNotification";


function Partie({ navigation }) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [cardLoading, setCardLoading] = useState({1: false, 2:false, 3:false, 4:false, 5:false, 0:false});
    const [game, setGame] = useState(null);
    const [resetDice, setResetDice] = useState(false);
    const [categorySelected, setCategorySelected] = useState(null);
    const [carteAction, setCarteActionSelected] = useState({ name: '', description: '' });
    const [isModalActionVisible, setModalActionVisible] = useState(false);
    const [isModalQuestionVisible, setModalQuestionVisible] = useState(false);
    const [isModalPassPlayer, setModalPassPlayer] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [joueurs, setJoueurs] = useState([]);
    const [currentJoueur, setCurrentJoueur] = useState({});
    const [canPlay, setCanPlay] = useState(true);
    const [canPlayCard, setCanPlayCard] = useState(false);
    const [canRollDice, setCanRollDice] = useState(true);
    const scrollViewRef = useRef();
    const [isSelectedPlayer, setIsSelectedPlayer] = useState(false)
    const [canPlayAgain, setCanPlayAgain] = useState(false)
    const [soundAmbiance, setSoundAmbiance] = useState(null);
    const [toggleMusic, setToggleMusic] = useState(true);
    const [toggleSoundEffect, setToggleSoundEffect] = useState(true);
    const [toggleSoundVoice, setToggleSoundVoice] = useState(true);

    const imageUrls = getAvatars()

    useEffect(() => {
        checkEnabledAudio('isMusiqueMute')
        checkEnabledAudio('isEffectMute')
        checkEnabledAudio('isVoiceMute')
        const loadSound = async () => {
            const sound = new Audio.Sound();
            await sound.loadAsync(require('../../../assets/audios/PunchDeck-ICantStop.mp3'));
            setSoundAmbiance(sound);
        };

        loadSound();

        return () => {
            if (soundAmbiance) {
                soundAmbiance.unloadAsync();
            }
        };
    }, []);

    const checkEnabledAudio = async (type) => {
        const enable = await helper.getGlobalBoolean(type)

        switch (type) {
            case 'isMusiqueMute':
                setToggleMusic(enable);
                break;
            case 'isEffectMute':
                setToggleSoundEffect(enable);
                break;
            case 'isVoiceMute':
                setToggleSoundVoice(enable);
                break;
        }
    }

    const setEnabledAudio = async (type, value) => {
        await helper.setGlobalBooleanValue(type, value)
        await checkEnabledAudio(type)
    }

    useEffect( () => {
        // Play the song when Partie screen is opened
        if(toggleMusic) {
            playAmbianceSong()
        }


        // Stop the song when Partie screen is no longer active or closed
        return () => {
            if(soundAmbiance){
                soundAmbiance.stopAsync();
            }
        };
    }, [soundAmbiance]);

    // On arrete la musique quand on quitte la navigation (pour être sur qu'il soit bien stoppé)
    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            if(soundAmbiance){
                soundAmbiance.stopAsync();
            }
        });

        return unsubscribe;
    }, [navigation, soundAmbiance]);

    // On arrete la musique quand on quitte la navigation (pour être sur qu'il soit bien stoppé)
    useEffect(() => {
        if(soundAmbiance){
            if(!toggleMusic){
                soundAmbiance.stopAsync();
            }
            else{
                playAmbianceSong()
            }
        }

        return () => {
            if(soundAmbiance){
                soundAmbiance.stopAsync();
            }
        };
    }, [soundAmbiance, toggleMusic]);


    useEffect(() => {
        const fetchGameData = async () => {
            try {
                const gameData = await PlateauService.getCurrentGame();

                if(gameData) {
                    setGame(gameData);
                }
                else{
                    setError({code: 4002, message: "Les inforamtions de la partie n'ont pas pu être chargés. Veuillez réessayer plus tard."})
                }


            } catch (error) {
                setError({code: 4003, message: "Les inforamtions de la partie n'ont pas pu être chargés. Veuillez réessayer plus tard."})
            }
        };

        fetchGameData();
    }, []);



    useEffect(() => {
        if (game) {
            setJoueurs(JSON.parse(game.joueurs_json));
        }
    }, [game]);

    useEffect(() => {
        if (joueurs.length > 0) {
            const findCurrentJoueur = joueurs.find(j => {
                return j.id == game.joueur_actuel
            })

            setCurrentJoueur(findCurrentJoueur);
            setIsLoading(false);
        }
    }, [joueurs]);

    useEffect(() => {
        if (resetDice) {
            scrollViewRef.current?.scrollTo({ y: 0, animated: true });
        }
    }, [resetDice]);



    // Play the song when Partie screen is opened
    const playAmbianceSong = async () => {
        if(soundAmbiance){
            const status = await soundAmbiance.getStatusAsync();

            if (status && status.isPlaying) {
                // The song is currently playing
            } else {
                await soundAmbiance.setVolumeAsync(0.1);
                await soundAmbiance.setIsLoopingAsync(true);

                if(toggleMusic){
                    await soundAmbiance.playAsync();
                }
            }
        }
    }

    const handleToggleMutedAudio = async (type, toggle) => {
        switch (type) {
            case 'isMusiqueMute':
                setToggleMusic(toggle)
                if(toggle){
                    await playAmbianceSong()
                }
                else{
                    soundAmbiance.stopAsync();
                }
                break;
            case 'isEffectMute':
                setToggleSoundEffect(toggle)
                break;
            case 'isVoiceMute':
                setToggleSoundVoice(toggle)
                break;
        }


        await setEnabledAudio(type, toggle)
    }

    const handleRollDice = async () => {
        if (!canPlay) return;

        if(toggleSoundEffect) {
            await helper.playAudio(require('../../../assets/audios/roll_dice.mp3'))
        }

        setCanRollDice(false)
        setCanPlayAgain(false)
        setCanPlayCard(true)
    };



    const handleOpenAction = async () => {
        if (!canPlay || !canPlayCard) return;

        const loading = {...cardLoading}; // Here, make a copy of cardLoading state
        loading[0] = true;

        setCardLoading(loading);

        if(toggleSoundEffect) {
            await helper.playAudio(require('../../../assets/audios/pick_card.mp3'))
        }

        const action = await PlateauService.getAction();
        setCarteActionSelected(action);

        // Play carte song
        if(toggleSoundVoice) {
            if(action && action.audio){
                const audioPath = 'https://objectifofficiersp.com/storage/' + action.audio;
                const sound = new Audio.Sound();

                try {
                    await sound.loadAsync({ uri: audioPath });
                    await sound.playAsync();
                } catch (error) {
                    // setError({code: 4005})
                }
            }
        }

        setModalActionVisible(true);
        setCardLoading({1: false, 2: false, 3: false, 4: false, 5: false, 0: false});


    };

    const closeModal = () => {
        setModalActionVisible(false);
        setCanRollDice(false)
        setCanPlay(false)
        setCanPlayCard(false)
        setCanPlayAgain(false)
    };

    const handleOpenModalStopPlayer = () => {
        setModalPassPlayer(true);
    }

    const handleCloseModalSelectJoueur = () => {
        setModalPassPlayer(false)
    }

    const handleSelectJoueurAction = async (selectedJoueur) => {
        handleSelectJoueur(selectedJoueur)
        setIsSelectedPlayer(true)
        setModalPassPlayer(false)
    }

    const handleOpenQuestion = async (category) => {
        if (!canPlay) return;
        try {
            const loading = {...cardLoading}; // Here, make a copy of cardLoading state
            loading[category] = true;

            setCardLoading(loading);


            setCategorySelected(category);
            if(toggleSoundEffect){
                await helper.playAudio(require('../../../assets/audios/pick_card.mp3'))
            }

            const questionData = await PlateauService.getQuestion(category, currentJoueur);

            setCurrentQuestion(questionData);
            setModalQuestionVisible(true);

            setCardLoading({1: false, 2: false, 3: false, 4: false, 5: false, 0: false});

        } catch (error) {
            setError({code: 4006})
        }
    };

    const handleCloseQuestion = () => {
        setModalQuestionVisible(false);
    };

    // This is called to set the result of question
    const handleValidReponseQuestion = (isCorrect) => {
        // If correct, user can play again
        if(isCorrect){
            setCanRollDice(true)
            setResetDice(true)
            setCanPlayAgain(true)
        }
        // If not correct, he cannot play, he has to pass
        else{
            setCanRollDice(false)
            setCanPlay(false)
            setResetDice(false)
            setCanPlayAgain(false)
        }
        // We can't play another car until we rollDice
        setCanPlayCard(false)
    }

    const handleContinuer = () => {
        // Check if the current joueur has_to_pass to reset
        setIsLoading(true);

        if(currentJoueur.has_to_pass){
            currentJoueur.has_to_pass = false
        }

        // set the next Joueur
        let nextJoueur = game.joueur_actuel + 1;
        if(nextJoueur > joueurs.length){
            nextJoueur = 1
        }

        // const updatedGameData = { ...game, joueur_actuel: nextJoueur };
        // setGame(updatedGameData)

        game.joueur_actuel = nextJoueur;



        const currentJoueurTemp = joueurs.find(j => {
            return j.id == nextJoueur
        });

        setCanPlay(true);
        setResetDice(true)
        setCanRollDice(true)
        setCanPlayAgain(false)
        setCurrentJoueur(currentJoueurTemp);


        // if the nextJoueur has_to_pass, he cannot play
        if(currentJoueurTemp.has_to_pass){
            setCanPlay(false);
            setCanRollDice(false)
        }

        setTimeout(() => {
            setResetDice(false);
        }, 100);

        setIsLoading(false);
    };

    const handleSelectJoueur = async (selectedPlayer) => {
        try{
            if(toggleSoundEffect) {
                await helper.playAudio(require('../../../assets/audios/stop.mp3'))
            }
            const result = await PlateauService.setStopUsed(selectedPlayer.id);
            setJoueurs((prevJoueurs) =>
                prevJoueurs.map((joueur) => {
                    if (joueur.id === selectedPlayer.id) {
                        return {
                            ...joueur,
                            stop_used: true, // Update the stop_used property to true
                        };
                    }
                    return joueur;
                })
            );

            setCanRollDice(false)
            setCanPlay(false)

        } catch (error){
            setError({code: 4007})
        }
    }

    const setJoueurHasToPass = (joueurToApply, hasToPass) => {
        const updatedJoueurs = joueurs.map((joueur) => {
            if (joueur.id === joueurToApply.id) {
                // Modify the selected player, e.g., add "has_to_pass" property
                return {
                    ...joueur,
                    has_to_pass: hasToPass,
                };
            }
            return joueur; // Keep the other players unchanged
        });

        setJoueurs(updatedJoueurs)
        return updatedJoueurs;
    }

    const stylesGame = {
        buttonHasToContinue: {
            borderWidth: canPlay ? 1 : 3,
            borderColor: canPlay ? COLORS.darkPrimary : COLORS.darkRed
        }
    }

    if (isLoading) {
        return (
            <View style={styles.loaderContainer}>
                <ActivityIndicator size="large" color={COLORS.primary} />
            </View>
        );
    }

    return (
        // <ScrollView ref={scrollViewRef} contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}></ScrollView>
        <View style={styles.container}>
            <HeaderPlateau
                joueurs={joueurs}
                currentJoueur={currentJoueur}
                handleMutedAudio={handleToggleMutedAudio}
                audios={{toggleMusic: toggleMusic, toggleSoundVoice: toggleSoundVoice, toggleSoundEffect: toggleSoundEffect}}
            />
            <View style={{flex: 1, justifyContent: 'space-between'}}>
                <Text style={styles.textCurrentJoueur}>{currentJoueur?.pseudo ?? ""}</Text>
                <Dice onRollDice={handleRollDice} currentJoueur={currentJoueur} resetDice={resetDice} disabled={!canRollDice} />
                {currentJoueur.hasOwnProperty('has_to_pass') && currentJoueur.has_to_pass ?
                    (
                        <Text style={styles.textHasToPass}>Vous devez passer votre tour !</Text>
                    ) : (
                        <Text></Text>
                    )
                }
                {!canPlay && !(currentJoueur.hasOwnProperty('has_to_pass') && currentJoueur.has_to_pass) ?
                    (
                        <Text style={styles.textHasToPass}>Votre tour est terminé !</Text>
                    ) : (
                        <Text></Text>
                    )
                }
                {canPlayAgain && canPlay ?
                    (
                        <Text style={[styles.textHasToPass, {color: COLORS.green}]}>Vous pouvez relancer le dé !</Text>
                    ) : (
                        <Text></Text>
                    )
                }

                <View style={styles.containerGrid}>
                    <View style={styles.buttonGrid}>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.blueButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenQuestion(1)} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Culture_Administrative.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[1] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image  source={require('../../../assets/cartes/icon_dos_carte_Culture_Administrative.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.redButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenQuestion(5)} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Doctrine_operationnelle.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[5] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image source={require('../../../assets/cartes/icon_dos_carte_Doctrine_operationnelle.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.greenButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenQuestion(2)} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Preventions.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[2] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image source={require('../../../assets/cartes/icon_dos_carte_Preventions.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.buttonGrid}>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.purpleButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenQuestion(4)} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Risque_Naturel.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[4] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image source={require('../../../assets/cartes/icon_dos_carte_Risque_Naturel.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.orangeButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenQuestion(3)} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Securite_Civile.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[3] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image source={require('../../../assets/cartes/icon_dos_carte_Securite_Civile.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={!canPlayCard} style={[styles.button, styles.blackButton,  { opacity: canPlayCard ? 1 : 0.3 }]} onPress={() => handleOpenAction()} >
                            <Image style={styles.carte} source={require('../../../assets/cartes/dos_carte_Action.png')}/>
                            <View style={styles.carteIcon}>
                                {
                                    cardLoading[0] ? (
                                        <ActivityIndicator />
                                    ) : (
                                        <Image source={require('../../../assets/cartes/icon_dos_carte_Action.png')}/>
                                    )
                                }
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.containerButtonBottom}>
                    <TouchableOpacity style={[
                        styles.nextButton,
                        stylesGame.buttonHasToContinue,
                        {width: (joueurs.filter((joueur) => !joueur.stop_used && joueur.id !== currentJoueur.id)).length > 0 ? '80%' : '100%'}
                    ]} onPress={handleContinuer}>
                        <Text style={styles.nextButtonText}>Continuer</Text>
                    </TouchableOpacity>

                    {(joueurs.filter((joueur) => !joueur.stop_used && joueur.id !== currentJoueur.id)).length > 0 && (
                        <TouchableOpacity style={[styles.passButton]} onPress={handleOpenModalStopPlayer}>
                            <Image style={{width: '100%', height: '100%', objectFit: 'contain'}} source={(require('../../../assets/general/stop.png'))}/>
                        </TouchableOpacity>
                    )}
                </View>
            </View>


            <Modal visible={isModalActionVisible}>
                <ActionModal
                    carteAction={carteAction}
                    handleCloseModal={closeModal}
                    currentJoueur={currentJoueur}
                    joueurs={joueurs}
                    handleSelectJoueurToPass={setJoueurHasToPass}
                    avatars={imageUrls}
                    toggleSoundEffect={toggleSoundEffect}
                />
            </Modal>

            <Modal visible={isModalPassPlayer}>
                <SelectJoueurActionModal selectText={'Sélectionnez le joueur qui vous a stoppé dans votre progression'} joueurs={joueurs.filter((joueur) => !joueur.stop_used)} currentJoueur={currentJoueur} handleCloseModal={handleCloseModalSelectJoueur} handleSelectJoueur={handleSelectJoueurAction}/>
            </Modal>

            <Modal visible={isModalQuestionVisible}>
                <QuestionPlateauModal
                    currentJoueur={currentJoueur}
                    currentPlayer={currentJoueur}
                    question={currentQuestion}
                    category_id={categorySelected}
                    handleCloseModal={handleCloseQuestion}
                    handleValidationReponse={handleValidReponseQuestion}
                    modeDuel={false}
                    avatars={imageUrls}
                    toggleSoundEffect={toggleSoundEffect}
                />
            </Modal>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </View>
    );
}

const styles = {
    container: {
        flex: 1,
        alignItems: 'center',
        paddingBottom: helper.isLittleScreen() ? 15 : 30
    },
    textCurrentJoueur: {
        fontSize: helper.isLittleScreen() ? SIZES["2xl"] : SIZES["3xl"],
        color: COLORS.black,
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 5 : 20,
        marginTop: helper.isLittleScreen() ? 30 : 45,
        textAlign: "center"
    },
    textHasToPass: {
        fontSize: helper.isLittleScreen() ? SIZES["lg"] : SIZES["2xl"],
        color: COLORS.red,
        fontWeight: FONTS.bold,
        textAlign: "center"
    },
    buttonContinuer: {},
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerGrid: {
        marginVertical: helper.isLittleScreen() ? 5 : 20
    },
    buttonGrid: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        width: helper.isLittleScreen() ? 90 : 100,
        height: helper.isLittleScreen() ? 110 : 130,
        margin: helper.isLittleScreen() ? 5 : 10,
        borderWidth: 3,
        borderRadius: ROUNDED.lg,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: 'center'
    },
    carte: {
        width: '110%',
        height: '110%'
    },
    carteIcon: {
        position: 'absolute',
    },
    blueButton: {
        backgroundColor: COLORS.contrat.blue,
        borderColor: COLORS.contrat.darkBlue,
        elevation: 2, // Adjust the elevation value as needed
        shadowColor: COLORS.contrat.darkBlue,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },
    greenButton: {
        backgroundColor: COLORS.contrat.green,
        borderColor: COLORS.contrat.darkGreen,
        elevation: 2,
        shadowColor: COLORS.contrat.darkGreen,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },
    orangeButton: {
        backgroundColor: COLORS.contrat.orange,
        borderColor: COLORS.contrat.darkOrange,
        elevation: 2,
        shadowColor: COLORS.contrat.darkOrange,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },
    purpleButton: {
        backgroundColor: COLORS.contrat.purple,
        borderColor: COLORS.contrat.darkPurple,
        elevation: 2,
        shadowColor: COLORS.contrat.darkPurple,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },
    redButton: {
        backgroundColor: COLORS.contrat.red,
        borderColor: COLORS.contrat.darkRed,
        elevation: 2,
        shadowColor: COLORS.contrat.darkRed,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },
    blackButton: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        elevation: 2,
        shadowColor: COLORS.black,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 2,
    },

    containerButtonBottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        paddingHorizontal: 30,
        maxWidth: 400
    },
    nextButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: helper.isLittleScreen() ? 13 : 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    nextButtonText: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: helper.isLittleScreen() ? SIZES.base :  SIZES.lg,
    },
    passButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        height: 60,
        marginLeft: 5
    },
    modalButtonPass: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    modalButtonTextPass: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
};

export default Partie;
