import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {ActivityIndicator, Image, Modal, Platform, SafeAreaView, StatusBar, Text, View} from 'react-native';
import NetInfo from '@react-native-community/netinfo'
import {getFocusedRouteNameFromRoute, NavigationContainer, useNavigation, useNavigationContainerRef } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import Login from './src/screens/Login';
import Accueil from './src/screens/Accueil';
import Classement from './src/screens/Classement';
import Defis from './src/screens/Defis/Defis';
import DefisPlay from './src/screens/Defis/DefisPlay';
import Compte from './src/screens/Compte';
import Plateau from './src/screens/plateau/Plateau';
import NombreJoueurs from './src/screens/plateau/NombreJoueurs';
import InfosJoueurs from './src/screens/plateau/InfosJoueurs';
import SelectTypeGame from './src/screens/plateau/SelectTypeGame';
import Partie from './src/screens/plateau/Partie';
import {AuthContext, AuthProvider} from './src/utils/AuthContext';
import {COLORS, FONTS, SIZES} from "./src/constants/theme";
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants'
import ProfileModal from "./src/components/ProfileModal";
import { navigationRef } from './src/navigation/RouteNavigation';
import AuthService from "./src/services/AuthService";
import * as helper from "./src/utils/helpers";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import NotificationService from "./src/services/NotificationService";
import UserService from "./src/services/UserService";

// Notifications.setNotificationHandler({
//     handleNotification: async () => ({
//         shouldShowAlert: true,
//         shouldPlaySound: false,
//         shouldSetBadge: false,
//     }),
// });

async function getPublicKeyNotification() {
    const {key} = await NotificationService.getPublicKey()
    return key;
}

async function saveSubscriptionNotification(subscription) {
    const resultNotificaiton = await NotificationService.saveSubscription(subscription)
    return resultNotificaiton;
}

async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        //
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            // alert('Failed to get push token for push notification!');

            let tokenToSend = null;
            // let checkExpoToken = await AuthService.check_expo_token(tokenToSend)
            return;
        }
        const registration = await navigator.serviceWorker.register('service-worker.js')
        let subscription = await registration.pushManager.getSubscription();

        if(!subscription) {
            subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: await getPublicKeyNotification()
            })
        }
        await saveSubscriptionNotification(subscription)


        // token = await Notifications.getExpoPushTokenAsync({
        //     projectId: Constants.expoConfig.extra.eas.projectId,
        // });
        //
        // let tokenToSend = null;
        //
        // if(token && token.data) {
        //     tokenToSend = token.data;
        // }
        //
        //
        // // We check if user is logged before savig token expo
        // const authToken = await AsyncStorage.getItem('token');
        // if(authToken){
        //
        //     try {
        //         const checkLogin = await AuthService.check();
        //
        //         if(checkLogin && checkLogin.auth){
        //             let checkExpoToken = await AuthService.check_expo_token(tokenToSend)
        //         }
        //     } catch (error) {
        //         console.log('Error auth token expo:' . error);
        //     }
        // }


    } else {
        alert('Must use physical device for Push Notifications');
    }

    // if (Platform.OS === 'android') {
    //     Notifications.setNotificationChannelAsync('default', {
    //         name: 'default',
    //         importance: Notifications.AndroidImportance.MAX,
    //         vibrationPattern: [0, 250, 250, 250],
    //         lightColor: '#FF231F7C',
    //     });
    // }

    return token;
}

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

function DefisStack({navigation, route}) {
    useLayoutEffect( () => {
        const routeName = getFocusedRouteNameFromRoute(route)
        if(routeName === "PlayDéfis"){
            navigation.setOptions({tabBarStyle: { display: 'none' }})
        }
        else{
            navigation.setOptions({tabBarStyle: {}})
        }
    }, [navigation, route])

    return (
        <Stack.Navigator>
            <Stack.Screen name="DéfisPage" component={Defis} options={{headerShown: false}} />
            <Stack.Screen name="PlayDéfis" component={DefisPlay} options={{headerShown: false}} />
        </Stack.Navigator>
    )
}

function PlateauStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name="PlateauPartie" component={Plateau} options={{headerShown: false}} />
            <Stack.Screen
                name="NombreJoueurs"
                component={NombreJoueurs}
                options={{
                    title: 'Création de partie',
                    headerBackImage: () => (<Ionicons name="arrow-back" color={COLORS.black} size={26} />),
                    headerBackTitle: 'Retour'
                }}
            />
            <Stack.Screen
                name="InfosJoueurs"
                component={InfosJoueurs}
                options={{
                    title: 'Création de partie',
                    headerBackImage: () => (<Ionicons name="arrow-back" color={COLORS.black} size={26} />),
                    headerBackTitle: 'Retour'
                }}
            />
            <Stack.Screen
                name="SelectTypeGame"
                component={SelectTypeGame}
                options={{
                    title: 'Création de partie',
                    headerBackImage: () => (<Ionicons name="arrow-back" color={COLORS.black} size={26} />),
                    headerBackTitle: 'Retour'
                }}
            />
            <Stack.Screen
                name="Partie"
                component={Partie}
                options={{ title: 'Jeu en cours', headerShown: false  }}
            />
        </Stack.Navigator>
    );
}

function LoginStack({isLoading}) {
    return (
        <Stack.Navigator>
            <Stack.Screen
                name="Login"
                component={Login}
                options={{headerShown: false}}
                initialParams={{ isLoading: isLoading }}
            />
        </Stack.Navigator>
    );
}

function App({navigation}) {
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [selectedFriendId, setSelectedFriendId] = useState(null);
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const [isConnected, setIsConnected] = useState(true); // Add isConnected state
    const notificationListener = useRef();
    const responseListener = useRef();
    const [redirect, setRedirect] = useState([null])
    const [loadingAccess, setLoadingAccess] = useState(true); // Nouvel état pour le chargement des accès
    const [hasAccessToPlateau, setHasAccessToPlateau] = useState(false);
    // console.log(navigationRef)
    //
    // useEffect(() => {
    //     if(navigationRef && navigationRef.current){
    //         console.log('navigationRef')
    //         // console.log(navigationRef.navigate('Défis'))
    //         // navigationRef.navigate(redirect)
    //     }
    // }, [])

    const imageSource = helper.isTabletteScreen()
        ? require('./assets/general/Fond_tablette.png')
        : require('./assets/general/Fond_Mobile.png');

    useEffect(() => {
        NetInfo.fetch().then(state => {
            setIsConnected(state.isConnected)
        })
    }, [])


    const handleOpenModalProfil = (user_id) => {
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setModalProfileVisible(false)
    }

    return (
        <AuthProvider>
            { isConnected ? (
                <AppContent redirect={redirect} />
            ) : (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', position: "relative" }}>
                    <Image style={{position: "absolute", width: "100%", height: "100%", resizeMode: "cover"}} source={imageSource}/>
                    <Text style={{color: COLORS.white, fontSize: SIZES.xl, textAlign: "center", fontWeight: FONTS.bold,  paddingHorizontal: 30, marginBottom: 10}}>
                        L'application nécessite une connexion Internet.
                    </Text>
                    <Text style={{color: COLORS.white, fontSize: SIZES.base, textAlign: "center", fontWeight: FONTS.semibold,  paddingHorizontal: 30}}>
                        Veuillez vérifier votre connexion et réessayer.
                    </Text>
                </View>
            )}
        </AuthProvider>
    )
}

function AppContent({redirect}) {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    const [expoPushToken, setExpoPushToken] = useState('');
    const [canRecieveNotification, setCanReceiveNotification] = useState(true);
    const [selectedFriendId, setSelectedFriendId] = useState(null);
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [loadingAccess, setLoadingAccess] = useState(true); // Nouvel état pour le chargement des accès
    const [hasAccessToPlateau, setHasAccessToPlateau] = useState(false);


    useEffect(() => {
        if(isLoggedIn) {
            const checkAccess = async () => {
                try {
                    const plateauAccess = await UserService.check_access();

                    if(plateauAccess && (!plateauAccess.has_mobile_access) && plateauAccess.has_plateau_access) {
                        setHasAccessToPlateau(true);
                    }
                    setLoadingAccess(false);
                } catch (error) {
                    setLoadingAccess(false);
                }
            };
            checkAccess();
        }
        setLoadingAccess(false);
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && hasAccessToPlateau) {
            navigationRef.current?.navigate('Plateau');
        }
    }, [isLoggedIn, hasAccessToPlateau]);

    useEffect(() => {
        if (isLoggedIn) {
            if('Notification' in window && 'serviceWorker' in navigator) {
                // console.log('begin notification')
            }
            else{
                return;
            }

            registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

            setCanReceiveNotification(true);


            //
            // notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            //     setNotification(notification);
            // });
            //
            // responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            //     const data = response.notification.request.content.data;
            //
            //     if(data) {
            //         const action = data.action
            //         if(action === "demande_amis") {
            //             if(data.friend_id) {
            //                 setSelectedFriendId(data.friend_id)
            //                 setModalProfileVisible(true)
            //             }
            //         }
            //         else if(action === "defi_amis") {
            //             if(navigationRef.isReady()) {
            //                 navigationRef.navigate('Défis')
            //             }
            //             // alert("Votre ami vous a défié.\n Rendez vous sur l'onglet Défis")
            //         }
            //         else if(action === "defi_ranked") {
            //             if(navigationRef.isReady()) {
            //                 navigationRef.navigate('Défis')
            //             }
            //             // alert("Un abonné vous a défié.\n Rendez vous sur l'onglet Défis")
            //         }
            //     }
            // });
            //
            //
            // return () => {
            //     Notifications.removeNotificationSubscription(notificationListener.current);
            //     Notifications.removeNotificationSubscription(responseListener.current);
            // };

            return () => {
                console.log('')
            }
        }
    }, [isLoggedIn])


    function handleServiceWorkerMessage(event) {
        console.log(event);
        if(event.data.type === "NOTIFICATION_CLICK") {
            if(event.data.data) {
                if(event.data.data.action) {
                    const data = event.data.data;
                    const action = data.action;

                    if(action === "demande_amis") {
                        if(data.friend_id) {
                            setSelectedFriendId(data.friend_id)
                            setModalProfileVisible(true)
                        }
                    }
                    else if(action === "defi_amis") {
                        if(navigationRef.isReady()) {
                            navigationRef.navigate('Défis')
                        }
                        // alert("Votre ami vous a défié.\n Rendez vous sur l'onglet Défis")
                    }
                    else if(action === "defi_ranked") {
                        if(navigationRef.isReady()) {
                            navigationRef.navigate('Défis')
                        }
                        // alert("Un abonné vous a défié.\n Rendez vous sur l'onglet Défis")
                    }
                }
            }
        }
    }

    useEffect(() => {
        navigator.serviceWorker.addEventListener("message", handleServiceWorkerMessage);

        // Cleanup to avoid potential memory leaks
        return () => {
            navigator.serviceWorker.removeEventListener("message", handleServiceWorkerMessage);
        };
    }, [canRecieveNotification])


    const handleOpenModalProfil = (user_id) => {
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setModalProfileVisible(false)
    }

    return (
        loadingAccess ? (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color={COLORS.primary} />
            </View>
        ) : (
            !isLoggedIn ? (
                <Login isLoading={isLoading}/>
            ) : (
                <SafeAreaView style={{ flex: 1 }}>
                    <StatusBar barStyle="dark-content" />

                    <NavigationContainer ref={navigationRef}>
                        <Tab.Navigator initialRouteName={hasAccessToPlateau ? "Plateau" : "Accueil"}>
                            <Tab.Screen
                                name="Accueil"
                                component={Accueil}
                                options={{
                                    headerShown: false,
                                    tabBarIcon: ({color, size}) => (
                                        <FontAwesome name="home" color={color} size={size} />
                                    )
                                }}
                            />

                            <Tab.Screen
                                name="Classement"
                                component={Classement}
                                options={{
                                    headerShown: false,
                                    tabBarIcon: ({color, size}) => (
                                        <FontAwesome name="trophy" color={color} size={size} />
                                    )
                                }}
                            />

                            <Tab.Screen
                                name="Défis"
                                component={DefisStack}
                                options={({ navigation }) => ({
                                    headerShown: false,
                                    tabBarIcon: ({ color, size }) => (
                                        <FontAwesome name="flag" color={color} size={size} />
                                    ),
                                })}
                            />

                            <Tab.Screen
                                name="Plateau"
                                component={PlateauStack}
                                options={{
                                    headerShown: false,
                                    tabBarIcon: ({color, size}) => (
                                        <MaterialCommunityIcons name="checkerboard" color={color} size={size} />
                                    ),
                                    tabBarStyle: { display: 'none' }
                                }}
                            />
                            <Tab.Screen
                                name="Comptes"
                                component={Compte}
                                options={{
                                    headerShown: false,
                                    tabBarIcon: ({color, size}) => (
                                        <FontAwesome name="user-circle" color={color} size={size} />
                                    )
                                }}
                            />
                        </Tab.Navigator>
                    </NavigationContainer>




                    <Modal visible={modalProfileVisible}>
                        <ProfileModal onClose={handleCloseProfil} user_id={selectedFriendId} />
                    </Modal>
                </SafeAreaView>
            )
        )
    )
}

export default App;

serviceWorkerRegistration.register();
