import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    Text,
    ScrollView,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    Image,
    SafeAreaView, Dimensions
} from 'react-native';
import Feather from "react-native-vector-icons/Feather";
// import VideoPlayer from 'react-native-video-player';
import { COLORS, SIZES, FONTS, ROUNDED } from "../../constants/theme";
import PlateauService from '../../services/PlateauService';
import ErrorNotification from "../../components/ErrorNotification";
import SelectWithGroups from "../../components/SelectWithGroups";
import ModalComponent from "../../components/ModalComponent";
import QuestionService from "../../services/QuestionService";

function InfosJoueurs({ route, navigation }) {
    const [error, setError] = useState(null);
    const { numPlayers } = route.params;
    const [playersInfo, setPlayersInfo] = useState([]);
    const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
    const [loading , setLoading] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const videoPlayerRef = useRef(null);
    const scrollViewRef = useRef(null);
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const [categories, setCategories] = useState(null)

    const categoryColors = {
        1: COLORS.contrat.blue,
        2: COLORS.contrat.red,
        3: COLORS.contrat.green,
        4: COLORS.contrat.purple,
        5: COLORS.contrat.orange,
    };




    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const dataCat = await QuestionService.getCategories();
                setCategories(dataCat)
            } catch (error) {
                setError({code: 4010, message: "Les catégories n'ont pas pu être récupérés"})
            }
        }

        fetchCategories()
    }, [])

    const imageUrls = [
        require('../../../assets/avatars/Avatar_1_Jeune_Garcon_xl.png'),
        require('../../../assets/avatars/Avatar_2_Pompier_action_xl.png'),
        require('../../../assets/avatars/Avatar_3_Pompier_femme_xl.png'),
        require('../../../assets/avatars/Avatar_4_Jeune_Fille_xl.png'),
        require('../../../assets/avatars/Avatar_5_Pompier_homme_xl.png'),
        require('../../../assets/avatars/Avatar_6_Femme_xl.png'),
    ];

    // Initialize the playersInfo state with the required number of player objects
    useEffect(() => {
        setPlayersInfo(Array(numPlayers).fill({
            pseudo: '',
            profil: 'Pompier',
            niveau: 'Normal',
            avatar: 0,
            categories: {1:1, 2:2, 3:3, 4:4, 5:5}
        }));
    }, [numPlayers]);

    const getCategoryLabel = (categoryId) => {
        // Parcourir toutes les catégories pour trouver le label correspondant à l'ID
        for (let group of categories) {
            let foundOption = group.options.find(option => option.id == categoryId);
            if (foundOption) {
                return group.label.charAt(0) + '. ' + foundOption.name;
            }
        }
        return "Choisir catégorie"; // Retourner un texte par défaut si aucune catégorie n'est trouvée
    };

    const renderCategoryCard = (index) => {
        const cardColor = categoryColors[index.toString()]; // Assurez-vous que categoryColors est correctement indexé.
        const isSelected = activeCardIndex === index;
        // Obtenir l'ID de la catégorie actuellement sélectionnée pour ce joueur
        const categoryId = playersInfo[currentPlayerIndex]?.categories[index];
        // Utiliser la fonction getCategoryLabel pour obtenir le label correspondant
        const categoryLabel = getCategoryLabel(categoryId);

        return (
            <View key={index} style={{paddingLeft: 30, borderWidth: 1, borderColor: COLORS.gray, marginBottom: 10}}>
                <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                    <View style={{width: 30, height: 33, marginRight: 5, backgroundColor: cardColor}}></View>
                    <TouchableOpacity
                        onPress={() => setActiveCardIndex(isSelected ? null : index)}
                        style={{flexDirection: "row", width: "95%", borderWidth: 1, borderColor: COLORS.black1, paddingHorizontal: 5, paddingVertical: 2, justifyContent: "space-between", alignItems: "center"}}>
                        <Text>{categoryLabel}</Text>
                        <Feather name="chevron-down" size={25} />
                    </TouchableOpacity>
                </View>
            </View>
        );
    };



    const handleInputChange = (field, value) => {
        const updatedPlayersInfo = [...playersInfo];
        updatedPlayersInfo[currentPlayerIndex][field] = value;
        setPlayersInfo(updatedPlayersInfo);
    };

    const handleNext = () => {
        const currentPlayerInfo = playersInfo[currentPlayerIndex];

        // Check if the pseudo field is empty
        if (!currentPlayerInfo?.pseudo) {
            // Display an error message or take any other appropriate action
            setError({code: '', message: "Le pseudo est obligatoire"})
            return;
        }

        if (currentPlayerIndex < numPlayers - 1) {
            // Create a new player object for the next player
            const newPlayer = { pseudo: '', profil: 'Pompier', niveau: 'Normal', avatar: 0, categories: {1:1, 2:2, 3:3, 4:4, 5:5} };
            setPlayersInfo((prevPlayersInfo) => {
                // Copy the previous players' info and replace the current player's info with the new player object
                const updatedPlayersInfo = [...prevPlayersInfo];
                updatedPlayersInfo[currentPlayerIndex] = { ...updatedPlayersInfo[currentPlayerIndex] };
                updatedPlayersInfo[currentPlayerIndex + 1] = newPlayer;
                return updatedPlayersInfo;
            });
            setCurrentPlayerIndex(currentPlayerIndex + 1);

            scrollViewRef.current.scrollTo({ y: 0, animated: true });
        } else {
            handleStartGame();
        }
    };


    const handleGoBack = () => {
        if (currentPlayerIndex > 0) {
            setCurrentPlayerIndex(currentPlayerIndex - 1);
            scrollViewRef.current.scrollTo({ y: 0, animated: true });
        } else {
            navigation.goBack();
        }
    };

    const handleNextPage = () => {
        navigation.navigate('SelectTypeGame', { playersInfo: playersInfo });
    }
    const handleStartGame = async () => {
        if (!playersInfo[currentPlayerIndex].pseudo) {
            return; // Don't proceed if the pseudo is not set
        }

        try {
            setLoading(true); // Set loading to true before making the API call

            // Make the API call to start the game
            const result = await PlateauService.startGame(playersInfo);

            if (result && result.id) {
                navigation.navigate('Partie');
            } else {
                // Handle the case when the result is not ok
                setError({code: 4003})
            }
        } catch (error) {
            // Handle any errors
            setError({code: 4004})
        } finally {
            setLoading(false); // Set loading back to false after the API call is finished
        }
    };


    const handleStartVideo = async () => {
        if (!playersInfo[currentPlayerIndex].pseudo) {
            return; // Don't proceed if the pseudo is not set
        }

        setIsVideoPlaying(true);
    }

    const handlePasser = async () => {
        setIsVideoPlaying(false);
        handleStartGame();
    };

    const handlePreviousImage = () => {
        setPlayersInfo((prevPlayersInfo) => {
            const updatedPlayersInfo = [...prevPlayersInfo];
            const currentPlayerInfo = updatedPlayersInfo[currentPlayerIndex];
            currentPlayerInfo.avatar = currentPlayerInfo.avatar > 0 ? currentPlayerInfo.avatar - 1 : imageUrls.length - 1;
            return updatedPlayersInfo;
        });
    };

    const handleNextImage = () => {
        setPlayersInfo((prevPlayersInfo) => {
            const updatedPlayersInfo = [...prevPlayersInfo];
            const currentPlayerInfo = updatedPlayersInfo[currentPlayerIndex];
            currentPlayerInfo.avatar = currentPlayerInfo.avatar < imageUrls.length - 1 ? currentPlayerInfo.avatar + 1 : 0;
            return updatedPlayersInfo;
        });
    };



    const handleSelectCategory = (categoryId) => {
        // Mettre à jour l'état avec la nouvelle catégorie pour le joueur et la carte actuelle
        const updatedPlayersInfo = playersInfo.map((player, index) => {
            if (index === currentPlayerIndex) {
                return {
                    ...player,
                    categories: {
                        ...player.categories,
                        [activeCardIndex]: categoryId
                    }
                };
            }
            return player;
        });
        setPlayersInfo(updatedPlayersInfo);
        setActiveCardIndex(null);  // Fermer le modal
    };



    const renderPlayerInput = () => {
        const playerInfo = playersInfo[currentPlayerIndex];

        return (
            <View style={styles.playerInputContainer}>
                <View>
                    <View style={styles.containerSlider}>
                        <TouchableOpacity style={{padding: 15}} onPress={handlePreviousImage}>
                            <Feather name="arrow-left-circle" size={40} />
                        </TouchableOpacity>

                        <View style={styles.containerFlatList}>
                            <View  style={styles.containerImage}>
                                { playerInfo && (
                                    <Image source={imageUrls[playerInfo?.avatar]} style={styles.image} resizeMode="cover" />
                                )}
                            </View>
                        </View>

                        <TouchableOpacity style={{padding: 15}} onPress={handleNextImage}>
                            <Feather name="arrow-right-circle" size={40} />
                        </TouchableOpacity>
                    </View>
                </View>

                <Text style={styles.playerInputLabel}>Pseudo</Text>
                { playerInfo && (
                    <TextInput
                        style={styles.playerInput}
                        placeholder="Pseudo"
                        onChangeText={(value) => handleInputChange('pseudo', value)}
                        value={playerInfo?.pseudo}
                    />
                )}

                <Text style={styles.playerInputLabel}>Profil</Text>
                <View style={styles.radioGroup}>
                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.profil === 'Pompier' && styles.radioButtonSelected,
                        ]}
                        onPress={() => handleInputChange('profil', 'Pompier')}
                    >
                        <Text style={styles.radioButtonText}>Pompier</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.profil === 'Citoyen' && styles.radioButtonSelected,
                        ]}
                        onPress={() => handleInputChange('profil', 'Citoyen')}
                    >
                        <Text style={styles.radioButtonText}>Citoyen</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.profil === 'Custom' && styles.radioButtonSelected,
                        ]}
                        onPress={() => handleInputChange('profil', 'Custom')}
                    >
                        <Text style={styles.radioButtonText}>Personnalisé</Text>
                    </TouchableOpacity>
                </View>
                {playerInfo?.profil === 'Custom' && [1, 2, 3, 4, 5].map((index) => renderCategoryCard(index))}

                <ModalComponent
                    visible={activeCardIndex !== null}
                    onClose={() => setActiveCardIndex(null)}
                >
                    <ScrollView>
                        <Text style={{fontSize: SIZES.xl, fontWeight: FONTS.bold, marginBottom: 10}}>Séléctionner une catégorie :</Text>
                        {
                            categories?.map((group) => (
                                <View key={group.label}>
                                    <Text style={{fontWeight: FONTS.bold}}>{group.label}</Text>
                                    {group.options.map((option) => (
                                        <TouchableOpacity onPress={() => handleSelectCategory(option.id)} style={styles.radioButton} key={option.id}>
                                            <Text>{option.name}</Text>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            ))
                        }

                    </ScrollView>
                </ModalComponent>


                <Text style={styles.playerInputLabel}>Niveau</Text>
                <View style={styles.radioGroup}>
                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.niveau === 'Facile' && styles.radioButtonSelected,
                            { borderColor: COLORS.blue },
                            { backgroundColor: playerInfo?.niveau === 'Facile' ? COLORS.blue : COLORS.white },
                        ]}
                        onPress={() => handleInputChange('niveau', 'Facile')}
                    >
                        <Text
                            style={[
                                styles.radioButtonText,
                                { color: playerInfo?.niveau === 'Facile' ? COLORS.white : COLORS.blue },
                            ]}
                        >
                            Facile
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.niveau === 'Normal' && styles.radioButtonSelected,
                            { borderColor: COLORS.primary },
                            { backgroundColor: playerInfo?.niveau === 'Normal' ? COLORS.primary : COLORS.white },
                        ]}
                        onPress={() => handleInputChange('niveau', 'Normal')}
                    >
                        <Text
                            style={[
                                styles.radioButtonText,
                                { color: playerInfo?.niveau === 'Normal' ? COLORS.white : COLORS.primary },
                            ]}
                        >
                            Intermédiaire
                        </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[
                            styles.radioButton,
                            playerInfo?.niveau === 'Difficile' && styles.radioButtonSelected,
                            { borderColor: COLORS.red },
                            { backgroundColor: playerInfo?.niveau === 'Difficile' ? COLORS.red : COLORS.white },
                        ]}
                        onPress={() => handleInputChange('niveau', 'Difficile')}
                    >
                        <Text
                            style={[
                                styles.radioButtonText,
                                { color: playerInfo?.niveau === 'Difficile' ? COLORS.white : COLORS.red },
                            ]}
                        >
                            Difficile
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };



    return (
        <SafeAreaView style={{flex: 1, alignItems: "center", backgroundColor: COLORS.white,}}>
            <ScrollView ref={scrollViewRef} contentContainerStyle={{ flexGrow: 1, maxHeight: 50, paddingBottom: 10 }} style={styles.container}>
                <Text style={styles.title}>{`Joueur ${currentPlayerIndex + 1}`}</Text>
                {renderPlayerInput()}

                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={[styles.button, styles.buttonRetour]} onPress={handleGoBack}>
                        <Text style={styles.buttonTextRetour}>Retour</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={handleNext}>
                        <Text style={styles.buttonText}>
                            Suivant
                            {/*{currentPlayerIndex === numPlayers - 1 ? 'Démarrer' : 'Suivant'}*/}
                        </Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        height: '80%',
        paddingHorizontal: 30,
        maxWidth: 500,
        width: "100%",
        paddingBottom: 10
    },
    passerButton: {
        backgroundColor: COLORS.primary,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        paddingVertical: 10,
        paddingHorizontal: 30,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    passerButtonText: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    title: {
        fontSize: SIZES["2xl"],
        fontWeight: FONTS.bold,
        textAlign: "center",
        marginTop: 20
    },
    playerInputContainer: {
        marginBottom: 30,
        width: '100%',
    },
    playerInputLabel: {
        fontSize: SIZES.lg,
        fontWeight: FONTS.semibold,
        marginBottom: 5,
    },
    playerInput: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkGray,
        borderWidth: 1,
        width: '100%',
        height: 50,
        paddingHorizontal: 10,
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 20
    },
    button: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        paddingVertical: 10,
        paddingHorizontal: 30,
        alignItems: 'center',
        justifyContent: 'center',
        width: '48%',
    },
    buttonText: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    buttonRetour: {
        backgroundColor: COLORS.gray,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkGray,
    },
    buttonTextRetour: {
        color: COLORS.darkerGray,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    radioGroup: {
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '100%',
    },
    radioButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkGray,
        borderWidth: 1,
        width: '100%',
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5
    },
    radioButtonSelected: {
        backgroundColor: COLORS.primary,
    },
    radioButtonText: {
        color: COLORS.black,
        fontWeight: FONTS.medium,
        fontSize: SIZES.lg,
        paddingHorizontal: 10,
    },
    containerSlider:{
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 10,
    },
    containerFlatList: {
        alignItems: 'center',
        marginHorizontal: 10,
        width: 170,
        height: 140,
    },
    containerImage: {
        width: 140,
        height: 140,
        overflow: "hidden",
        borderRadius: ROUNDED.full
    },
    image: {
        flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
    },
});

export default InfosJoueurs;
