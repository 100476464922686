import React, { useState } from 'react';
import {View, Text, StyleSheet, TouchableOpacity, SafeAreaView, ScrollView} from 'react-native';
import {COLORS, SIZES, FONTS, ROUNDED} from "../../constants/theme";
import CustomButton from "../../components/CustomButton";
import * as helper from "../../utils/helpers";

function NombreJoueurs({ navigation }) {
    const [selectedPlayers, setSelectedPlayers] = useState(2);

    const handlePlayerSelection = (numPlayers) => {
        setSelectedPlayers(numPlayers);
    };

    const handleNext = () => {
        // Pass the selected number of players to the next screen
        navigation.navigate('InfosJoueurs', { numPlayers: selectedPlayers });
    };

    const handleGoBack = () => {
        navigation.goBack();
    };

    return (
        <SafeAreaView style={{flex: 1, alignItems: "center"}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, maxHeight: 50, paddingBottom: 10 }} style={{flex: 1}}>
                <View style={styles.container}>
                    <Text style={styles.title}>Sélectionner le nombre de joueurs :</Text>
                    <View style={styles.radioGroup}>
                        <TouchableOpacity
                            style={[
                                styles.radioButton,
                                selectedPlayers === 2 && styles.radioButtonSelected,
                            ]}
                            onPress={() => handlePlayerSelection(2)}
                        >
                            <Text style={[styles.radioButtonText, selectedPlayers === 2 && styles.radioButtonTextSelected]}>2</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.radioButton,
                                selectedPlayers === 3 && styles.radioButtonSelected,
                            ]}
                            onPress={() => handlePlayerSelection(3)}
                        >
                            <Text style={[styles.radioButtonText, selectedPlayers === 3 && styles.radioButtonTextSelected]}>3</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.radioButton,
                                selectedPlayers === 4 && styles.radioButtonSelected,
                            ]}
                            onPress={() => handlePlayerSelection(4)}
                        >
                            <Text style={[styles.radioButtonText, selectedPlayers === 4 && styles.radioButtonTextSelected]}>4</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.radioButton,
                                selectedPlayers === 5 && styles.radioButtonSelected,
                            ]}
                            onPress={() => handlePlayerSelection(5)}
                        >
                            <Text style={[styles.radioButtonText, selectedPlayers === 5 && styles.radioButtonTextSelected]}>5</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.radioButton,
                                selectedPlayers === 6 && styles.radioButtonSelected,
                            ]}
                            onPress={() => handlePlayerSelection(6)}
                        >
                            <Text style={[styles.radioButtonText, selectedPlayers === 6 && styles.radioButtonTextSelected]}>6</Text>
                        </TouchableOpacity>
                    </View>

                    <CustomButton
                        title={'Suivant'}
                        onPress={handleNext}
                        width="80%"
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 30,
        width: '100%',
        maxWidth: 400,
    },
    title: {
        fontSize: helper.isLittleScreen() ? SIZES["base"] : SIZES["xl"],
        fontWeight: FONTS.semibold,
        textAlign: "center"
    },
    radioGroup: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 30,
        width: '100%',
        paddingHorizontal: 30
    },
    radioButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkGray,
        borderWidth: 1,
        width: '100%',
        height: helper.isLittleScreen() ? 40 : 50,
        alignItems: 'center',
        justifyContent: 'center',
        margin: helper.isLittleScreen() ? 5 : 8,

    },
    radioButtonSelected: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
    radioButtonText: {
        color: COLORS.black,
        fontWeight: FONTS.bold,
        fontSize: helper.isLittleScreen() ? SIZES["lg"] : SIZES["2xl"],
    },
    radioButtonTextSelected: {
        color: COLORS.white,
    },
    nextButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%'
    },
    nextButtonText: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
});

export default NombreJoueurs;
