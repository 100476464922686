import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
const AuthService = {
    check: async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/check_login`, {},{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log('Error checking login:', error);
            return {auth: false};
        }
    },
    check_expo_token: async (expoToken) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/check_expo_token`, {token: expoToken},{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error checking expo token:', error);
        }
    },
    login: async (email, password) => {
        try {
            const response = await axios.post(`${API_URL}/login`, {
                email: email,
                password: password,
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                if(error.response.status === 401) {
                    throw new Error('invalid credentials');
                }
                else if (error.response.status === 403) {
                    throw new Error('mobile_access');
                }
            }
            else {
                // Handle any other errors
                // console.error('Error logging in:', error);
                throw error;
            }
        }
    },
    register: async (name, email, password, password_confirmation) => {
        try {
            const response = await axios.post(`${API_URL}/register`, {
                name: name,
                email: email,
                password: password,
                password_confirmation: password_confirmation,
            });


            if (response.status === 201 || response.status === 200) {
                return { success: true, data: response.data };
            } else {
                return { success: false, message: 'Une erreur est survenue lors de l\'inscription. Veuillez réessayer.' };
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422) {
                    const errorData = error.response.data;
                    if (errorData.errors) {
                        // Handle validation errors (e.g., email format, password requirements)
                        return { success: false, errors: errorData.errors };
                    }
                } else if (error.response.status === 401) {
                    throw new Error('invalid credentials');
                } else if (error.response.status === 403) {
                    throw new Error('mobile_access');
                }
            }

            // Handle any other errors
            // console.error('Error registering:', error);
            throw { success: false, message: 'Une erreur est survenue lors de l\'inscription. Veuillez réessayer.' };
        }
    }
}

export default AuthService;
