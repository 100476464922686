import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    View,
    Text,
    FlatList,
    Image,
    TouchableOpacity,
    StyleSheet,
    ScrollView,
    SafeAreaView,
    Modal,
    RefreshControl, Linking
} from 'react-native';
import {ActivityIndicator} from "react-native-paper";
import {COLORS, FONTS, ROUNDED, SIZES} from "../constants/theme";
import {useFocusEffect} from "@react-navigation/native";
import ClassementService from "../services/ClassementService";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Entypo from "react-native-vector-icons/Entypo";
import * as helper from "../utils/helpers";
import {ROOT_URL} from "../constants/api";
import ProfileModal from "../components/ProfileModal";
import ErrorNotification from "../components/ErrorNotification";
import UserService from "../services/UserService";
import CustomButton from "../components/CustomButton";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {AuthContext} from "../utils/AuthContext";


function Classement({ route }) {
    const [error, setError] = useState(null);
    const { checkLoginStatus } = useContext(AuthContext);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [classements, setClassements] = useState([]);
    const [type, setType] = useState((route.params?.showLastWeekClassement ? 'resultat' : 'global') || 'global');
    const [nextPage, setNextPage] = useState(1);
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [mobileAccess, setMobileAccess] = useState(false)


    useFocusEffect(
        useCallback(() => {
            if(route.params?.showLastWeekClassement){
                setType('resultat')
                route.params.showLastWeekClassement = false;
            }
            checkMobileAccess();

            fetchClassement();
        }, [type])
    );


    const checkMobileAccess = async () => {
        const checkAccess = await UserService.checkMobileAccess();
        if (checkAccess) {
            setMobileAccess(checkAccess.has_access);
        }
        setLoadingInitial(false); // Mettre fin au chargement initial
    };
    const handleLogout = async () => {
        try {
            // Clear access token from AsyncStorage
            await AsyncStorage.removeItem('token');
            checkLoginStatus()
        } catch (error) {
            setError({code: 5004})
        }
    };

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        fetchClassement();
        setRefreshing(false);
    }, []);

    const fetchClassement = async () => {
        try {
            const classementData = await ClassementService.classement(type, nextPage)
            setClassements(classementData)

        } catch (error) {
            setError({code: 2000, message: "Le classement est indisponible. Veuillez réessayer plus tard."})
        }
    }


    const handleSelectType = (newType) => {
        setType(newType)
        setClassements([])
    }

    const handleOpenProfil = (user_id) => {
        setSelectedUserId(user_id)
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setSelectedUserId(null)
        setModalProfileVisible(false)
    }

    const renderItem = (item, index) => {
        const backgroundColor = item.current_player === 1 ? COLORS.darkerGray : COLORS.white;
        const textColor = item.current_player === 1 ? COLORS.white : COLORS.darkerGray;
        const borderColor = item.current_player === 1 ? COLORS.primary : COLORS.gray;

        return (
            <TouchableOpacity key={index} onPress={() => handleOpenProfil(item.user_id)} style={[styles.row, {backgroundColor: backgroundColor, borderColor: borderColor}]}>
                <View style={styles.rowText}>
                    <View style={styles.itemPosition}>
                        { item.positionChange > 0 && (
                            <Entypo name="triangle-up" color={COLORS.green} size={15} style={{position: "absolute", left: -15}} />
                        )}
                        { item.positionChange < 0 && (
                            <Entypo name="triangle-down" color={COLORS.red} size={15} style={{position: "absolute", left: -15}} />
                        )}
                        <Text style={{color: textColor, fontSize: helper.isLittleScreen() ? SIZES['xs'] : SIZES['sm']}}>{item.position}.</Text>
                    </View>
                    <View style={styles.containerImageProfil}>
                        <View style={[styles.avatarContainer]}>
                            {item.avatar && (
                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${item.avatar.replace('.svg', '.png')}`}}/>
                            )}
                        </View>
                        {item.rank_id && (
                            <Image style={{width: '100%', height: '100%', resizeMode: "contain", position: "absolute"}} source={helper.getRankById(item.rank_id)}/>
                        )}
                    </View>
                    <View style={styles.rowText}>
                        <Text style={[styles.playerName, {color: textColor}]}>{item.name}</Text>
                        { [1, 2, 3].includes(item.position) && (
                            <FontAwesome5 name="crown" color={COLORS.primary} style={styles.crownIcon} size={10} />
                        )}
                    </View>
                </View>
                <Text style={[styles.playerScore, {color: textColor}]}>{item.total_points}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            { loadingInitial ? (
                <ActivityIndicator animating={true} size="large" color={COLORS.primary} style={styles.loadingIndicator} />
            ) : mobileAccess ? (
                <View style={styles.container}>
                    <Text style={styles.title}>Classement</Text>
                    <View style={styles.buttonRow}>
                        <TouchableOpacity onPress={() => handleSelectType('global')} style={[styles.typeButton, type === 'global' && styles.typeButtonSelected]}>
                            <Text style={styles.typeText}>Global</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleSelectType('trimestre')} style={[styles.typeButton, type === 'trimestre' && styles.typeButtonSelected]}>
                            <Text style={styles.typeText}>Trimestre</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => handleSelectType('semaine')} style={[styles.typeButton, type === 'semaine' && styles.typeButtonSelected]}>
                            <Text style={styles.typeText}>Semaine</Text>
                        </TouchableOpacity>
                    </View>

                    <ScrollView refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                    }>
                        { type === 'resultat' && (
                            <View style={styles.containerResultat}>
                                <Text style={styles.titleResultat}>Résultat de la semaine passée</Text>

                                <View style={styles.containerPodium}>

                                    { classements?.length > 1 && (
                                        <View style={[styles.containerBoxPodium]}>
                                            <View style={[styles.boxPodium]}>
                                                <Text style={[styles.textTop1, {fontWeight: FONTS.bold}]}>2</Text>
                                                <View style={[styles.containerAvatarPodium2]}>
                                                    {classements[1]['avatar'] && (
                                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${classements[1]['avatar'].replace('.svg', '.png')}`}}/>
                                                    )}
                                                </View>
                                                <Text style={styles.textPseudoPodium}>{helper.truncateString(classements[1]['name'], 15)}</Text>
                                                <Text style={styles.textPointPodium}>{classements[1]['total_points']}</Text>
                                            </View>
                                        </View>
                                    )}
                                    { classements?.length > 0 && (
                                        <View style={[styles.containerBoxPodium, {marginHorizontal: 10}]}>
                                            <View style={[styles.boxPodium, {marginBottom: 80}]}>
                                                <View style={styles.boxCrown}>
                                                    <FontAwesome5 name={"crown"} size={55} color={COLORS.primary} />
                                                    <View style={styles.boxTextTop1}>
                                                        <Text style={styles.textTop1}>1</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.containerAvatarPodium1]}>
                                                    {classements[0]['avatar'] && (
                                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${classements[0]['avatar'].replace('.svg', '.png')}`}}/>
                                                    )}
                                                </View>
                                                <Text style={styles.textPseudoPodium}>{helper.truncateString(classements[0]['name'], 15)}</Text>
                                                <Text style={styles.textPointPodium}>{classements[0]['total_points']}</Text>
                                            </View>
                                        </View>
                                    )}

                                    { classements?.length > 2 && (
                                        <View style={styles.containerBoxPodium}>
                                            <View style={[styles.boxPodium]}>
                                                <Text style={[styles.textTop1, {fontWeight: FONTS.semibold}]}>3</Text>
                                                <View style={[styles.containerAvatarPodium3]}>
                                                    {classements[2]['avatar'] && (
                                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${classements[2]['avatar'].replace('.svg', '.png')}`}}/>
                                                    )}
                                                </View>
                                                <Text style={styles.textPseudoPodium}>{helper.truncateString(classements[2]['name'], 15)}</Text>
                                                <Text style={styles.textPointPodium}>{classements[2]['total_points']}</Text>
                                            </View>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}
                        { classements && (
                            <ScrollView style={styles.listContent}>
                                {classements.map((item, index) => (
                                    renderItem(item, index)
                                ))}
                            </ScrollView>
                        )}

                        {/*<FlatList*/}
                        {/*    data={classements}*/}
                        {/*    keyExtractor={(item, index) => item.position}*/}
                        {/*    renderItem={renderItem}*/}
                        {/*    contentContainerStyle={styles.listContent}*/}
                        {/*    refreshControl={*/}
                        {/*        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />*/}
                        {/*    }*/}
                        {/*/>*/}
                    </ScrollView>

                </View>
            ) : (
                <View style={{justifyContent: 'center', alignItems: 'center', padding: 30}}>
                    <View style={{width: '500px', height: '300px',  padding: 30, borderRadius: ROUNDED.lg, backgroundColor: 'white', justifyContent: 'space-between'}}>
                        <View>
                            <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.bold, marginBottom: '15px'}}>Abonnement application requis</Text>
                            <Text>Bonjour <Text style={{fontWeight: FONTS.semibold}}>{'test'}</Text>, Vous devez être abonné à l'application pour accéder à cette fonctionnalité. Veuillez vous abonner pour profiter pleinement de cette expérience.</Text>
                        </View>

                        <View>
                            <CustomButton title="S'abonner" onPress={() =>
                                Linking.openURL('https://objectifofficiersp.com/boutique')}
                            />
                            <CustomButton
                                title="Se déconnecter"
                                onPress={ handleLogout }
                                backgroundColor={COLORS.red}
                                borderColor={COLORS.lightRed}
                                shadowColor={COLORS.darkRed}
                                textColor={COLORS.white}
                            />
                        </View>

                    </View>

                </View>
            )}

            <Modal visible={modalProfileVisible}>
                <ProfileModal onClose={handleCloseProfil} user_id={selectedUserId} />
            </Modal>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-between",
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.full,
        borderWidth: 1,
        borderColor: COLORS.gray,
        marginBottom: 5,
        paddingHorizontal: 20,
        paddingVertical: helper.isLittleScreen() ? 3 : 5
    },
    itemPosition: {
        position: "relative",
        marginRight: 8,
    },
    containerImageProfil: {
        position: "relative",
        flexDirection: "row",
        width: helper.isLittleScreen() ? 40 : 50,
        height: helper.isLittleScreen() ? 40 : 50,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    avatarContainer: {
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 30 : 40,
        height: helper.isLittleScreen() ? 30 : 40,
        aspectRatio: 1,
        backgroundColor: COLORS.darkGray,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    },
    rowText: {
        flexDirection: "row",
        alignItems: 'center'
    },
    playerName: {
        fontSize: SIZES['sm']
    },
    crownIcon: {
        marginLeft: 5,
        marginBottom: 2
    },
    playerScore: {
        fontSize: helper.isLittleScreen() ? SIZES['xs'] : SIZES['sm'],
        fontWeight: FONTS.semibold
    },
    typeButton: {
        backgroundColor: COLORS.white,
        width: '32%',
        alignItems: "center",
        borderRadius: ROUNDED.full,
        paddingVertical: helper.isLittleScreen() ? 6 : 8,
        paddingHorizontal: 3
    },
    typeButtonSelected: {
        backgroundColor: COLORS.primary
    },
    typeText: {
        fontSize: helper.isLittleScreen() ? SIZES['xs'] : SIZES['sm'],
        fontWeight: FONTS.semibold,
        color: COLORS.black
    },
    container: {
        flex: 1
    },
    title: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 0 : 5,
        textAlign: "center"
    },
    buttonRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        marginTop: 10
    },
    listContent: {
        flex: 1,
        marginTop: 5,
        paddingVertical:  helper.isLittleScreen() ? 6 : 10,
        paddingHorizontal: 30,
    },
    containerResultat: {
        alignItems: "center",
        marginTop:  helper.isLittleScreen() ? 15 : 20,
        paddingHorizontal: 30
    },
    titleResultat: {
        fontSize: helper.isLittleScreen() ? SIZES['lg'] : SIZES['xl'],
        color: COLORS.black1,
        fontWeight: FONTS.bold,
        marginBottom: 10,
        textAlign: "center",
        textDecorationLine: "underline"
    },
    containerPodium: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    containerBoxPodium: {

    },
    boxPodium: {
        alignItems: "center",
    },
    boxCrown: {
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 7
    },
    boxTextTop1: {
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        // backgroundColor: COLORS.lightPrimary,
        aspectRatio: 1,
        width: 25,
        borderRadius: ROUNDED.full,
        top: 17,
    },
    textTop1: {
        fontSize: SIZES['2xl'],
        color: COLORS.black,
        fontWeight: FONTS.black
    },
    containerAvatarPodium1: {
        width: 120,
        height: 120,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: COLORS.white
    },
    containerAvatarPodium2: {
        width: 86,
        height: 86,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: COLORS.white
    },
    containerAvatarPodium3: {
        width: 86,
        height: 86,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: COLORS.white
    },
    textPseudoPodium: {
        marginTop: 5,
        marginBottom: 2,
        fontSize: SIZES['sm'],
        color: COLORS.darkerGray,
    },
    textPointPodium: {
        fontWeight: FONTS.bold,
        fontSize: SIZES['sm'],
        color: COLORS.black1,
    }
});

export default Classement;
