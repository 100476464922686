import {Image, Modal, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {ROOT_URL} from "../constants/api";
import * as helper from "../utils/helpers";
import {COLORS, FONTS, ROUNDED, SIZES} from "../constants/theme";
import ProfileModal from "./ProfileModal";
import ModalComponent from "./ModalComponent";
import React, {useState} from "react";
import ModalSearchProfile from "./ModalSearchProfile";
import CustomButton from "./CustomButton";

function ModalListFriends({ visible, onClose, friends }) {
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [modalSearchProfilVisible, setModalSearchProfilVisible] = useState(false);
    const [selectedFriendId, setSelectedFriendId] = useState(null);



    const handleOpenModalProfil = (user_id) => {
        setSelectedFriendId(user_id)
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setModalProfileVisible(false)
    }


    const handleOpenModalSearchProfil = () => {
        setModalSearchProfilVisible(true)
    }

    const handleCloseSearchProfil = () => {
        setModalSearchProfilVisible(false)
    }


    return (
        <SafeAreaView style={{flex: 1, position: 'relative'}}>
            <ScrollView style={{flexGrow: 1, flex: 1, paddingTop:  helper.isLittleScreen() ? 6 : 10, paddingBottom: 80, paddingHorizontal: 30,}}>
                <Text style={styles.title}>Mes amis</Text>
                <View style={{marginBottom: 20}}>
                    <CustomButton
                        onPress={handleOpenModalSearchProfil}
                        title="Ajouter des amis"
                    />
                </View>
                {friends.map((item, index) => (
                    <View key={index}>
                        <View style={styles.containerFriends}>
                            <View style={styles.boxInfoFriend}>
                                <View style={styles.containerImageProfil}>
                                    <View style={[styles.avatarContainer]}>
                                        {item.user_avatar && (
                                            <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${item.user_avatar.replace('.svg', '.png')}`}}/>
                                        )}
                                    </View>
                                    {item.rank && item.rank.split(',')[0] && (
                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain", position: "absolute"}} source={helper.getRankById(parseInt(item.rank.split(',')[0]))}/>
                                    )}
                                </View>
                                <View>
                                    <Text style={styles.friendName}>{item.user_name}</Text>
                                    <Text style={[styles.friendRank]}>{item.rank ? item.rank.split(',')[1] : ''} {item.user_points} pts</Text>
                                </View>
                            </View>
                            <TouchableOpacity style={styles.buttonProfil} onPress={() => handleOpenModalProfil(item.user_id)}>
                                <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black}}>Voir</Text>
                            </TouchableOpacity>
                        </View>
                        {index < friends.length - 1 && <View style={styles.separator} />}
                    </View>
                ))}


                <Modal visible={modalProfileVisible}>
                    <ProfileModal onClose={handleCloseProfil} user_id={selectedFriendId} />
                </Modal>

                <Modal visible={modalSearchProfilVisible}>
                    <ModalSearchProfile onClose={handleCloseSearchProfil} friends={friends} />
                </Modal>
            </ScrollView>

            <View style={{position: "absolute", bottom: 8, width: "100%", paddingHorizontal: 30, alignItems: "center"}}>
                <CustomButton
                    onPress={onClose}
                    title={'Fermer'}
                    width={'70%'}
                    backgroundColor={COLORS.gray}
                    borderColor={COLORS.lightGray}
                    shadowColor={COLORS.darkerGray}
                    textColor={COLORS.darkerGray}
                />
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    title: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 8 : 10,
        textAlign: "center"
    },
    containerFriends: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    boxInfoFriend: {
        flexDirection: "row",
        alignItems: "center",
    },
    friendName:{
        fontSize: SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    friendRank:{
        fontSize: SIZES.xs,
        color: COLORS.darkerGray
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.gray,
        marginVertical: helper.isLittleScreen() ? 15 : 20,
        width: "100%"
    },
    containerImageProfil: {
        position: "relative",
        flexDirection: "row",
        width: helper.isLittleScreen() ? 40 : 50,
        height: helper.isLittleScreen() ? 40 : 50,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    avatarContainer: {
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 30 : 40,
        height: helper.isLittleScreen() ? 30 : 40,
        aspectRatio: 1,
        backgroundColor: COLORS.darkGray,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    },
    buttonProfil: {
        paddingVertical: 6,
        borderRadius: ROUNDED.md,
        width: 70,
        borderWidth: 1,
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
})

export default ModalListFriends;
