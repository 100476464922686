import React, {useState} from 'react';
import {TouchableOpacity, StyleSheet, View, Text, SafeAreaView, ScrollView, Linking, Image} from 'react-native';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";
import CustomButton from "./CustomButton";
import {ROOT_URL} from "../constants/api";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

function ModalNoAccess({ user, onClose }) {
    const imageSource = helper.isTabletteScreen()
        ? require('../../assets/general/Fond_tablette.png')
        : require('../../assets/general/Fond_Mobile.png');

    const handlePaymentRedirect = async () => {
        const url = `${ROOT_URL}/boutique`;
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            await Linking.openURL(url);
        }
    };

    return (
        <SafeAreaView style={styles.container}>
            <Image style={styles.image} source={imageSource}/>
            <ScrollView style={{flex: 1}}>
                <View style={styles.contentContainer}>
                    <Text style={styles.welcomeText}>Bienvenue, {user?.name} !</Text>
                    <Text style={styles.appDescription}>
                        Objectif officier SP, l’application mobile pour préparer son concours de Lieutenant de SPP de manière ludique !
                    </Text>

                    <Text style={styles.appFeaturesTitle}>Fonctionnalités principales :</Text>

                    <View style={styles.appFeature}>
                        <Text style={styles.appFeatureText}>
                            - Mode SOLO : Progressez en répondant correctement à des questions sur les 5 principales thématiques des concours d’officiers de sapeurs-pompiers.
                        </Text>
                    </View>

                    <View style={styles.appFeature}>
                        <Text style={styles.appFeatureText}>
                            - Défis : Confrontez-vous à vos amis ou à d'autres abonnés à travers des défis.
                        </Text>
                    </View>

                    <View style={styles.appFeature}>
                        <Text style={styles.appFeatureText}>
                            - Classement : Chaque semaine, grimpez dans le classement grâce aux points que vous accumulez.
                        </Text>
                    </View>

                    <View style={styles.appFeature}>
                        <Text style={styles.appFeatureText}>
                            - Mode PLATEAU : Jouez avec votre famille ou vos amis à notre jeu de société "Objectif officier sapeur-pompier" avec plus de 4500 questions.
                        </Text>
                    </View>

                    <Text style={styles.purchaseInfo}>
                        Cette application nécessite l’achat du "PACK" qui inclut le jeu de société livré chez vous. Pour plus d'informations, rendez vous sur notre page web.
                    </Text>

                    <Text style={styles.associationInfo}>
                        Cette application est proposée par l'association à but non lucratif "RESOCO SP - Réussir son concours d’officier sapeurs-pompiers".
                    </Text>

                    <CustomButton
                        title={'Voir notre boutique'}
                        onPress={handlePaymentRedirect}
                    />

                    <CustomButton
                        title={'Retour'}
                        backgroundColor={COLORS.gray}
                        borderColor={COLORS.lightGray}
                        shadowColor={COLORS.darkerGray}
                        textColor={COLORS.darkerGray}
                        onPress={onClose}
                    />

                    <TouchableOpacity
                        style={{marginVertical: 30, flexDirection: "row", alignItems: "center"}}
                        onPress={() =>  Linking.openURL('https://objectifofficiersp.com/contact?supprimer')}
                    >
                        <Text style={{textDecorationLine: "underline", marginRight: 5, color: COLORS.darkRed}}>Supprimer mon compte</Text>
                        <FontAwesome5 name="external-link-alt" color={COLORS.darkRed} size={helper.isLittleScreen() ? 8 : 12} style={{marginRight: 5}} />
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10,
        paddingHorizontal: 20,
        position: "relative"
    },
    image: {
        width: helper.isLittleScreen() ? '100%' : '101%',
        height: helper.isLittleScreen() ? '100%' : '110%',
        resizeMode: "cover",
        position: "absolute",
        top: -10
    },
    contentContainer: {
        width: "100%",
        maxWidth: helper.isLittleScreen() ? "90%" : "80%",
        alignItems: 'center',
        backgroundColor: COLORS.white,
        paddingHorizontal: 30,
        paddingVertical: 20,
        borderRadius: ROUNDED.lg,
        marginTop: helper.isLittleScreen() ? 10 : 30
    },
    welcomeText: {
        fontSize: SIZES['3xl'],
        fontWeight: 'bold',
        marginBottom: 20,
        color: COLORS.black
    },
    appDescription: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
    },
    appFeaturesTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    appFeature: {
        marginBottom: 10,
    },
    appFeatureText: {
        fontSize: 16,
        marginLeft: 10,
    },
    purchaseInfo: {
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        fontWeight: FONTS.semibold
    },
    associationInfo: {
        marginBottom: 40,
        fontSize: 14,
        textAlign: 'center',
        color: 'gray',
    },

});


export default ModalNoAccess;
