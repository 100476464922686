import {COLORS, ROUNDED} from "../constants/theme";
import {Audio} from "expo-av";
import {Dimensions, View} from 'react-native';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const displayError = (code, message = "") => {
    if(message.length) {
        alert(`Erreur (#${code}) : \n${message}`)
    }
    else {
        alert(`Une erreur (#${code}) est survenue. \nVeuillez réessayer plus tard.`)
    }
}

export const getGlobalBoolean = async (key) => {
    try {
        const value = await AsyncStorage.getItem(key);

        if(value === null){
            await AsyncStorage.setItem(key, '1');
            return true;
        }

        return value === '1';

    } catch (error) {
        this.displayError(4001)
        return false;
    }
}

export const setGlobalBooleanValue = async (key, value) => {
    try {
        await AsyncStorage.setItem(key, value ? '1' : '0');
    } catch (error) {

    }
}

export const isLittleScreen = () => {
    const window = Dimensions.get('window');

    return window.height < 750
}

export const isTabletteScreen = () => {
    const { width, height } = Dimensions.get('window');
    const pixelDensity = Dimensions.get('window').scale;

    // Check based on screen dimensions and pixel density
    if (width >= 600 && pixelDensity < 3) {
        return true; // Consider the device as a tablet
    }
    return false; // Consider the device as a phone
}

export function truncateString(str, num) {
    return str.length > num ? str.slice(0, num) + "..." : str;
}

export function getRankById(rank_id){
    switch (rank_id){
        case 1:
            return require('../../assets/ranks/bronze.png')
        case 2:
            return require('../../assets/ranks/argent.png')
        case 3:
            return require('../../assets/ranks/or.png')
        case 4:
            return require('../../assets/ranks/platine.png')
        case 5:
            return require('../../assets/ranks/diamant.png')
        case 6:
            return require('../../assets/ranks/maitre.png')
        case 7:
            return require('../../assets/ranks/challenger.png')
    }
    return null;
}


export function getColorByCategorySolo(categoryId, canOpen = 1) {
    let backgroundColor = COLORS.gray;
    let darkColor = COLORS.darkGray;

    switch (categoryId) {
        case 1:
            backgroundColor = COLORS.blue;
            darkColor = COLORS.darkBlue;
            break;
        case 2:
            backgroundColor = COLORS.green;
            darkColor = COLORS.darkGreen;
            break;
        case 3:
            backgroundColor = COLORS.orange;
            darkColor = COLORS.darkOrange;
            break;
        case 4:
            backgroundColor = COLORS.purple;
            darkColor = COLORS.darkPurple;
            break;
        case 5:
            backgroundColor = COLORS.red;
            darkColor = COLORS.darkRed;
            break;
        case null:
            backgroundColor = COLORS.black1;
            darkColor = COLORS.white;
            break;
        default:
            break;
    }

    if(canOpen !== 1){
        backgroundColor = COLORS.darkGray;
        darkColor = COLORS.darkerGray;
    }

    return { backgroundColor, darkColor };
}

export function getColorByCategory(categoryId) {
    let backgroundColor = COLORS.gray;
    let darkColor = COLORS.darkGray;

    switch (categoryId) {
        case 1:
            backgroundColor = COLORS.contrat.blue;
            darkColor = COLORS.contrat.darkBlue;
            break;
        case 2:
            backgroundColor = COLORS.contrat.green;
            darkColor = COLORS.contrat.darkGreen;
            break;
        case 3:
            backgroundColor = COLORS.contrat.orange;
            darkColor = COLORS.contrat.darkOrange;
            break;
        case 4:
            backgroundColor = COLORS.contrat.purple;
            darkColor = COLORS.contrat.darkPurple;
            break;
        case 5:
            backgroundColor = COLORS.contrat.red;
            darkColor = COLORS.contrat.darkRed;
            break;
        default:
            break;
    }

    return { backgroundColor, darkColor };
}

export function getImageByCategory(categoryId) {
    let image = null;

    switch (categoryId) {
        case 1:
            image = require('../../assets/cartes/dos_carte_Culture_Administrative.png');
            break;
        case 2:
            image = require('../../assets/cartes/dos_carte_Preventions.png');
            break;
        case 3:
            image = require('../../assets/cartes/dos_carte_Securite_Civile.png');
            break;
        case 4:
            image = require('../../assets/cartes/dos_carte_Risque_Naturel.png');
            break;
        case 5:
            image = require('../../assets/cartes/dos_carte_Doctrine_operationnelle.png');
            break;
        default:
            break;
    }

    return image;
}

export function getImageBandeauByCategory(categoryId) {
    let image = null;

    switch (categoryId) {
        case 1:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Culture_Administrative.png');
            break;
        case 2:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Preventions.png');
            break;
        case 3:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Securite_Civile.png');
            break;
        case 4:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Risque_Naturel.png');
            break;
        case 5:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Doctrine_operationnelle.png');
            break;
        default:
            image = require('../../assets/bandeaux/bandeau_dos_carte_Action.png');
            break;
    }

    return image;
}


export const removeHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '').replace(/[\r\n]+/g, '');
};

export const getAvatars = (index) => {
    return {
        0: require('../../assets/avatars/Avatar_1_Jeune_Garcon.png'),
        1: require('../../assets/avatars/Avatar_2_Pompier_action.png'),
        2: require('../../assets/avatars/Avatar_3_Pompier_femme.png'),
        3: require('../../assets/avatars/Avatar_4_Jeune_Fille.png'),
        4: require('../../assets/avatars/Avatar_5_Pompier_homme.png'),
        5: require('../../assets/avatars/Avatar_6_Femme.png'),
    }
}


export const playAudio = async (audio, options = {}) => {
    try {
        const { sound } = await Audio.Sound.createAsync(audio);

        if (options.volume) {
            // Lower the volume
            await sound.setVolumeAsync(options.volume); // Set the volume to 50% (0.0 to 1.0)
        }

        if(options.loop){
            await sound.setIsLoopingAsync(true); // Set the sound to loop
        }

        await sound.playAsync();


        // Your sound is playing!

        // Don't forget to unload the sound from memory
        // when you are done using the Sound object
        // await sound.unloadAsync();

        return sound;
    } catch (error) {
        // console.log('Failed to play the sound', error);
    }
}

export const beautifyDateTime = (datetime) => {
    const now = new Date();
    const date = new Date(datetime);

    const isToday = now.toDateString() === date.toDateString();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    const isYesterday = yesterday.toDateString() === date.toDateString();

    if (isToday) {
        return date.toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit'});
    } else if (isYesterday) {
        return 'Hier';
    } else if (now.getFullYear() === date.getFullYear()) {
        return date.toLocaleDateString('fr-FR', {day: '2-digit', month: '2-digit'});
    } else {
        return date.toLocaleDateString('fr-FR', {day: '2-digit', month: '2-digit', year: 'numeric'});
    }
}

export const renderLevelContent = (isValid, canOpen, color) => {
    const styles = {
        level: {
            justifyContent: "center",
            alignItems: "center",
            borderWidth: 3,
            borderRadius: ROUNDED.full,
            width: isLittleScreen() ? 25 : 30,
            aspectRatio: 1
        }
    }
    return (
        <View style={[styles.level, {borderColor: color}]}>
            {
                isValid === null && canOpen ? (
                    <FontAwesome name="star" size={isLittleScreen() ? 12 : 15} color={color} />
                ) : isValid === null && !canOpen ? (
                    <FontAwesome name="lock" size={isLittleScreen() ? 12 : 15} color={COLORS.darkerGray} />
                ) : isValid === 1 ? (
                    <FontAwesome name="check" size={isLittleScreen() ? 12 : 15} color={color} />
                ) : (
                    <FontAwesome name="close" size={isLittleScreen() ? 12 : 15} color={color}  />
                )
            }
        </View>
    );
}

