import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
const ClassementService = {
    classement: async (type, page = 0) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/classement/${type}${page > 0 ? '?page=' + page : ''}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error checking login:', error);
        }
    }
}

export default ClassementService;
