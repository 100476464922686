import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthService from '../services/AuthService';
import * as helper from "./helpers";
import ErrorNotification from "../components/ErrorNotification";
import {Modal} from "react-native";
import ModalNoAccess from "../components/ModalNoAccess";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [noAccessModal, setNoAccessModal] = useState(false)
    const [userRegistred, setUserRegistred] = useState(null)

    const checkLoginStatus = async () => {
        setIsLoading(true);
        const token = await AsyncStorage.getItem('token');

        if (token) {
            try {
                const checkLogin = await AuthService.check();
                setIsLoggedIn(checkLogin && checkLogin.auth);

                if(checkLogin){
                    if(checkLogin.auth){

                    }
                    else {
                        if(checkLogin.no_access_page){
                            setNoAccessModal(true)
                            setUserRegistred(checkLogin.user)
                        }
                        await AsyncStorage.removeItem('token');
                    }
                }
                else{
                    await AsyncStorage.removeItem('token');
                }
            } catch (error) {
                setError({code: "0012"})
            }
        } else {
            await AsyncStorage.removeItem('token');
            setIsLoggedIn(false);
        }
        setIsLoading(false);
    };

    // Call checkLoginStatus when the component is mounted
    useEffect(() => {
        checkLoginStatus();
    }, []);



    return (
        <AuthContext.Provider value={{ isLoggedIn, isLoading, checkLoginStatus }}>
            {children}

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />

            <Modal visible={noAccessModal}>
                <ModalNoAccess user={userRegistred} onClose={() => setNoAccessModal(false)} />
            </Modal>
        </AuthContext.Provider>
    );
};
