import React, {useCallback, useContext, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    Image,
    TouchableOpacity,
    Modal,
    RefreshControl, ImageBackground, Linking,
} from 'react-native';
import {COLORS, FONTS, ROUNDED, SIZES} from "../../constants/theme";
import * as helper from "../../utils/helpers";
import CustomButton from "../../components/CustomButton";
import {useFocusEffect} from "@react-navigation/native";
import DefiService from "../../services/DefiService";
import {ROOT_URL} from "../../constants/api";
import ResultDefiModal from "../../components/ResultDefiModal";
import DefierAmiModal from "../../components/DefierAmiModal";
import {DefisStatus, DefisTypes, getTypeName} from "../../constants/defis";
import ModalComponent from "../../components/ModalComponent";
import ErrorNotification from "../../components/ErrorNotification";
import {ActivityIndicator} from "react-native-paper";
import UserService from "../../services/UserService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {AuthContext} from "../../utils/AuthContext";

function Defis({ navigation }) {
    const [error, setError] = useState(null);
    const { checkLoginStatus } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const imageSource = helper.isTabletteScreen()
        ? require('../../../assets/general/Fond_defis_tablette.png')
        : require('../../../assets/general/Fond_Defis.png');
    const [refreshing, setRefreshing] = useState(false);
    const [recentDefis, setRecentDefis] = useState([])
    const [currentDefis, setCurrentDefis] = useState([])
    const [openResultModal, setOpenResultModal] = useState(false)
    const [defiResultSelected, setDefiResultSelected] = useState(false)
    const [openDefiAmitModal, setOpenDefiAmiModal] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [openRankedModal, setOpenRankedModal] = useState(false)
    const [selectedReprendreDefi, setSelectedReprendreDefi] = useState(null)
    const [acceptReprise, setAcceptReprise] = useState(null)
    const [demandeRanked, setDemandeRanked] = useState(null)
    const [openDemandeModal, setOpenDemandeModal] = useState(false)
    const [mobileAccess, setMobileAccess] = useState(false)

    useFocusEffect(
        useCallback(() => {
            checkMobileAccess();
            refreshPage();
        }, [])
    );


    const checkMobileAccess = async () => {
        const checkAccess = await UserService.checkMobileAccess();
        if (checkAccess) {
            setMobileAccess(checkAccess.has_access);
        }
        setLoadingInitial(false); // Mettre fin au chargement initial
    };

    const handleLogout = async () => {
        try {
            // Clear access token from AsyncStorage
            await AsyncStorage.removeItem('token');
            checkLoginStatus()
        } catch (error) {
            setError({code: 5004})
        }
    };
    const resetAllModal = () => {
        setOpenResultModal(false);
        setDefiResultSelected(false);
        setOpenDefiAmiModal(false);
        setOpenConfirmModal(false);
        setOpenRankedModal(false);
        setOpenDemandeModal(false);
    }

    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        refreshPage();
        setRefreshing(false);
    }, []);

    const refreshPage = () => {
        setIsLoading(true);
        resetAllModal();

        fetchDemandeDefis();
        fetchRecentDefis();
        fetchCurrentDefis();


        setIsLoading(false);
    }

    const handleOpenResultModal = (defi) => {
        setDefiResultSelected(defi)
        setOpenResultModal(true);
    }

    const handleCloseModal = () => {
        setDefiResultSelected(null)
        setOpenResultModal(false);
    }

    const handleOpenDefiAmitModal = () => {
        setOpenDefiAmiModal(true);
    }

    const handleCloseDefiAmiModal = () => {
        setOpenDefiAmiModal(false);
    }

    const handleOpenConfirmModal = (defi_id, accept) => {
        setAcceptReprise(accept)
        setSelectedReprendreDefi(defi_id)
        setOpenConfirmModal(true);
    }

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    }

    const handleOpenRankedModal = () => {
        setOpenRankedModal(true);
    }


    const handleCloseRankedModal = () => {
        setOpenRankedModal(false);
    }


    const fetchDemandeDefis = async () => {
        try {
            const demandeDefisData = await DefiService.demandeDefi()
            setDemandeRanked(demandeDefisData)

            if(demandeDefisData && Object.keys(demandeDefisData).length > 0) {
                setOpenDemandeModal(true)
            }
            else{
                setOpenDemandeModal(false)
            }
        } catch (error) {
            setError({code: 3000})
        }
    }

    const fetchRecentDefis = async () => {
        try {
            const recentDefisData = await DefiService.getRecentDefi()
            setRecentDefis(recentDefisData)
        } catch (error) {
            setError({code: 3001})
        }
    }

    const fetchCurrentDefis = async () => {
        try {
            const currentDefisData = await DefiService.getCurrentDefi()
            setCurrentDefis(currentDefisData)
        } catch (error) {
            setError({code: 3002})
        }
    }


    const startDefiRanked = async () => {
        try {
            const getDefiData = await DefiService.createDefi(DefisTypes.DEFIS_RANKED)

            if(getDefiData && getDefiData.error){
                setError({code: 3008, message: getDefiData.error})
            }

            if(getDefiData && getDefiData.canStart){
                navigation.navigate('PlayDéfis', { defi: getDefiData });
            }


            resetAllModal();

        } catch (error) {
            setError({code: 3003})
        }
    };

    const startDefiEntrainement = async () => {
        try {
            const getDefiData = await DefiService.createDefi(DefisTypes.DEFIS_TRAIN)

            if(getDefiData && getDefiData.error){
                setError({code: 3009, message: getDefiData.error})
            }

            if(getDefiData && getDefiData.canStart){
                navigation.navigate('PlayDéfis', { defi: getDefiData });
            }

            resetAllModal();

        } catch (error) {
            setError({code: 3004})
        }
    };


    const reprendreDefi = async (defi_id, accept = null) =>{
        try {
            const getDefiData = await DefiService.reprendreDefi(defi_id, accept)

            if(getDefiData){
                if(getDefiData.canStart) {
                    navigation.navigate('PlayDéfis', { defi: getDefiData });
                }
                else{
                    alert(getDefiData.message)
                    refreshPage()
                    setOpenConfirmModal(false);
                    setSelectedReprendreDefi(null)
                    setAcceptReprise(null)
                }
            }

            resetAllModal();

        } catch (error) {
            setError({code: 3005})
        }
    }

    const reponseDefiRanked = async (accept) =>{
        try {
            const getDefiData = await DefiService.reponseDemandeDefi(demandeRanked.id, accept)

            if(getDefiData){
                if(getDefiData.error){
                    setError({code: 3010, message: getDefiData.error})
                    setOpenDemandeModal(false);
                    demandeRanked(null)
                }
                else {
                    if(getDefiData.canStart){
                        navigation.navigate('PlayDéfis', { defi: getDefiData });
                    }
                    else{
                        setError({code: '', message: 'Vous ne pouvez plus répondre à ce défi'})
                        setOpenDemandeModal(false);
                        demandeRanked(null)
                    }
                }
            }
            else{
                setError({code: 3006})
            }

            resetAllModal();

        } catch (error) {
            setError({code: 3007})
        }
    }

    const renderCurrentDefi = (item, index) => {
        return (
            <View style={styles.itemRecentDefi}>
                <View style={[styles.containerAvatar]}>
                    { item.is_bot === 1 ? (
                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={require('../../../assets/general/Robot.png')}/>
                    ) : (
                        item.opponent_avatar && (
                            <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${item.opponent_avatar.replace('.svg', '.png')}`}}/>
                        )
                    )}
                </View>
                <View style={{flex: 1}}>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 4, flex: 1}}>
                        <Text style={styles.nameRecentDefi}>{item.is_bot === 1 ? 'Bot' : item.opponent_name ? item.opponent_name : 'Recherche un adversaire' } #{item.id}</Text>
                        <Text style={{color: COLORS.darkerGray, fontSize: SIZES.xs}}>{item.user_start_time ? helper.beautifyDateTime(item.created_at) : '-'}</Text>
                    </View>

                    <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", flex: 1}}>
                        <View>
                            <Text style={{fontSize: SIZES.xs, color: COLORS.darkerGray}}>{ getTypeName(item.type_id) }</Text>
                            <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.blue}]}>En attente</Text>
                        </View>


                        <View style={{flexDirection: "row", justifyContent: "flex-end", alignItems: "center", flex: 1}}>
                            { item.peut_reprendre === 1 && item.has_to_accept === 0 && (
                                <TouchableOpacity style={[styles.buttonRecentDefi, styles.buttonRecentDefiDefier]} onPress={ () => reprendreDefi(item.id) }>
                                    <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black}}>Reprendre</Text>
                                </TouchableOpacity>
                            )}
                            { item.peut_reprendre === 1 && item.has_to_accept === 1 && (
                                <View style={{flexDirection: "row", justifyContent: "flex-end", alignItems: "center", flex: 1}}>
                                    <TouchableOpacity style={[styles.buttonRecentDefi, styles.buttonRefuserDefier]} onPress={ () => handleOpenConfirmModal(item.id, 0) }>
                                        <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.white}}>Refuser</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[styles.buttonRecentDefi, styles.buttonAccepterDefier]} onPress={ () => handleOpenConfirmModal(item.id, 1) }>
                                        <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.white}}>Accepter</Text>
                                    </TouchableOpacity>
                                </View>
                            )}
                        </View>

                    </View>
                </View>
            </View>
        )
    }

    const renderRecenDefi = (item, index) => {
        const isCreator = item.current_user_id === item.creator_user_id;
        const avatar = isCreator ? item.opponent_avatar : item.user_avatar;
        const name = isCreator ? item.opponent_name : item.user_name;


        return (
            <View style={styles.itemRecentDefi}>
                <View style={[styles.containerAvatar]}>
                    { item.is_bot === 1 ? (
                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={require('../../../assets/general/Robot.png')}/>
                    ) : (
                        avatar && (
                            <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${avatar.replace('.svg', '.png')}`}}/>
                        )
                    )}
                </View>
                <View style={{flex: 1}}>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginBottom: 4, flex: 1}}>
                        <Text style={styles.nameRecentDefi}>{item.is_bot === 1 ? 'Bot' : name}</Text>
                        <Text style={{color: COLORS.darkerGray, fontSize: SIZES.xs}}>{helper.beautifyDateTime(item.created_at)}</Text>
                    </View>

                    <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", flex: 1}}>

                        { item.type_id === DefisTypes.DEFIS_RANKED && (
                            <View>
                                <Text style={{fontSize: SIZES.xs, color: COLORS.darkerGray}}>{ getTypeName(item.type_id) }</Text>
                                { item.current_user_id === item.winner ? (
                                    <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.green}]}>+{item.points} pts</Text>
                                ) : (
                                    <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.red}]}>{item.points_perdant} pts</Text>
                                )}
                            </View>
                        )}

                        { item.type_id !== DefisTypes.DEFIS_RANKED && item.statut_id === DefisStatus.STATUT_FINISHED && (
                            <View>
                                <Text style={{fontSize: SIZES.xs, color: COLORS.darkerGray}}>{ getTypeName(item.type_id) }</Text>
                                { item.current_user_id === item.winner ? (
                                    <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.green}]}>Gagné</Text>
                                ) : (
                                    <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.red}]}>Perdu</Text>
                                )}
                            </View>
                        )}

                        { item.type_id !== DefisTypes.DEFIS_RANKED && item.statut_id === DefisStatus.STATUT_CANCELED && (
                            <View>
                                <Text style={{fontSize: SIZES.xs, color: COLORS.darkerGray}}>{ getTypeName(item.type_id) }</Text>
                                <Text style={[styles.pointsRecentDefi, {backgroundColor: COLORS.darkGray}]}>Annulé</Text>
                            </View>
                        )}

                        { item.statut_id == DefisStatus.STATUT_FINISHED && (
                            <View style={{flexDirection: "row", justifyContent: "flex-end", alignItems: "center", flex: 1}}>
                                <TouchableOpacity onPress={() => handleOpenResultModal(item)} style={[styles.buttonRecentDefi, styles.buttonRecentDefiVoir]}>
                                    <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black1}}>Voir</Text>
                                </TouchableOpacity>
                                {/*{ item.is_bot !== 1 && (*/}
                                {/*    <TouchableOpacity style={[styles.buttonRecentDefi, styles.buttonRecentDefiDefier]}>*/}
                                {/*        <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black}}>Défier</Text>*/}
                                {/*    </TouchableOpacity>*/}
                                {/*)}*/}
                            </View>
                        )}

                    </View>
                </View>
            </View>
        )
    }

    return (
        <SafeAreaView style={{ flex: 1 }}>
            { loadingInitial ? (
                <ActivityIndicator animating={true} size="large" color={COLORS.primary} style={styles.loadingIndicator} />
            ) : mobileAccess ? (
                <ImageBackground style={{ flex: 1 }} source={imageSource}>
                    <ScrollView refreshControl={
                        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                    }>
                        <View style={styles.container}>
                            <View style={{flex:1, width: '100%', maxWidth: 400}}>
                                <Text style={[styles.title, {color: COLORS.white}]}>Lancer un défi</Text>

                                <CustomButton
                                    title={'Défier un ami'}
                                    backgroundColor={COLORS.white}
                                    textColor={COLORS.darkerGray}
                                    shadowColor={COLORS.darkGray}
                                    borderColor={COLORS.gray}
                                    onPress={handleOpenDefiAmitModal}
                                />

                                { !currentDefis.some(defi => defi.type_id === DefisTypes.DEFIS_RANKED) && (
                                    <CustomButton
                                        title={'Défier un abonné'}
                                        onPress={handleOpenRankedModal}
                                        backgroundColor={COLORS.white}
                                        textColor={COLORS.darkerGray}
                                        shadowColor={COLORS.darkGray}
                                        borderColor={COLORS.gray}
                                    />
                                )}

                                <CustomButton
                                    title={'Entrainement'}
                                    onPress={startDefiEntrainement}
                                    backgroundColor={COLORS.white}
                                    textColor={COLORS.darkerGray}
                                    shadowColor={COLORS.darkGray}
                                    borderColor={COLORS.gray}
                                />

                                { currentDefis.length > 0 && (
                                    <View style={styles.containerRecentDefis}>
                                        <Text style={styles.titleRecentDefis}>Défis en cours :</Text>
                                        {currentDefis.map((defi, index) => (
                                            <View key={index}>
                                                { (renderCurrentDefi(defi, index)) }
                                                {index < currentDefis.length - 1 && <View style={styles.separator} />}
                                            </View>

                                        ))}
                                    </View>
                                )}

                                { recentDefis && recentDefis.length > 0 && (
                                    <View style={styles.containerRecentDefis}>
                                        <Text style={styles.titleRecentDefis}>Récent défis :</Text>

                                        {recentDefis.map((defi, index) => (
                                            <View key={index}>
                                                { (renderRecenDefi(defi, index)) }
                                                {index < recentDefis.length - 1 && <View style={styles.separator} />}
                                            </View>

                                        ))}
                                    </View>
                                )}
                            </View>
                        </View>

                    </ScrollView>


                    <Modal visible={openResultModal}>
                        <ResultDefiModal defi={defiResultSelected} handleCloseModal={handleCloseModal} />
                    </Modal>

                    <Modal visible={openDefiAmitModal}>
                        <DefierAmiModal handleCloseModal={handleCloseDefiAmiModal} />
                    </Modal>

                    <ModalComponent
                        visible={openConfirmModal}
                        onClose={handleCloseConfirmModal}
                        title={acceptReprise === 1 ? "Accepter le défi ?" : "Refuser le défi ?"}
                        titleValid={acceptReprise === 1 ? "Démarrer" : "Refuser"}
                        onValid={() => reprendreDefi(selectedReprendreDefi, acceptReprise)}
                    >
                        <View style={{justifyContent: "space-between", marginTop: 20, paddingHorizontal: 15}}>
                            <Text style={[styles.titleModal]}>
                                {acceptReprise === 1 ? "Êtes-vous sûr de vouloir lancer le défi ?" : "Êtes-vous sûr de vouloir refuser le défi ?"}
                            </Text>

                        </View>
                    </ModalComponent>

                    <ModalComponent
                        visible={openRankedModal}
                        onClose={handleCloseRankedModal}
                        title={"Défier un abonné ?"}
                        titleValid={"Commencer"}
                        onValid={startDefiRanked}
                    >
                        <View style={{justifyContent: "space-between", marginTop: 20, paddingHorizontal: 15}}>
                            <Text style={[styles.titleModal]}>
                                Vous allez lancer un défi contre un abonné, confirmez-vous ce choix ?
                            </Text>
                            <Text style={[styles.descriptionModal]}>
                                5 points sont en jeu dans ce mode.
                            </Text>
                        </View>
                    </ModalComponent>

                    <ModalComponent
                        visible={openDemandeModal}
                        showCloseButton={false}
                    >
                        <View style={{justifyContent: "space-between", marginTop: 20, paddingHorizontal: 15}}>
                            <View>
                                <Text style={[styles.titleModal, {color: COLORS.darkerGray, marginBottom: 0}]}>
                                    Vous avez reçu une demande de défi classé par
                                </Text>
                                <Text style={[styles.title, {color: COLORS.black, textAlign: 'center', marginTop: 2, marginBottom: 50}]}>
                                    {demandeRanked?.user_name}
                                </Text>
                            </View>

                            <View style={{flexDirection: "row", justifyContent: "space-between"}}>

                                <CustomButton
                                    title={'Refuser'}
                                    width={"48%"}
                                    onPress={() => reponseDefiRanked(0)}
                                    backgroundColor={COLORS.red}
                                    textColor={COLORS.white}
                                    shadowColor={COLORS.darkRed}
                                    borderColor={COLORS.lightRed}
                                />

                                <CustomButton
                                    title={'Accepter'}
                                    width={"48%"}
                                    onPress={() => reponseDefiRanked(1)}
                                    backgroundColor={COLORS.green}
                                    textColor={COLORS.white}
                                    shadowColor={COLORS.darkGreen}
                                    borderColor={COLORS.lightGreen}
                                />
                            </View>

                        </View>
                    </ModalComponent>

                    <ErrorNotification
                        isVisible={error !== null}
                        code={error?.code}
                        message={error?.message}
                        onClose={() => setError(null)}
                    />
                </ImageBackground>
            ) : (
                <ImageBackground style={{ flex: 1 }} source={imageSource}>
                    <View style={{justifyContent: 'center', alignItems: 'center', padding: 30}}>
                        <View style={{width: '500px', height: '300px',  padding: 30, borderRadius: ROUNDED.lg, backgroundColor: 'white', justifyContent: 'space-between'}}>
                            <View>
                                <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.bold, marginBottom: '15px'}}>Abonnement application requis</Text>
                                <Text>Bonjour <Text style={{fontWeight: FONTS.semibold}}>{'test'}</Text>, Vous devez être abonné à l'application pour accéder à cette fonctionnalité. Veuillez vous abonner pour profiter pleinement de cette expérience.</Text>
                            </View>

                            <View>
                                <CustomButton title="S'abonner" onPress={() =>
                                    Linking.openURL('https://objectifofficiersp.com/boutique')}
                                />
                                <CustomButton
                                    title="Se déconnecter"
                                    onPress={ handleLogout }
                                    backgroundColor={COLORS.red}
                                    borderColor={COLORS.lightRed}
                                    shadowColor={COLORS.darkRed}
                                    textColor={COLORS.white}
                                />
                            </View>

                        </View>

                    </View>
                </ImageBackground>
            )}
        </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        paddingHorizontal: 30
    },
    title: {
        textAlign: 'center',
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 15 : 20,
    },
    titleModal: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.lg : SIZES.xl,
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 15 : 20,
        textAlign: 'center'
    },
    descriptionModal: {
        color: COLORS.darkerGray,
        fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base,
        textAlign: 'center'
    },
    containerRecentDefis: {
        marginTop: helper.isLittleScreen() ? 20 : 30,
        backgroundColor: COLORS.white,
        padding: helper.isLittleScreen() ? 7 : 15,
        width: '100%',
        borderRadius: ROUNDED.sm
    },
    titleRecentDefis: {
        fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base,
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 15 : 20,
    },
    itemRecentDefi: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
    },
    containerAvatar: {
        width: 50,
        height: 50,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: 'hidden',
        marginRight: 10,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: COLORS.gray
    },
    nameRecentDefi: {
        fontSize: SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    pointsRecentDefi: {
        paddingHorizontal: 7,
        paddingVertical: 2,
        fontSize: SIZES.xs,
        borderRadius: ROUNDED.full,
        color: COLORS.white,
        fontWeight: FONTS.semibold,
        textAlign: "center"
    },
    buttonRecentDefi: {
        paddingVertical: 6,
        borderRadius: ROUNDED.md,
        width: 70,
        borderWidth: 1,
    },
    buttonRecentDefiVoir: {
        backgroundColor: COLORS.white,
        borderColor: COLORS.darkGray,
    },
    buttonRecentDefiDefier: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
        marginLeft: 5
    },
    buttonRefuserDefier: {
        backgroundColor: COLORS.red,
        borderColor: COLORS.red,
    },
    buttonAccepterDefier: {
        backgroundColor: COLORS.green,
        borderColor: COLORS.green,
        marginLeft: 5
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.gray,
        marginVertical: helper.isLittleScreen() ? 15 : 20,
    },
});

export default Defis;
