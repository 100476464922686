import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

const LevelService = {
    getLevels: async (page = 0) => {
        const token = await AsyncStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/levels/${page > 0 ? '?page=' + page : ''}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.log(error);
            return error;
        }
    },
}

export default LevelService;
