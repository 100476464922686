import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Animated } from 'react-native';
import { COLORS, SIZES, ROUNDED, FONTS } from "../constants/theme";
import * as helper from "../utils/helpers";

const Dice = ({ onRollDice, currentJoueur, resetDice, disabled  }) => {
    const [diceNumber, setDiceNumber] = useState(null);
    const [animation, setAnimation] = useState(new Animated.Value(0));
    const [isResetting, setIsResetting] = useState(false);
    const [color, setColor] = useState(COLORS.black);


    useEffect(() => {
        if (resetDice) {
            setDiceNumber(null);
            setIsResetting(true);
        }
    }, [resetDice]);

    useEffect(() => {
        if (isResetting) {
            setIsResetting(false);
            setColor(COLORS.black)
        }
    }, [isResetting]);

    const animateDiceRoll = () => {
        Animated.timing(animation, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
        }).start(() => {
            setDiceNumber(Math.floor(Math.random() * 6) + 1);
            setColor(COLORS.red)
            animation.setValue(0);
        });
    };


    const diceRotation = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });

    const handleRollDice = () => {
        animateDiceRoll();
        onRollDice();
    };

    return (
        <View style={styles.container}>
            <Text style={[styles.textTitle, {color: disabled ? 'transparent' : COLORS.black}]}>Lancez le dé !</Text>
            <TouchableOpacity onPress={handleRollDice} disabled={disabled}>
                <Animated.View style={[styles.dice, { backgroundColor: color}, { transform: [{ rotate: diceRotation }] }]}>
                    <View style={styles.diceDotContainer}>
                        {diceNumber === 1 && <View style={styles.diceDot} />}
                        {diceNumber === 2 && (
                            <View style={styles.diceDotContainer2}>
                                <View style={styles.diceDot} />
                                <View style={styles.diceDot} />
                            </View>
                        )}
                        {diceNumber === 3 && (
                            <View style={styles.diceDotContainer3}>
                                <View style={styles.diceDot} />
                                <View style={styles.diceDot} />
                                <View style={styles.diceDot} />
                            </View>
                        )}
                        {diceNumber === 4 && (
                            <View style={styles.diceDotContainer4}>
                                <View style={styles.diceDotRow}>
                                    <View style={styles.diceDot} />
                                    <View style={styles.diceDot} />
                                </View>
                                <View style={styles.diceDotRow}>
                                    <View style={styles.diceDot} />
                                    <View style={styles.diceDot} />
                                </View>
                            </View>
                        )}
                        {diceNumber === 5 && (
                            <View style={styles.diceDotContainer5}>
                                <View style={[styles.diceDotRow]}>
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                </View>
                                <View style={styles.diceDot} />
                                <View style={styles.diceDotRow}>
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                </View>
                            </View>
                        )}
                        {diceNumber === 6 && (
                            <View style={styles.diceDotContainer6}>
                                <View style={styles.diceDotRow}>
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                </View>
                                <View style={styles.diceDotRow}>
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                </View>
                                <View style={styles.diceDotRow}>
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                    <View style={[styles.diceDot, {marginHorizontal: 8}]} />
                                </View>
                            </View>
                        )}
                    </View>
                </Animated.View>
            </TouchableOpacity>

            {diceNumber === null && (
                <Text style={[styles.diceNumberText, {color: 'transparent'}]}>Chargement</Text>
            )}
            {diceNumber !== null && (
                <Text style={styles.diceNumberText}>Le joueur {currentJoueur.pseudo} doit avancer de {diceNumber} case{diceNumber > 1 ? 's' : ''}</Text>
            )}
        </View>
    );
};

const styles = {
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 3
    },
    textTitle: {
        fontSize: helper.isLittleScreen() ? SIZES.lg : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 6 : 10,
    },
    diceNumberText: {
        marginTop: helper.isLittleScreen() ? 5 : 10,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.medium,
        color: COLORS.black,
    },
    dice: {
        width: 70,
        height: 70,
        backgroundColor: COLORS.black,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: ROUNDED["2xl"],
    },
    diceDotContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        height: 60,
    },
    diceDotContainer2: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        height: 60,
    },
    diceDotContainer3: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 60,
        height: 60,
    },
    diceDotContainer4: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
    },
    diceDotContainer5: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
    },
    diceDotContainer6: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
    },
    diceDotRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    diceDot: {
        width: 12,
        height: 12,
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.full,
        margin: 3,
    },
};

export default Dice;
