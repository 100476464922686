import React from 'react';
import { View, Text, TouchableOpacity, Modal } from 'react-native';
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {COLORS, FONTS, SIZES} from "../constants/theme";

const ErrorNotification = ({ isVisible, code, message = "", onClose }) => {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={isVisible}
        >
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ backgroundColor: 'white', width: '100%', alignItems: "center", maxWidth: 400, padding: 20, borderRadius: 10, elevation: 5 }}>
                    <Text style={{color: COLORS.darkRed, fontSize: SIZES.base, fontWeight: FONTS.bold, marginBottom: 6}}>Erreur{code ? ` (#${code})` : ''}</Text>
                    <Text style={{color: COLORS.black1}}>{message ? message : "Oops, une erreur est survenue. Veuillez réessayer plus tard."}</Text>
                    <TouchableOpacity onPress={onClose} style={{backgroundColor: COLORS.primary, width: '100%', marginTop: 20, alignItems: 'center', borderRadius: 5, paddingVertical: 11}}>
                        <Text style={{color: COLORS.black1, fontWeight: FONTS.bold, fontSize: SIZES.base}}>Réessayer</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

export default ErrorNotification;
