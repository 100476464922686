import React, {useState, useEffect, useCallback} from 'react';
import {
    TouchableOpacity,
    Text,
    View,
    ScrollView,
    StyleSheet,
    ActivityIndicator,
    SafeAreaView,
    Image, Modal,
} from 'react-native';
import CustomButton from './CustomButton';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import {
    removeHtmlTags,
    getColorByCategory,
    getColorByCategorySolo,
    getImageBandeauByCategory,
    isLittleScreen
} from '../utils/helpers';
import he from 'he';
import QuestionService from "../services/QuestionService";
import * as helper from "../utils/helpers";
import SoloService from "../services/SoloService";
import ErrorNotification from "./ErrorNotification";

function QuestionModal({ questionData, toggleSoundEffect, level, handleNextModal, onValidate, handleCloseModal }) {
    const [error, setError] = useState(null);
    const [question, setQuestion] = useState(null)
    const [selectedResponses, setSelectedResponses] = useState([]);
    const [validerClicked, setValiderClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const [isCorrectReponse, setIsCorrectReponse] = useState(null);
    const [reponse1Style, setReponse1Style] = useState({});
    const [reponse2Style, setReponse2Style] = useState({});
    const [reponse3Style, setReponse3Style] = useState({});
    const [reponse4Style, setReponse4Style] = useState({});
    const [reponse1CibleStyle, setReponse1CibleStyle] = useState({});
    const [reponse2CibleStyle, setReponse2CibleStyle] = useState({});
    const [reponse3CibleStyle, setReponse3CibleStyle] = useState({});
    const [reponse4CibleStyle, setReponse4CibleStyle] = useState({});
    const [textValidation, setTextValidation] = useState('')
    const [styleValidation, setStyleValidation] = useState({})
    const [bandeauImage, setBandeauImage] = useState(null)
    const [modalBonusVisible, setModalBonusVisible] = useState(false);
    const [bonusSelected, setBonusSelected] = useState(null);
    const [bonus1, setBonus1] = useState(0);
    const [bonus2, setBonus2] = useState(0);
    const [loadingBonus, setLoadingBonus] = useState(false)
    const [bonusUsed, setBonusUsed] = useState(false)
    const [questionLoading, setQuestionLoading] = useState(false)


    useEffect(() => {
        if (questionData) {
            setQuestion(questionData);
            setQuestionLoading(true);
        }
        else{
            setQuestionLoading(true);
            fetchQuestionData()
        }
    }, [questionData]);

    useEffect( () => {
        fetchUserData();
    }, [])


    useEffect( () => {
        if(question){
            setQuestionLoading(false);
        }
    }, [question])

    useEffect(() => {
        if(question !== null) {
            setBandeauImage(getImageBandeauByCategory(level?.category_id))

            if(level.reponse && level.reponse.length > 0){
                const selectedCurrentResponses = level.reponse.split(',')

                const correctResponses = [
                    question.bonne_reponse1,
                    question.bonne_reponse2,
                    question.bonne_reponse3,
                    question.bonne_reponse4,
                ];

                setValiderClicked(true);

                let isCorrect = true;
                for(let i = 0; i < correctResponses.length; i++){
                    if(correctResponses[i] != selectedCurrentResponses[i]){
                        isCorrect = false
                    }
                }

                const reponses = handleReponseExist(selectedCurrentResponses)
                setSelectedResponses(reponses);

                setStyleValidation(isCorrect ? {color: COLORS.green} : {color: COLORS.red});
                setTextValidation(isCorrect ? "Bonne réponse !" : "Mauvaise réponse")
            }
        }
    }, [question]);


    const fetchQuestionData = async () => {
        if(level && level.id) {
            try {
                const questionReData = await QuestionService.getQuestionFromLevel(level.id);
                setQuestion(questionReData)
                setQuestionLoading(false);

            } catch (error) {
                setError({code: 1017})
                // console.log(error)
            }
        }
    }

    const fetchUserData = async () => {
        try {
            const userData = await SoloService.getUser();

            setBonus1(userData.bonus1)
            setBonus2(userData.bonus2)


        } catch (error) {
            setError({code: 1015})
            // console.log(error)
        }
    }

    const handleValidModal = async () => {
        const correctResponses = [
            question.bonne_reponse1,
            question.bonne_reponse2,
            question.bonne_reponse3,
            question.bonne_reponse4,
        ];
        setValiderClicked(true);
        let selectedCurrentResponses = [0, 0, 0, 0];

        for(let i = 0; i < selectedCurrentResponses.length; i++){
            selectedCurrentResponses[i] = selectedResponses.includes(i + 1) ? 1 : 0;
        }

        let isCorrect = true;
        for(let i = 0; i < correctResponses.length; i++){
            if(correctResponses[i] != selectedCurrentResponses[i]){
                isCorrect = false
            }
        }
        const reponses = handleReponseExist(selectedCurrentResponses)


        if(isCorrect){
            if(toggleSoundEffect) {
                await helper.playAudio(require('../../assets/audios/win.mp3'))
            }
            setStyleValidation({color: COLORS.green})
            setTextValidation("Bonne réponse !")
        }
        else{
            if(toggleSoundEffect) {
                await helper.playAudio(require('../../assets/audios/loose.mp3'))
            }
            setStyleValidation({color: COLORS.red})
            setTextValidation("Mauvaise réponse")
        }

        // Set reponse to api
        const setReponseResult = await QuestionService.setResultQuestion(level.id, selectedCurrentResponses.join(','));

        setIsCorrectReponse(isCorrect)

        setSelectedResponses(reponses);
        setIsLoading(false);
        setCanGoNext(true)
    }

    const toggleAnswerSelection = (answerIndex) => {
        if(!validerClicked){
            if (selectedResponses.includes(answerIndex)) {
                setSelectedResponses(selectedResponses.filter((index) => index !== answerIndex));
            } else {
                setSelectedResponses([...selectedResponses, answerIndex]);
            }
        }
    };

    const colors = getColorByCategorySolo(level?.category_id)


    const nextModal = () => {
        handleNextModal(level.category_id === null, isCorrectReponse)
    }
    const closeModal = () => {
        handleCloseModal();
    }


    const setReponseStyle = (responseIndex, style) => {
        switch (responseIndex) {
            case 1:
                setReponse1Style(style);
                break;
            case 2:
                setReponse2Style(style);
                break;
            case 3:
                setReponse3Style(style);
                break;
            case 4:
                setReponse4Style(style);
                break;
            default:
                break;
        }
    };

    const handleReponseExist = (reponsesExist) => {
        const reponses = [];

        reponsesExist.forEach((reponse, index) => {
            if (reponse == '1') {
                reponses.push(index + 1);

                if (question[`bonne_reponse${index + 1}`] == '1') {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.green, borderWidth: 3, borderColor: COLORS.black });
                } else {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.red, borderWidth: 3, borderColor: COLORS.black });
                }
            } else {
                if (question[`bonne_reponse${index + 1}`] == '1') {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.green });
                } else {
                    setReponseStyle(index + 1, {});
                }
            }
        });

        return reponses;
    };

    const handlePressBonus = (bonus) => {
        setModalBonusVisible(true)
        setBonusSelected(bonus)
    }
    const handleCloseBonus = () => {
        setModalBonusVisible(false)
        setBonusSelected(null)
    }
    const handleUseBonus = async () => {
        setLoadingBonus(true)
        if(bonusSelected === 1){
            setQuestion(null)
            setSelectedResponses([]);
        }
        const setReponseResult = await SoloService.setBonusUsed(bonusSelected, level.id);

        if(setReponseResult.success){
            if(bonusSelected === 1){
                setBonus1(bonus1 - 1)
                setQuestion(setReponseResult.newQuestion)
            }
            else if(bonusSelected === 2){
                setBonus2(bonus2 - 1)
                const correctResponses = [
                    question.bonne_reponse1,
                    question.bonne_reponse2,
                    question.bonne_reponse3,
                    question.bonne_reponse4,
                ];

                const allValidValue = Object.fromEntries(
                    Object.entries(correctResponses).flatMap(([key, value]) => {
                        if (value === 1) {
                            return [[key, value]];
                        } else {
                            return [];
                        }
                    })
                );

                const keysArray = Object.keys(allValidValue);
                const randomKey = parseInt(keysArray[Math.floor(Math.random() * keysArray.length)]);

                const style = {borderWidth: 3, borderColor: COLORS.darkGreen};

                switch (randomKey + 1) {
                    case 1:
                        setReponse1CibleStyle(style);
                        break;
                    case 2:
                        setReponse2CibleStyle(style);
                        break;
                    case 3:
                        setReponse3CibleStyle(style);
                        break;
                    case 4:
                        setReponse4CibleStyle(style);
                        break;
                    default:
                        break;
                }

            }
            setBonusUsed(true)
        }
        else{
            let errorMessage = 'Une erreur est survenue. Veuillez réessayer plus tard.'
            if(setReponseResult.error){
                errorMessage = setReponseResult.error;
            }

            setError({code: 1016, message: errorMessage})
        }

        setModalBonusVisible(false)
        setBonusSelected(null)
        setLoadingBonus(false)
    }

    const stylesCategory = StyleSheet.create({
        categoryContainer: {
            backgroundColor: colors.backgroundColor,
            marginBottom: 5,
            height: helper.isLittleScreen() ? 50 : 70,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        containerCarte: {
            flex: 1,
            position: "absolute",
            width: '100%',
            height: '100%'
        },
        carte: {
            flex: 1,
            // height: '100%',
            width: '100%',
            resizeMode: 'stretch'
        },
        containerPlayers: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        currentPlayerText:{
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            fontWeight: FONTS.bold,
            borderColor: colors.backgroundColor,
            borderWidth: 2,
            paddingVertical: 2,
            paddingHorizontal: 10,
            borderRadius: ROUNDED.lg,
            textAlign: "center"
        },
        playerText: {
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            fontWeight: FONTS.semibold,
            borderColor: COLORS.white,
            borderWidth: 2,
            paddingVertical: 2,
            paddingHorizontal: 10,
            borderRadius: ROUNDED.lg,
            textAlign: "center"
        },
        categoryText: {
            fontSize: helper.isLittleScreen() ? SIZES.sm: SIZES.base,
            fontWeight: FONTS.semibold,
            color: COLORS.white,
            textAlign: "center",
            position: "relative",
        },
        containerAvatar:{
            flex: 1,
            flexDirection: 'row',
            justifyContent: "center",
            height: 30,
            marginTop: helper.isLittleScreen() ? 0 : 5
        },
        boxAvatar: {
            borderColor: colors.backgroundColor,
            borderWidth: 3,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: ROUNDED.full,
            aspectRatio: 1,
            overflow: "hidden",
            width: helper.isLittleScreen() ? 65 : 80,
            height: helper.isLittleScreen() ? 65 : 80,
        },
        image: {
            width: '80%',
            height: '80%',
            resizeMode: "cover"
        }
    })

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <SafeAreaView style={styles.modalContainer}>
                <View>
                    <View style={stylesCategory.categoryContainer}>
                        <View style={stylesCategory.containerCarte}>
                            { bandeauImage !== null && (
                                <Image style={stylesCategory.carte} source={bandeauImage}/>
                            )}
                        </View>
                        { level?.category_id > 0 && bonus1 > 0 && !bonusUsed && (
                            <View style={{position: "absolute", left: helper.isLittleScreen() ? 5 : 10,}}>
                                <TouchableOpacity onPress={() => handlePressBonus(1)} style={{ paddingHorizontal: 8, paddingVertical: 4, backgroundColor: COLORS.white, width: 50, aspectRatio: 1, justifyContent: "center", borderWidth:3, borderColor: COLORS.red, borderRadius: ROUNDED.full,  flexDirection: "row", alignItems: 'center', marginRight:5 }}>
                                    <Image style={{width: 20, height: 20}} source={require('../../assets/general/refresh3.png')}/>
                                    <Text style={{color: COLORS.orange, fontWeight: FONTS.bold, fontSize: SIZES["2xs"]}} >{bonus1}</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                        <View style={{paddingHorizontal: 10}}>
                            <Text style={stylesCategory.categoryText}>{level?.category_name ? level.category_name : "Validation"}</Text>
                        </View>
                        { level?.category_id > 0 && bonus2 > 0 && !bonusUsed && (
                            <View style={{position: "absolute", right: helper.isLittleScreen() ? 5 : 10}}>
                                <TouchableOpacity onPress={() => handlePressBonus(2)} style={{ paddingHorizontal: 8, paddingVertical: 4, backgroundColor: COLORS.white, width: 50, aspectRatio: 1, justifyContent: "center", borderWidth:3, borderColor: COLORS.blue, borderRadius: ROUNDED.full,  flexDirection: "row", alignItems: 'center'}}>
                                    <Image style={{width: 20, height: 20}} source={require('../../assets/general/cible.png')}/>
                                    <Text style={{color: COLORS.blue, fontWeight: FONTS.bold, fontSize: SIZES["2xs"]}} >{bonus2}</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>

                    { question === null ? (
                        <ActivityIndicator />
                    ) : (
                        <View style={styles.questionContainer}>
                            <Text style={styles.questionText}>{removeHtmlTags(he.decode(question.question))}</Text>

                            <TouchableOpacity
                                style={[
                                    styles.responseButton,
                                    selectedResponses.includes(1) && styles.responseButtonSelected,
                                    reponse1CibleStyle,
                                    reponse1Style,
                                ]}
                                onPress={() => toggleAnswerSelection(1)}
                            >
                                <View style={{alignItems: "center", flexDirection: "row"}}>
                                    <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition1))}</Text>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.responseButton,
                                    selectedResponses.includes(2) && styles.responseButtonSelected,
                                    reponse2CibleStyle,
                                    reponse2Style,
                                ]}
                                onPress={() => toggleAnswerSelection(2)}
                            >

                                <View style={{alignItems: "center", flexDirection: "row"}}>
                                    <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition2))}</Text>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.responseButton,
                                    selectedResponses.includes(3) && styles.responseButtonSelected,
                                    reponse3CibleStyle,
                                    reponse3Style,
                                ]}
                                onPress={() => toggleAnswerSelection(3)}
                            >
                                <View style={{alignItems: "center", flexDirection: "row"}}>
                                    <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition3))}</Text>
                                </View>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.responseButton,
                                    selectedResponses.includes(4) && styles.responseButtonSelected,
                                    reponse4CibleStyle,
                                    reponse4Style,
                                ]}
                                onPress={() => toggleAnswerSelection(4)}
                            >
                                <View style={{alignItems: "center", flexDirection: "row"}}>
                                    <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition4))}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    )}

                </View>

                <View>
                    <Text style={[{fontSize: helper.isLittleScreen() ? SIZES["xl"] : SIZES["2xl"], fontWeight: FONTS.bold, textAlign: "center"} ,styleValidation]}>{textValidation}</Text>

                    <View style={{width: '100%', alignItems: "center"}}>
                        <View style={styles.bottomButtonContainer}>
                            <View style={{height: 20, width: "100%"}}>
                                {isLoading && <ActivityIndicator size={"small"} />}
                            </View>
                            <View style={{height: 70, width: "100%"}}>
                                {!validerClicked && (
                                    <CustomButton title="Valider" onPress={ () => {
                                        setIsLoading(true);
                                        handleValidModal();
                                    }} />
                                )}
                                {canGoNext && (
                                    <CustomButton title="Suivant"
                                                  onPress={ nextModal }
                                    />
                                )}

                                {(!canGoNext && validerClicked && !isLoading) && (
                                    <CustomButton title="Fermer"
                                                  onPress={closeModal}
                                                  backgroundColor={COLORS.gray}
                                                  borderColor={COLORS.lightGray}
                                                  shadowColor={COLORS.darkerGray}
                                                  textColor={COLORS.darkerGray}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                </View>

                <Modal visible={modalBonusVisible} animationType="slide" transparent={true}>
                    <View style={styles.containerModal}>
                        <Text style={styles.textModal}>Bonus</Text>

                        <View style={styles.containerContrat}>
                            { bonusSelected === 1 && (
                                <View style={{flex: 1, alignItems: "center", padding: 5, justifyContent: "center"}}>
                                    <Image style={{width: helper.isLittleScreen() ? 60 : 70, height: helper.isLittleScreen() ? 60 : 70, marginBottom: isLittleScreen() ? 8 : 15}} source={require('../../assets/general/refresh_xxl.png')}/>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl'], fontWeight: FONTS.semibold, marginVertical: isLittleScreen() ? 8 : 15}}>
                                        Switch
                                    </Text>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], marginBottom: isLittleScreen() ? 3 : 5}}>
                                        Change la question actuelle d’un niveau par une autre aléatoirement.
                                    </Text>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], color: COLORS.red, fontWeight: FONTS.semibold}}>
                                        Êtes vous sur de vouloir utiliser ce bonus ?
                                    </Text>
                                </View>
                            )}
                            { bonusSelected === 2 && (
                                <View style={{flex: 1, alignItems: "center", padding: 5, justifyContent: "center"}}>
                                    <Image style={{width: helper.isLittleScreen() ? 80 : 90, height: helper.isLittleScreen() ? 80 : 90, marginBottom: isLittleScreen() ? 8 : 15}} source={require('../../assets/general/cible_xxl.png')}/>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl'], fontWeight: FONTS.semibold, marginVertical: isLittleScreen() ? 8 : 15}}>
                                        Cible
                                    </Text>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], marginBottom: isLittleScreen() ? 5 : 10}}>
                                        Sélectionne la bonne réponse ou une des bonnes réponses de la question
                                    </Text>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], fontWeight: FONTS.semibold, marginBottom: isLittleScreen() ? 3 : 5}}>
                                        Attention une ou plusieurs réponse possible
                                    </Text>
                                    <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['base'] : SIZES['lg'], color: COLORS.red, fontWeight: FONTS.semibold}}>
                                        {bonusUsed ? "Vous avez déjà utilisé un bonus." : "Êtes vous sur de vouloir utiliser ce bonus ?"}
                                    </Text>
                                </View>
                            )}

                            {loadingBonus && <ActivityIndicator />}


                            <View style={{alignItems: "center"}}>
                                { !bonusUsed && !loadingBonus && (
                                    <CustomButton
                                        onPress={handleUseBonus}
                                        title={"Utiliser"}
                                        width={'70%'}
                                    />
                                )}
                                <CustomButton
                                    title="Annuler"
                                    onPress={handleCloseBonus}
                                    backgroundColor={COLORS.gray}
                                    borderColor={COLORS.lightGray}
                                    shadowColor={COLORS.darkerGray}
                                    textColor={COLORS.darkerGray}
                                    width={'70%'}
                                />
                            </View>
                        </View>
                    </View>
                </Modal>

            </SafeAreaView>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        flexDirection: "column",
        justifyContent: 'space-between',
        elevation: 5,
        paddingBottom: 30
    },
    questionContainer: {
        padding: 20,
    },
    joueurText: {
        fontSize: helper.isLittleScreen() ? SIZES.lg : SIZES.xl,
        fontWeight: FONTS.bold,
        textAlign: 'center',
        marginTop: helper.isLittleScreen() ? 15 : 25,
        color: COLORS.black,
    },
    questionText: {
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.semibold,
        textAlign: 'center',
        marginTop: helper.isLittleScreen() ? 5 : 10,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        color: COLORS.black,
    },
    responseButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        width: '100%',
        paddingHorizontal: helper.isLittleScreen() ? 8 : 10,
        paddingVertical: helper.isLittleScreen() ? 10 : 15,
        marginVertical: 5,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    responseButtonSelected: {
        backgroundColor: COLORS.primary,
        borderWidth: 1,
        borderColor: COLORS.primary,
    },
    responseButtonText: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        color: COLORS.black,
        fontWeight: FONTS.semibold,
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
    },
    bottomButtonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 400,
        paddingHorizontal: 20,
        marginBottom: 10,
    },
    iconContainer: {
        backgroundColor: COLORS.white,
        borderRadius: 100,
        marginRight: 3,
        aspectRatio: 1,
        width: 30,
        alignItems: "center",
        justifyContent: "center"
    },
    containerModal : {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 30,
        backgroundColor: 'rgba(24,24,27,0.8)'
    },
    textModal : {
        textTransform: "uppercase",
        color: COLORS.white,
        fontWeight: FONTS.bold,
        marginBottom: isLittleScreen() ? 15 : 30,
        fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl']
    },
    containerContrat: {
        justifyContent: "space-between",
        height: isLittleScreen() ? '70%' : '60%',
        padding: isLittleScreen() ? 8 : 10,
        width: '100%',
        maxWidth: 400,
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.xl
    },
    buttonContrat : {
        padding: isLittleScreen() ? 8 : 10,
        borderWidth: 2,
        borderRadius: ROUNDED.xl,
        justifyContent: "center",
        height: isLittleScreen() ? 70 : 90,
        marginBottom: isLittleScreen() ? 15 : 30
    },
    textContratTitle : {
        fontWeight: FONTS.bold,
        fontSize: isLittleScreen() ? SIZES.base : SIZES.lg
    },
});

export default QuestionModal;
