const COLORS = {
    primary: "#FFB834",
    lightPrimary: "#FFD280",
    darkPrimary: "#946B1E",

    secondary: "#444262",
    tertiary: "#FF7754",

    white: "#FFFFFF",

    gray: "#F5F5F5",
    lightGray: "#FCFCFC",
    darkGray: "#C8C8C8",
    darkerGray: "#707070",

    black: "#18181B",
    black1: "#3F3F46",

    blue: "#38BDF8",
    lightBlue: "#bae6fd",
    darkBlue: "#0284C7",
    red: "#DC2626",
    darkRed: "#991B1B",
    lightRed: "#FA2B2B",
    green: "#4ADE80",
    lightGreen: "#bbf7d0",
    darkGreen: "#16A34A",
    orange: "#FB923C",
    darkOrange: "#C2410C",
    yellow: "#FDE047",
    darkYellow: "#CAA902",
    purple: "#B24C86",
    darkPurple: "#6B2E51",

    transparent: 'rgba(255,255,255,0)',

    contrat : {
        blue: "#679EC5",
        darkBlue: "#3883B3",
        lightBlue: "#A5BFDB",

        red: "#CD5B58",
        darkRed: "#B3383B",
        lightRed: "#D5827A",

        green: "#6DA38B",
        darkGreen: "#4A8563",
        lightGreen: "#91BEA5",

        orange: "#DCA051",
        darkOrange: "#D0732F",
        lightOrange: "#E6BF77",

        purple: "#B24C86",
        darkPurple: "#9F2F6F",
        lightPurple: "#C871A2",
    }

};

const FONTS = {
    thin: '100',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
};

const SIZES = {
    '3xs': 8,
    '2xs': 10,
    xs: 12,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
};

const ROUNDED = {
    none: 0,
    sm: 2,
    md: 4,
    lg: 8,
    xl: 12,
    '2xl': 16,
    '3xl': 24,
    full: 9999,
};

const SHADOWS = {
    small: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 2,
    },
    medium: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 5.84,
        elevation: 5,
    },
};


export { COLORS, FONTS, SIZES, ROUNDED, SHADOWS };
