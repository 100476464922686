import React, {useState, useEffect, useContext, useRef} from 'react';
import {
    View,
    Text,
    StyleSheet,
    TextInput,
    Linking,
    Image,
    SafeAreaView, ActivityIndicator, TouchableOpacity, Modal
} from 'react-native';
import { COLORS, SIZES, FONTS, ROUNDED } from "../constants/theme";
import AuthService from "../services/AuthService";
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as helper from "../utils/helpers";
import CustomButton from "../components/CustomButton";
import {ROOT_URL} from "../constants/api";
import { AuthContext  } from "../utils/AuthContext";
import ErrorNotification from "../components/ErrorNotification";
import ModalNoAccess from "../components/ModalNoAccess";

function Login({isLoading}) {
    const [error, setError] = useState(null);
    const imageSource = helper.isTabletteScreen()
        ? require('../../assets/general/Fond_tablette.png')
        : require('../../assets/general/Fond_Mobile.png');
    const passwordRef = useRef(null)
    const passwordInscriptionRef = useRef(null)
    const passwordConfirmRef = useRef(null)
    const emailRef = useRef(null)
    const { checkLoginStatus } = useContext(AuthContext);
    const [connexionForm, setConnexionForm] = useState(false)
    const [inscriptionForm, setInscriptionForm] = useState(false)
    const [pseudo, setPseudo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);  // New state variable for error messages
    const [noAccessModal, setNoAccessModal] = useState(false)
    const [userRegistred, setUserRegistred] = useState(null)

    useEffect(() => {
        setEmail("");
        setPassword("");
        setErrorMessage(null);
    }, [connexionForm])

    useEffect(() => {
        setPseudo("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setErrorMessage(null);
    }, [inscriptionForm])

    const handleLogin = async () => {
        // Check if email and password are not empty
        if (email === '' || password === '') {
            setErrorMessage("L'e-mail et le mot de passe sont requis");
            return;
        }

        // Check if email is in the correct format
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Veuillez entrer une adresse e-mail valide.');
            return;
        }

        try {
            const response = await AuthService.login(email.trim().toLowerCase(), password);

            if(response.access_token){
                await AsyncStorage.setItem('token', response.access_token);
                checkLoginStatus();
                setErrorMessage(null);
            }
            else if(response.no_access_page) {
                setNoAccessModal(true);
            }
            else {
                setErrorMessage('Une erreur est survenue. Veuillez réessayer.');
            }
        } catch (error) {
            if (error.message === 'invalid credentials') {
                setErrorMessage('E-mail ou mot de passe invalide. Veuillez réessayer.');
            }
            else if(error.message === 'mobile_access') {
                setErrorMessage("Vous n'avez d'abonnement actif pour l'accès à l'application. Veuillez vous rendre sur objectifofficiersp.com pour souscrire à l'abonnement mobile.");
            }
            else {
                setError({code: '0003', message: "Votre connexion internet semble limité. Veuillez vérifier votre connexion Wi-Fi ou vos données mobiles et réessayez."})
            }
        }
    }

    const handlePress = async (url) => {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            await Linking.openURL(url);
        } else {
            setError({code: '0004', message: `Vous ne pouvez pas ouvrir le l'url ${url}. Veuillez réessayer plus tard.`})
        }
    };

    const handleRegister = async () => {
        // Perform input validations
        if (pseudo === '' || email === '' || password === '' || confirmPassword === '') {
            setErrorMessage("Tous les champs sont requis.");
            return;
        }

        // Check if email is in the correct format
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("Veuillez entrer une adresse e-mail valide.");
            return;
        }

        // Check if password length is less than 8 characters
        if (password.length < 8) {
            setErrorMessage("Le mot de passe doit comporter au moins 8 caractères.");
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setErrorMessage("Les mots de passe ne correspondent pas.");
            return;
        }

        // Call your registration service (replace 'YourRegistrationService' with your actual service)
        try {
            const response = await AuthService.register(pseudo.trim(), email.trim().toLowerCase(), password, confirmPassword);

            if (response.success) {
                setUserRegistred(response.data.user)
                setNoAccessModal(true)
            } else {
                // Registration failed, handle the error
                setErrorMessage('Une erreur est survenue lors de l\'inscription. Veuillez réessayer. 1');
            }
        } catch (error) {
            // Handle any unexpected errors
            setErrorMessage('Une erreur est survenue lors de l\'inscription. Veuillez réessayer. 2');
        }
    };

    const handleCloseNoAccess = () => {
        setConnexionForm(false);
        setInscriptionForm(false);
        setPseudo("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setErrorMessage(null);
        setNoAccessModal(false)
    }


    return (
        <SafeAreaView style={styles.container}>
            <Image style={styles.image} source={imageSource}/>
            <View style={{position: "absolute",width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                <Image style={styles.imagePompier} source={require('../../assets/general/pompier.png')}/>
            </View>
            <View style={{width: "100%", height: helper.isLittleScreen() ? (inscriptionForm ? "8%" : "25%") : (inscriptionForm ? "20%" : "35%"), alignItems: "center"}}>
                <Image style={styles.imageLogo} source={require('../../assets/logo/logo_blanc.png')}/>
            </View>
            { isLoading ? (
                <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                    <ActivityIndicator size="large" color={COLORS.white} />
                </View>
            ) : (
                <View style={styles.containerBox}>
                    <View style={styles.containerContent}>
                        { !connexionForm && !inscriptionForm && (
                            <Text style={styles.title}>Bienvenue sur l’application mobile de préparation aux concours d’officiers de sapeurs-pompiers</Text>
                        )}
                        {/*{ !connexionForm && (*/}
                        {/*    <Text style={styles.description}>Lorem ipsum dolor sit amet, consectetur</Text>*/}
                        {/*)}*/}

                        {errorMessage && (
                            <Text style={styles.errorText}>{errorMessage}</Text>  // Display the error message if it exists
                        )}

                        {connexionForm && (
                            <View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Adresse Email</Text>
                                    <TextInput
                                        style={styles.input}
                                        onChangeText={setEmail}
                                        value={email}
                                        returnKeyType="next"
                                        onSubmitEditing={() => passwordRef.current.focus()}
                                    />
                                </View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Mot de passe</Text>
                                    <TextInput
                                        ref={passwordRef}
                                        style={styles.input}
                                        onChangeText={setPassword}
                                        value={password}
                                        secureTextEntry
                                        returnKeyType="done"
                                        onSubmitEditing={handleLogin}
                                    />
                                </View>

                                <View style={[styles.containerButton, {marginTop: 0}]}>
                                    <CustomButton
                                        title={'Se connecter'}
                                        onPress={() => {handleLogin()}}
                                    />
                                    <CustomButton
                                        title={'Retour'}
                                        backgroundColor={COLORS.gray}
                                        borderColor={COLORS.lightGray}
                                        shadowColor={COLORS.darkerGray}
                                        textColor={COLORS.darkerGray}
                                        onPress={() => {setConnexionForm(false)}}
                                    />
                                    <Text onPress={() => {handlePress(`${ROOT_URL}/forgot-password`)}} style={{color: COLORS.darkerGray, textDecorationLine: "underline", marginTop : helper.isLittleScreen() ? 8: 10}}>Mot de passe oublié ?</Text>
                                </View>
                            </View>
                        )}

                        {inscriptionForm && (
                            <View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Pseudo</Text>
                                    <TextInput
                                        style={styles.input}
                                        onChangeText={setPseudo}
                                        value={pseudo}
                                        returnKeyType="next"
                                        onSubmitEditing={() => emailRef.current.focus()}
                                    />
                                </View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Adresse Email</Text>
                                    <TextInput
                                        ref={emailRef}
                                        style={styles.input}
                                        onChangeText={setEmail}
                                        value={email}
                                        returnKeyType="next"
                                        onSubmitEditing={() => passwordInscriptionRef.current.focus()}
                                    />
                                </View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Mot de passe</Text>
                                    <TextInput
                                        ref={passwordInscriptionRef}
                                        style={styles.input}
                                        onChangeText={setPassword}
                                        value={password}
                                        secureTextEntry
                                        returnKeyType="next"
                                        onSubmitEditing={() => passwordConfirmRef.current.focus()}
                                    />
                                </View>
                                <View style={styles.inputContainer}>
                                    <Text style={styles.label}>Confirmer mot de passe</Text>
                                    <TextInput
                                        ref={passwordConfirmRef}
                                        style={styles.input}
                                        onChangeText={setConfirmPassword}
                                        value={confirmPassword}
                                        secureTextEntry
                                        returnKeyType="done"
                                        onSubmitEditing={handleLogin}
                                    />
                                </View>

                                <View style={[styles.containerButton, {marginTop: 0}]}>
                                    <CustomButton
                                        title={"S'inscire"}
                                        onPress={() => {handlePress(`${ROOT_URL}/register`)}}
                                    />
                                    <CustomButton
                                        title={'Retour'}
                                        backgroundColor={COLORS.gray}
                                        borderColor={COLORS.lightGray}
                                        shadowColor={COLORS.darkerGray}
                                        textColor={COLORS.darkerGray}
                                        onPress={() => {setInscriptionForm(false)}}
                                    />
                                    <Text onPress={() => {handlePress(`${ROOT_URL}/forgot-password`)}} style={{color: COLORS.darkerGray, textDecorationLine: "underline", marginTop : helper.isLittleScreen() ? 8: 10}}>Mot de passe oublié ?</Text>
                                </View>
                            </View>
                        )}




                        { !connexionForm && !inscriptionForm && (
                            <View style={styles.containerButton}>
                                <CustomButton
                                    title={'Connexion'}
                                    onPress={() => {setConnexionForm(true)}}
                                />
                                <Text style={{marginVertical: helper.isLittleScreen() ? 3 : 5, color: COLORS.darkGray, fontWeight: FONTS.semibold}}>Ou</Text>
                                <TouchableOpacity onPress={() => {handlePress(`${ROOT_URL}/register`)}} style={{paddingHorizontal: 6, paddingVertical: 8, width: '100%'}}>
                                    <Text style={{color: COLORS.darkerGray, textDecorationLine: "underline", textAlign: "center"}}>S'inscrire</Text>
                                </TouchableOpacity>
                            </View>
                        )}



                    </View>
                </View>
            )}

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />

            <Modal visible={noAccessModal}>
                <ModalNoAccess user={userRegistred} onClose={handleCloseNoAccess} />
            </Modal>

        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: COLORS.white,
        position: "relative",
        justifyContent: "space-between",
    },
    image: {
        width: helper.isLittleScreen() ? '100%' : '101%',
        height: helper.isLittleScreen() ? '100%' : '110%',
        resizeMode: "cover",
        position: "absolute",
        top: 0,
    },
    imageLogo: {
        width: '100%',
        height: '100%',
        resizeMode: "contain",
        zIndex: 1
    },
    imagePompier: {
        width: '100%',
        height: '65%',
        resizeMode: "contain",
        marginTop: helper.isLittleScreen() ? 80 : 100
    },
    containerBox: {
        paddingHorizontal:  helper.isLittleScreen() ? 30 : 40,
        width: '100%',
        marginBottom: helper.isLittleScreen() ? 70 : 90,
        alignItems: "center",
    },
    containerContent: {
        padding:  helper.isLittleScreen() ? 15 : 20,
        borderRadius: ROUNDED["xl"],
        backgroundColor: COLORS.white,
        width: '100%',
        maxWidth: 500
    },
    title: {
        color: COLORS.black1,
        fontWeight: FONTS.bold,
        textAlign: "center",
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg
    },
    description: {
        color: COLORS.darkerGray,
        textAlign: "center",
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm
    },
    containerInputs: {
        marginVertical: 30
    },
    containerButton: {
        marginTop: helper.isLittleScreen() ? 80 : 90,
        alignItems: "center",
    },
    inputContainer: {
        marginBottom: helper.isLittleScreen() ? 10 : 15,
    },
    label: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 3 : 8,
        color: COLORS.darkerGray
    },
    input: {
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        borderRadius: 4,
        paddingVertical: helper.isLittleScreen() ? 6 : 8,
        paddingHorizontal: helper.isLittleScreen() ? 10 : 12,
        fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base,
    },
    errorText: {
        color: COLORS.red,
        textAlign: "center",
        fontSize: SIZES.xs,
        marginBottom: helper.isLittleScreen() ? 2 : 5,
        fontWeight: FONTS.semibold
    }
});

export default Login;
