import React, {useEffect, useState} from 'react';
import {Modal, View, Text, StyleSheet, SafeAreaView, Image, ScrollView} from 'react-native';
import {COLORS, FONTS, ROUNDED, SIZES} from '../constants/theme';
import CustomButton from './CustomButton';
import {getRankById, isLittleScreen} from "../utils/helpers";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import UserService from "../services/UserService";
import {SvgUri} from "react-native-svg";
import {ROOT_URL} from "../constants/api";
import * as helper from "../utils/helpers";
import {ActivityIndicator} from "react-native-paper";
import ErrorNotification from "./ErrorNotification";


function ProfileModal({ onClose, user_id }) {
    const [error, setError] = useState(null);
    const [profil, setProfil] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingFriend, setLoadingFriend] = useState(false);

    useEffect( () => {
        fetchUserData()

    }, [])

    const fetchUserData = async () => {
        try {
            setLoading(true);
            const profilData = await UserService.getProfil(user_id)

            if(profilData){
                setProfil(profilData)
            }
            else{
                setError({code: '0008'})
            }
            setLoading(false);

        } catch (error) {
            // console.log(error)
            setError({code: '0009'})
            setLoading(false);
        }
    }

    const handleDemandeAmis = async () => {
        try {
            setLoadingFriend(true);
            const requestData = await UserService.sendDemandeAmis(user_id)

            if(requestData.sended){
                alert("Votre demande a bien été envoyée")
            }
            else{
                setError({code: '0007', message: "Votre demande n'a pas pu aboutir. Veuillez réessayer plus tard."})
            }

            setProfil(requestData.user);
            setLoadingFriend(false);

        } catch (error) {
            // console.log(error)
            setError({code: '0011', message: "Votre demande n'a pas pu aboutir. Veuillez réessayer plus tard."})
            setLoadingFriend(false);
        }
    }

    const handleReponseDemandeAmis = async (statut) => {
        try {
            setLoadingFriend(true);
            const requestData = await UserService.setReponseDemandeAmis(user_id, statut)

            if(statut === 1){
                alert("Vous êtes désormais amis.")
            }

            setProfil(requestData.user)
            setLoadingFriend(false);

        } catch (error) {
            // console.log(error)
            setError({code: '0010'})
            setLoadingFriend(false);
        }
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                <View style={styles.container}>
                    { loading ? (
                        <ActivityIndicator animating={true} size="large" color={COLORS.primary} />
                    ) : (
                        <View style={styles.containerTop}>
                            <View style={styles.containerAvatar}>
                                {profil?.avatar_path && (
                                    <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${profil?.avatar_path.replace('.svg', '.png')}`}}/>
                                )}
                            </View>
                            <Text style={styles.profilName}>{profil?.name}</Text>
                            <Text style={styles.profilPoints}>{profil?.total_points} pts</Text>

                            { profil?.amis === null || profil?.amis.statut_id === -1 ? (
                                <View style={[styles.containerBottom]}>
                                    <View style={styles.bottom}>
                                        { loadingFriend ? (
                                            <ActivityIndicator animating={true} size="small" color={COLORS.blue} />
                                        ) : (
                                            <CustomButton
                                                onPress={handleDemandeAmis}
                                                title={"Demande d'amis"}
                                                backgroundColor={COLORS.blue}
                                                borderColor={COLORS.lightBlue}
                                                shadowColor={COLORS.darkBlue}
                                            >
                                                <FontAwesome5 name={'user-plus'} size={22} color={COLORS.white} style={{marginRight: 10}}/>
                                            </CustomButton>
                                        )}
                                    </View>
                                </View>
                            ) : (
                                profil?.amis.statut_id === 0 ? (
                                    profil?.amis.ami_id === user_id ? (
                                        <Text style={{color: COLORS.darkBlue}}>Votre demande est en attente</Text>
                                    ) : (
                                        <View style={styles.containerButtons}>
                                            <Text style={{color: COLORS.darkBlue}}>Vous avez une demande en attente</Text>
                                            <View style={styles.boxButtons}>
                                                <CustomButton
                                                    onPress={ () => handleReponseDemandeAmis(-1)}
                                                    title={"Refuser"}
                                                    backgroundColor={COLORS.red}
                                                    borderColor={COLORS.lightRed}
                                                    shadowColor={COLORS.darkRed}
                                                    width={'48%'}
                                                />
                                                <CustomButton
                                                    onPress={ () => handleReponseDemandeAmis(1 )}
                                                    title={"Accepter"}
                                                    backgroundColor={COLORS.green}
                                                    borderColor={COLORS.lightGreen}
                                                    shadowColor={COLORS.darkGreen}
                                                    width={'48%'}
                                                />
                                            </View>
                                        </View>
                                    )
                                ) : profil?.amis.statut_id === 1 && (
                                    <View style={styles.bottom}>
                                        { loadingFriend ? (
                                            <ActivityIndicator animating={true} size="small" color={COLORS.blue} />
                                        ) : (
                                            <CustomButton
                                                onPress={ () => handleReponseDemandeAmis(-1)}
                                                title={"Retirer des amis"}
                                                backgroundColor={COLORS.red}
                                                borderColor={COLORS.lightRed}
                                                shadowColor={COLORS.darkRed}
                                            >
                                                <FontAwesome5 name={'user-minus'} size={22} color={COLORS.white} style={{marginRight: 10}}/>
                                            </CustomButton>
                                        )}
                                    </View>
                                )
                            )}

                            <View style={styles.containerStats}>
                                <View style={styles.boxStats}>
                                    <Text style={styles.titleStats}>Rang</Text>
                                    { profil?.rank && (
                                        <View style={styles.containerRang}>
                                            <Image style={{width: "100%", height: "100%", resizeMode: "contain"}} source={helper.getRankById(profil?.rank.rank.id)}/>
                                        </View>
                                    )}
                                    <Text style={styles.titleRank}>{profil?.rank ? profil?.rank.rank.name : 'Non classé'}</Text>
                                </View>
                                <View style={styles.boxStats}>
                                    <Text style={styles.titleStats}>Classement Global</Text>
                                    <Text style={styles.titlePosition}>#{profil?.position}</Text>
                                </View>
                                <View style={styles.boxStats}>
                                    <Text style={styles.titleStats}>Niveau</Text>
                                    <Text style={styles.titlePosition}>{profil?.level}</Text>
                                </View>
                            </View>

                        </View>
                    )}
                    <View style={styles.containerBottom}>
                        <View style={styles.bottom}>
                            <CustomButton
                                onPress={onClose}
                                title={'Fermer'}
                                backgroundColor={COLORS.gray}
                                borderColor={COLORS.gray}
                                shadowColor={COLORS.darkerGray}
                                textColor={COLORS.darkerGray}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 30,
        justifyContent: "space-between",
        marginTop: 20
    },
    containerTop: {
        alignItems: "center",
    },
    containerAvatar: {
        width: 120,
        height: 120,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: COLORS.gray,
        overflow: "hidden",
        marginBottom: helper.isLittleScreen() ? 5 : 10,
        justifyContent: "center",
        alignItems: "center",
    },
    containerBottom: {
        width: "100%",
        alignItems: "center",
        marginBottom: helper.isLittleScreen() ? 5 : 10,
    },
    bottom: {
        width: "100%",
        maxWidth: 400
    },
    profilName: {
        fontSize: SIZES['lg'],
        color: COLORS.darkerGray,
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 4 : 6
    },
    profilPoints: {
        fontSize: SIZES['xl'],
        color: COLORS.darkerGray,
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 15
    },
    containerButtons: {
        width: "100%",
        maxWidth: 400,
        alignItems: "center"
    },
    boxButtons: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    containerStats: {
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        marginTop: helper.isLittleScreen() ? 10 : 15,
    },
    boxStats: {
        alignItems: "center",
        marginHorizontal: 20
    },
    titleStats: {
        fontSize: helper.isLittleScreen() ? SIZES['xs'] : SIZES['sm'],
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 5 : 8,
        color: COLORS.darkerGray
    },
    containerRang: {
        width: 58,
        height: 58,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        borderWidth: 1,
        borderColor: COLORS.gray,
        marginBottom: helper.isLittleScreen() ? 3 : 4,
    },
    titleRank: {
        fontSize: helper.isLittleScreen() ? SIZES['sm'] : SIZES['base'],
        color: COLORS.black1,
        fontWeight: FONTS.semibold
    },
    titlePosition: {
        fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['4xl'],
        color: COLORS.black1,
        fontWeight: FONTS.bold,
    }
});

export default ProfileModal;
