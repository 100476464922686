import React, { useState } from 'react';
import { TouchableOpacity, Text, View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native';
import CustomButton from './CustomButton';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';

function SelectJoueurActionModal({ currentJoueur, joueurs, handleSelectJoueur, handleCloseModal, selectText }) {
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const handlePlayerSelection = (player) => {
        setSelectedPlayer(player);
    };

    const handleValidation = () => {
        if (selectedPlayer) {
            // Perform validation logic or any other action
            handleSelectJoueur(selectedPlayer);
        }
    };

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.modalContainer}>
                <View>
                    <Text style={styles.joueurPseudoText}>{currentJoueur.pseudo}</Text>
                    <Text style={styles.joueurText}>{ selectText ? selectText : "Doit sélectionner un joueur" } :</Text>
                    {joueurs.map((joueur) => {
                        if (joueur.pseudo !== currentJoueur.pseudo) {
                            return (
                                <TouchableOpacity
                                    key={joueur.pseudo}
                                    style={[
                                        styles.responseButton,
                                        selectedPlayer === joueur ? styles.responseButtonSelected : null,
                                    ]}
                                    onPress={() => handlePlayerSelection(joueur)}
                                >
                                    <Text style={styles.responseButtonText}>{joueur.pseudo}</Text>
                                </TouchableOpacity>
                            );
                        }
                        return null;
                    })}
                </View>
                <View style={styles.bottomButtonContainer}>
                    {selectedPlayer ? (
                        <CustomButton
                            title="Valider"
                            onPress={handleValidation}
                        />
                    ) : null}
                    <CustomButton
                        title="Fermer"
                        onPress={handleCloseModal}
                        backgroundColor={COLORS.gray}
                        borderColor={COLORS.lightGray}
                        shadowColor={COLORS.darkerGray}
                        textColor={COLORS.darkerGray}
                    />
                </View>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 20,
        elevation: 5,
    },
    joueurPseudoText: {
        fontSize: SIZES["3xl"],
        fontWeight: FONTS.bold,
        textAlign: 'center',
        marginTop: 20,
        color: COLORS.black,
    },
    joueurText: {
        fontSize: SIZES.xl,
        fontWeight: FONTS.regular,
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 20,
        color: COLORS.black,
    },
    responseButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        width: '100%',
        paddingHorizontal: 10,
        paddingVertical: 15,
        marginVertical: 5,
        textAlign: 'center',
        wordBreak: 'break-word',
    },
    responseButtonSelected: {
        backgroundColor: COLORS.primary,
        borderWidth: 1,
        borderColor: COLORS.primary,
    },
    responseButtonText: {
        fontSize: SIZES.lg,
        color: COLORS.black,
        fontWeight: FONTS.semibold,
        textAlign: 'center',
    },
    bottomButtonContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginVertical: 10,
    },
    iconContainer: {
        backgroundColor: COLORS.white,
        borderRadius: 100,
        marginRight: 3,
        aspectRatio: 1,
        width: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default SelectJoueurActionModal;
