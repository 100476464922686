import React, { useState, useEffect  } from 'react';
import {
    Text,
    View,
    StyleSheet,
    Modal,
    ActivityIndicator,
    Image,
    ScrollView,
    SafeAreaView
} from 'react-native';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";
import CustomButton from "./CustomButton";
import {ROOT_URL} from "../constants/api";
import ResultDefiDetailModal from "./ResultDefiDetailModal";

function ResultDefiModal({defi, handleCloseModal}) {
    const [openDetailModal, setOpenDetailModal] = useState(false)

    const handleOpenDefailModal = () => {
        setOpenDetailModal(true)
    }
    const closeDetailModal = () => {
        setOpenDetailModal(false)
    }

    const renderResult = (creator, mb = 0) => {
        if(defi){
            const user_id = creator ? defi?.creator_user_id : defi?.opponent_user_id
            const isCurrentUser = user_id === defi.current_user_id;

            let name = creator ? defi.user_name : defi.opponent_name;
            let time = creator ? defi.user_time :  defi.opponent_time;
            let avatar = creator ? defi.user_avatar : defi.opponent_avatar;

            if(defi.is_bot && !creator) {
                name = "Bot";
            }

            const getBorderColor = () => {
                if (isCurrentUser) {
                    return defi.won === 1 ? styles.resultWon : styles.resultLoosed;
                } else {
                    return styles.resultDefault;
                }
            };

            const result = defi.questions.reduce((accumulator, question) => accumulator + (creator ? question.user_valid : question.defi_user_valid), 0);

            return (
                <View style={[styles.containerResult, getBorderColor(), { marginBottom: mb }]}>
                    <View style={styles.containerAvatar}>
                        <View>
                            { defi.is_bot === 1 && !isCurrentUser ? (
                                <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={require('../../assets/general/Robot.png')} />
                            ) : (
                                (avatar) && (
                                    <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={{ uri: `${ROOT_URL}/storage/${avatar.replace('.svg', '.png')}` }} />
                                )
                            )}

                        </View>
                    </View>
                    <Text style={styles.name}>{name}</Text>
                    <Text style={styles.score}>{result} / {defi.questions.length}</Text>
                    <Text style={styles.time}>{time}</Text>
                </View>
            )
        }
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                <View style={styles.modalContainer}>
                    <Text style={[styles.resultatText, {color: defi?.won === 1 ? COLORS.green : COLORS.red}]}>{defi?.won === 1 ? "Vous avez gagné :)" : (defi?.won === 0 && defi?.winner > 0 ? "Vous avez perdu :(" : "Aucun vainqueur !")}</Text>
                    <View>
                        { renderResult(true, 50) }
                        { renderResult(false) }
                    </View>

                    <View>
                        <CustomButton
                            title={"Voir détail"}
                            onPress={handleOpenDefailModal}
                        />
                        <CustomButton
                            title={"Fermer"}
                            backgroundColor={COLORS.gray}
                            textColor={COLORS.darkerGray}
                            shadowColor={COLORS.darkGray}
                            borderColor={COLORS.lightGray}
                            onPress={handleCloseModal}
                        />
                    </View>
                </View>
            </ScrollView>


            <Modal visible={openDetailModal}>
                <ResultDefiDetailModal defi={defi} handleCloseModal={closeDetailModal} />
            </Modal>
        </SafeAreaView>
    )
}


const styles = StyleSheet.create({
    modalContainer: {
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        paddingVertical: 15
    },
    containerResult: {
        position: "relative",
        flex: 1,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: "#FAFAFA",
        paddingVertical: helper.isLittleScreen() ? 15 : 20,
        borderRadius: ROUNDED.lg,
        justifyContent: "center",
        width: "100%"
    },
    containerAvatar: {
        position: "absolute",
        width: "100%",
        height: 80,
        top: -40,
    },
    boxAvatar: {
        width: 80,
        height: 80,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
    },
    name: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base,
        fontWeight: FONTS.semibold,
        textAlign: "center",
        marginTop: 30
    },
    score: {
        color: COLORS.black1,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.bold,
        textAlign: "center",
        marginTop:  helper.isLittleScreen() ? 15 : 20,
        marginBottom:  helper.isLittleScreen() ? 3 : 5,
    },
    time: {
        color: COLORS.black1,
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.bold,
        textAlign: "center",
    },
    resultWon: {
        borderColor: COLORS.green,
        borderWidth: 2
    },
    resultLoosed: {
        borderColor: COLORS.red,
        borderWidth: 2
    },
    resultDefault: {
        borderColor: COLORS.darkGray,
        borderWidth: 1,
    },
    resultatText: {
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        textAlign: "center"
    }
});

export default ResultDefiModal;
