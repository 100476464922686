import React, { useState, useEffect } from 'react';
import {
    TouchableOpacity,
    Text,
    View,
    ScrollView,
    StyleSheet,
    ActivityIndicator,
    Image,
    SafeAreaView,
    Platform
} from 'react-native';
import CustomButton from './CustomButton';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import {removeHtmlTags, getColorByCategory, getImageByCategory, getImageBandeauByCategory} from '../utils/helpers';
import he from 'he';
import PlateauService from "../services/PlateauService";
import * as helper from "../utils/helpers";

function QuestionPlateauModal({question, category_id, toggleSoundEffect, handleCloseModal, handleValidationReponse, avatars, handleNextQuestion, modeDuel, currentJoueur, currentPlayer, player1, player2}) {
    const [selectedResponses, setSelectedResponses] = useState([]);
    const [validerClicked, setValiderClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const [reponse1Style, setReponse1Style] = useState({});
    const [reponse2Style, setReponse2Style] = useState({});
    const [reponse3Style, setReponse3Style] = useState({});
    const [reponse4Style, setReponse4Style] = useState({});
    const [textValidation, setTextValidation] = useState('')
    const [styleValidation, setStyleValidation] = useState({})
    const [winner, setWinner] = useState(null)
    const [looser, setLooser] = useState(null)

    const bandeauImage = getImageBandeauByCategory(category_id)

    const handleValidModal = async () => {

        const correctResponses = [
            question.bonne_reponse1,
            question.bonne_reponse2,
            question.bonne_reponse3,
            question.bonne_reponse4,
        ];
        setValiderClicked(true);
        let selectedCurrentResponses = [0, 0, 0, 0];

        for(let i = 0; i < selectedCurrentResponses.length; i++){
            selectedCurrentResponses[i] = selectedResponses.includes(i + 1) ? 1 : 0;
        }

        let isCorrect = true;
        for(let i = 0; i < correctResponses.length; i++){
            if(correctResponses[i] != selectedCurrentResponses[i]){
                isCorrect = false
            }
        }
        const reponses = handleReponseExist(selectedCurrentResponses)

        handleValidationReponse(isCorrect)



        if(isCorrect){
            if(toggleSoundEffect) {
                await helper.playAudio(require('../../assets/audios/win.mp3'))
            }
            setStyleValidation({color: COLORS.green})
            setTextValidation("Bonne réponse !")
        }
        else{
            if(toggleSoundEffect) {
                await helper.playAudio(require('../../assets/audios/loose.mp3'))
            }
            setStyleValidation({color: COLORS.red})
            setTextValidation("Mauvaise réponse")
        }

        // Set reponse to api
        const setReponseResult = await PlateauService.setReponse(question.id, currentPlayer.id, isCorrect);


        setSelectedResponses(reponses);
        setIsLoading(false);


        if(modeDuel){
            if(isCorrect){
                setCanGoNext(true)
            }
            else{
                setWinner(currentPlayer === player1 ? player2 : player1)
                setLooser(currentPlayer !== player1 ? player2 : player1)
            }
        }
    }

    const toggleAnswerSelection = (answerIndex) => {
        if(!validerClicked){
            if (selectedResponses.includes(answerIndex)) {
                setSelectedResponses(selectedResponses.filter((index) => index !== answerIndex));
            } else {
                setSelectedResponses([...selectedResponses, answerIndex]);
            }
        }
    };

    const colors = getColorByCategory(category_id)


    const setReponseStyle = (responseIndex, style) => {
        switch (responseIndex) {
            case 1:
                setReponse1Style(style);
                break;
            case 2:
                setReponse2Style(style);
                break;
            case 3:
                setReponse3Style(style);
                break;
            case 4:
                setReponse4Style(style);
                break;
            default:
                break;
        }
    };

    const handleReponseExist = (reponsesExist) => {
        const reponses = [];

        reponsesExist.forEach((reponse, index) => {
            if (reponse == '1') {
                reponses.push(index + 1);

                if (question[`bonne_reponse${index + 1}`] == '1') {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.green, borderWidth: 3, borderColor: COLORS.black });
                } else {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.red, borderWidth: 3, borderColor: COLORS.black });
                }
            } else {
                if (question[`bonne_reponse${index + 1}`] == '1') {
                    setReponseStyle(index + 1, { backgroundColor: COLORS.green });
                } else {
                    setReponseStyle(index + 1, {});
                }
            }
        });

        return reponses;
    };


    const stylesCategory = StyleSheet.create({
        categoryContainer: {
            backgroundColor: colors.backgroundColor,
            marginBottom: 5,
        },
        containerCarte: {
            flex: 1,
            position: "absolute",
            width: '100%',
            height: '100%'
        },
        carte: {
            flex: 1,
            // height: '100%',
            width: '100%',
            resizeMode: 'stretch'
        },
        containerPlayers: {
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        currentPlayerText:{
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            fontWeight: FONTS.bold,
            borderColor: colors.backgroundColor,
            borderWidth: 2,
            paddingVertical: 2,
            paddingHorizontal: 10,
            borderRadius: ROUNDED.lg,
            textAlign: "center"
        },
        playerText: {
            fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
            fontWeight: FONTS.semibold,
            borderColor: COLORS.white,
            borderWidth: 2,
            paddingVertical: 2,
            paddingHorizontal: 10,
            borderRadius: ROUNDED.lg,
            textAlign: "center"
        },
        categoryText: {
            fontSize: helper.isLittleScreen() ? SIZES.lg: SIZES.xl,
            fontWeight: FONTS.semibold,
            color: COLORS.white,
            textAlign: "center",
            position: "relative",
        },
        containerAvatar:{
            flex: 1,
            flexDirection: 'row',
            justifyContent: "center",
            height: 30,
            marginTop: helper.isLittleScreen() ? 0 : 5
        },
        boxAvatar: {
            borderColor: colors.backgroundColor,
            borderWidth: 3,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: ROUNDED.full,
            aspectRatio: 1,
            overflow: "hidden",
            width: helper.isLittleScreen() ? 65 : 80,
            height: helper.isLittleScreen() ? 65 : 80,
        },
        image: {
            width: '80%',
            height: '80%',
            resizeMode: "cover"
        }
    })

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <SafeAreaView style={styles.modalContainer}>
                <View>
                        { modeDuel ?
                            (
                                <View>
                                    <View style={[stylesCategory.categoryContainer, {marginBottom: 15, height: helper.isLittleScreen() ? 40 : 60}]}>
                                        <View style={stylesCategory.containerCarte}>
                                            <Image style={stylesCategory.carte} source={bandeauImage}/>
                                        </View>

                                        <View style={{paddingHorizontal: 10, paddingTop: helper.isLittleScreen() ? 8 : (Platform.OS === 'ios' ? 25 : 16)}}>
                                            <Text style={stylesCategory.categoryText}>DUEL !</Text>
                                        </View>
                                    </View>
                                    <View style={stylesCategory.containerPlayers}>
                                        <Text style={currentPlayer == player1 ? stylesCategory.currentPlayerText :  stylesCategory.playerText}>{player1.pseudo}</Text>
                                        <Text style={{marginHorizontal: 50, fontWeight: FONTS.bold}}>VS</Text>
                                        <Text style={currentPlayer == player2 ? stylesCategory.currentPlayerText :  stylesCategory.playerText}>{player2.pseudo}</Text>
                                    </View>
                                    {/*<Text style={styles.joueurText}>{currentPlayer === player1 ? player1 : player2}</Text>*/}
                                </View>
                            ) :
                            (
                                <View style={stylesCategory.categoryContainer}>
                                    <View style={stylesCategory.containerCarte}>
                                        <Image style={stylesCategory.carte} source={bandeauImage}/>
                                    </View>
                                    <View style={{paddingHorizontal: 10, paddingTop: helper.isLittleScreen() ? 10 : 30}}>
                                        <Text style={stylesCategory.categoryText}>{question.category_name}</Text>
                                        <View style={stylesCategory.containerAvatar}>
                                            <View style={stylesCategory.boxAvatar}>
                                                <Image source={avatars[currentJoueur?.avatar]} style={styles.image} resizeMode="cover" />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            )
                        }

                    <View style={styles.questionContainer}>
                        {!modeDuel ? (
                                <Text style={styles.joueurText}>{currentJoueur.pseudo}</Text>
                            ) : (<Text></Text>)
                        }
                        <Text style={styles.questionText}>{removeHtmlTags(he.decode(question.question))}</Text>

                        <TouchableOpacity
                            style={[
                                styles.responseButton,
                                selectedResponses.includes(1) && styles.responseButtonSelected,
                                reponse1Style
                            ]}
                            onPress={() => toggleAnswerSelection(1)}
                        >
                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition1))}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[
                                styles.responseButton,
                                selectedResponses.includes(2) && styles.responseButtonSelected,
                                reponse2Style
                            ]}
                            onPress={() => toggleAnswerSelection(2)}
                        >

                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition2))}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[
                                styles.responseButton,
                                selectedResponses.includes(3) && styles.responseButtonSelected,
                                reponse3Style
                            ]}
                            onPress={() => toggleAnswerSelection(3)}
                        >
                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition3))}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[
                                styles.responseButton,
                                selectedResponses.includes(4) && styles.responseButtonSelected,
                                reponse4Style
                            ]}
                            onPress={() => toggleAnswerSelection(4)}
                        >
                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(question.proposition4))}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>

                <View>
                    <Text style={[{fontSize: helper.isLittleScreen() ? SIZES["xl"] : SIZES["2xl"], fontWeight: FONTS.bold, textAlign: "center"} ,styleValidation]}>{textValidation}</Text>
                    {winner !== null ? (
                        <View>
                            {winner === player2 ? (
                                <Text style={{fontSize: SIZES.xl, textAlign: "center"}}>
                                    <Text style={{fontWeight: FONTS.bold}}>{player2?.pseudo} </Text>
                                    prend la place de
                                    <Text style={{fontWeight: FONTS.bold}}> {player1?.pseudo}</Text>
                                </Text>
                            ) : (
                                <Text style={{fontSize: SIZES.xl, textAlign: "center"}}>
                                    Raté
                                    <Text style={{fontWeight: FONTS.bold}}> {player2?.pseudo}</Text>
                                    <Text>, </Text>
                                    <Text style={{fontWeight: FONTS.bold}}>{player1?.pseudo}</Text>
                                    <Text> reste à sa place</Text>
                                </Text>
                            )}

                        </View>
                    ) : (<Text></Text>)}
                    <View style={{width: '100%', alignItems: "center"}}>
                        <View style={styles.bottomButtonContainer}>
                            {isLoading && <ActivityIndicator />}
                            {!validerClicked && (
                                <CustomButton title="Valider" onPress={ () => {
                                    setIsLoading(true);
                                    handleValidModal();
                                }} />
                            )}
                            {canGoNext && (
                                <CustomButton title="Suivant"
                                    onPress={ () => {
                                        handleNextQuestion();
                                    }}
                                />
                            )}

                            {(!modeDuel && validerClicked) || winner !== null ? (
                                <CustomButton title="Fermer" onPress={handleCloseModal} />
                            ) : (<Text></Text>)}
                        </View>
                    </View>
                </View>
            </SafeAreaView>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        flexDirection: "column",
        justifyContent: 'space-between',
        elevation: 5,
        paddingBottom: 30
    },
    questionContainer: {
        padding: 20,
        flexDirection: "column",
    },
    joueurText: {
        fontSize: helper.isLittleScreen() ? SIZES.lg : SIZES.xl,
        fontWeight: FONTS.bold,
        textAlign: 'center',
        marginTop: helper.isLittleScreen() ? 15 : 25,
        color: COLORS.black,
    },
    questionText: {
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.semibold,
        textAlign: 'center',
        marginTop: helper.isLittleScreen() ? 5 : 10,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        color: COLORS.black,
    },
    responseButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        width: '100%',
        paddingHorizontal: helper.isLittleScreen() ? 8 : 10,
        paddingVertical: helper.isLittleScreen() ? 10 : 15,
        marginVertical: 5,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    responseButtonSelected: {
        backgroundColor: COLORS.primary,
        borderWidth: 1,
        borderColor: COLORS.primary,
    },
    responseButtonText: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        color: COLORS.black,
        fontWeight: FONTS.semibold,
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
    },
    bottomButtonContainer: {
        alignItems: 'center',
        justifyContent: "center",
        width: '100%',
        maxWidth: 400,
        paddingHorizontal: 20,
        marginVertical: 10,
    },
    iconContainer: {
        backgroundColor: COLORS.white,
        borderRadius: 100,
        marginRight: 3,
        aspectRatio: 1,
        width: 30,
        alignItems: "center",
        justifyContent: "center"
    }
});

export default QuestionPlateauModal;
