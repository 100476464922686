import {API_URL} from '../constants/api';
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
const NotificationService = {
    getAll: async () => {
        // console.log('start getPublicKey')
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/notification/all`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            console.log(error);
            // console.error('Error checking login:', error);
        }
    },
    getPublicKey: async () => {
        // console.log('start getPublicKey')
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.get(`${API_URL}/notification/key`,{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error checking login:', error);
        }
    },
    saveSubscription: async (subscription) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const response = await axios.post(`${API_URL}/notification/subscribe`, {subscription: subscription},{
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            return response.data;
        } catch (error) {
            // console.error('Error saveSubscription:', error);
        }
    },
}

export default NotificationService;
