import React, {useEffect, useState} from 'react';
import {TouchableOpacity, Text, StyleSheet, View, Modal, Image, SafeAreaView, ScrollView} from 'react-native';
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useNavigation, CommonActions  } from '@react-navigation/native';
import CustomButton from './CustomButton';
import {getAvatars} from '../utils/helpers';
import PlateauService from "../../src/services/PlateauService";
import * as helper from "../utils/helpers";
import ErrorNotification from "./ErrorNotification";


function HeaderPlateau({joueurs, currentJoueur, audios, handleMutedAudio}){
    const [error, setError] = useState(null);
    const [modalExitVisible, setModalExitVisible] = useState(false)
    const [modalWinnerVisible, setModalWinnerVisible] = useState(false)
    const [modalFinalVisible, setModalFinalVisible] = useState(false)
    const [modalParamVisible, setModalParamVisible] = useState(false)
    const [winnerSelected, setWinnerSelected] = useState(null)
    const [results, setResults] = useState(null);

    const imageUrls = getAvatars()
    const navigation = useNavigation();


    const handleToggleAudio = (type) => {
        let toggle = true

        switch (type) {
            case 'isMusiqueMute':
                toggle = !audios.toggleMusic
                break;
            case 'isEffectMute':
                toggle = !audios.toggleSoundEffect
                break;
            case 'isVoiceMute':
                toggle = !audios.toggleSoundVoice
                break;
        }

        handleMutedAudio(type, toggle);
    }

    const HandleExit =  () => {
        try {
            // console.log('start');
            navigation.reset({
                index: 0,
                routes: [{ name: 'Plateau' }],
            });
        } catch (error) {
            setError({code: 4012})
            // console.error('An error occurred:', error);
        }
    };


    const HandleWinner = async () => {
        setWinnerSelected(currentJoueur)

        try {
            const resultsData = await PlateauService.endGame(currentJoueur.id);
            setResults(resultsData);

            setModalExitVisible(false)
            setModalWinnerVisible(false);
            setModalFinalVisible(true)
        } catch (error) {
            setError({code: 4011})
        }

    }

    const HandleFinal = () => {
        setModalExitVisible(false);
        setModalWinnerVisible(false);
        setModalFinalVisible(false);


        navigation.dispatch(
            CommonActions.reset({
                index: 0,
                routes: [{ name: 'Plateau' }],
            })
        );
    }

    const handleCloseModal = () => {
        setModalExitVisible(false);
        setModalWinnerVisible(false);
        setModalParamVisible(false);
    }


    const handleOpenModalExit = () => {
        setModalExitVisible(true)
    }


    const handleOpenModalParam = () => {
        setModalParamVisible(true)
    }

    const handleOpenModalWinner = () => {
        setModalWinnerVisible(true)
    }


    return (
        <View style={styles.container}>
            <View style={styles.containerButtons}>
                <TouchableOpacity style={styles.btnExit} onPress={handleOpenModalParam}>
                    <FontAwesome5 name="cog" color={COLORS.black} size={25} />
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnWinner} onPress={handleOpenModalWinner}>
                    <Text style={{fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm}}>Vainqueur</Text>
                    <FontAwesome name="trophy" size={25} style={{marginLeft: 5}} />
                </TouchableOpacity>
            </View>
            <View style={styles.containerAvatar}>
                <View style={styles.boxAvatar}>
                    <Image source={imageUrls[currentJoueur?.avatar]} style={styles.image} resizeMode="cover" />
                </View>
            </View>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalParamVisible}
            >
                <View style={styles.containerModalExit}>
                    <View style={[styles.boxModalExit]}>
                        <View style={{flexDirection: "row", justifyContent: "flex-end", width: '100%', marginTop: -10, marginBottom: 20}}>
                            <View style={{alignItems: 'center'}}>
                                <Text style={{fontSize: SIZES.xs}}>Effet</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isEffectMute')}>
                                    <FontAwesome5 name={audios?.toggleSoundEffect ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                            <View style={{alignItems: 'center', marginHorizontal: 3}}>
                                <Text style={{fontSize: SIZES.xs}}>Voix</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isVoiceMute')}>
                                    <FontAwesome5 name={audios?.toggleSoundVoice ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                            <View style={{alignItems: 'center'}}>
                                <Text style={{fontSize: SIZES.xs}}>Musique</Text>
                                <TouchableOpacity style={styles.btnMusic} onPress={() => handleToggleAudio('isMusiqueMute')}>
                                    <FontAwesome5 name={audios?.toggleMusic ? "volume-up" : "volume-mute"} size={20} />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={{alignItems: 'center'}}>
                            <FontAwesome5 name="pause" size={35} color={COLORS.black} />
                            <Text style={styles.TextModalPause}>PAUSE</Text>
                        </View>
                        <View style={styles.containerButtonsModelExit}>
                            <CustomButton
                                title={'Reprendre'}
                                backgroundColor={COLORS.primary}
                                borderColor={COLORS.primary}
                                shadowColor={COLORS.darkPrimary}
                                onPress={handleCloseModal}
                            />
                            <CustomButton
                                title={'Quitter'}
                                backgroundColor={COLORS.red}
                                borderColor={COLORS.red}
                                shadowColor={COLORS.darkRed}
                                onPress={HandleExit}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalExitVisible}
            >
                <View style={styles.containerModalExit}>
                    <View style={styles.boxModalExit}>
                        <Text style={styles.TextModalExit}>Êtes-vous sûr de vouloir quitter la partie ?</Text>
                        <View style={styles.containerButtonsModelExit}>
                            <CustomButton
                                title={'Quitter'}
                                backgroundColor={COLORS.red}
                                borderColor={COLORS.red}
                                shadowColor={COLORS.darkRed}
                                onPress={HandleExit}
                            />
                            <CustomButton
                                title={'Annuler'}
                                onPress={handleCloseModal}
                                backgroundColor={COLORS.gray}
                                borderColor={COLORS.lightGray}
                                shadowColor={COLORS.darkerGray}
                                textColor={COLORS.darkerGray}
                            />
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalWinnerVisible}
            >
                <View style={styles.containerModalExit}>
                    <View style={[styles.boxModalExit, styles.boxModalWinner]}>
                        <View style={{width: "100%"}}>
                            <Text style={styles.TextModalExit}>Confirmez vous le choix du gagnant ?</Text>
                            <View style={{justifyContent: "center", flexDirection: "row", width: "100%", marginVertical: 10}}>
                                <View style={styles.containerAvatar2}>
                                    <View style={[styles.boxAvatar]}>
                                        <Image source={imageUrls[currentJoueur?.avatar]} style={styles.image} resizeMode="cover" />
                                    </View>
                                </View>
                            </View>
                            <Text style={{fontWeight: FONTS.bold, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES["xl"] : SIZES["3xl"], marginBottom: 10}}>{currentJoueur?.pseudo ?? ''}</Text>

                        </View>
                        <View style={styles.containerButtonsModelExit}>
                            <CustomButton
                                title={'Désigner le gagnant'}
                                onPress={HandleWinner}
                            />
                            <CustomButton
                                title={'Annuler'}
                                onPress={handleCloseModal}
                                backgroundColor={COLORS.gray}
                                borderColor={COLORS.lightGray}
                                shadowColor={COLORS.darkerGray}
                                textColor={COLORS.darkerGray}
                            />
                        </View>
                    </View>
                </View>
            </Modal>
            <Modal visible={modalFinalVisible}>
                <SafeAreaView style={{flex: 1}}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                        <View style={{flexDirection: "column", justifyContent: "space-between", padding: 30, height: "100%"}}>
                            <View>
                                <View style={{alignItems: 'center',}}>
                                    <MaterialCommunityIcons name="party-popper" size={helper.isLittleScreen() ? 30 : 36} color="black" />
                                </View>
                                <Text style={[styles.TextModalExit, {fontWeight: FONTS.bold, fontSize: helper.isLittleScreen() ? SIZES["2xl"] : SIZES["3xl"]}]}>Félicitations</Text>

                                <View style={{alignItems: 'center'}}>
                                    <FontAwesome5 style={{ marginTop: helper.isLittleScreen() ? 6 : 10 }} name="crown" size={helper.isLittleScreen() ? 35 : 50} />
                                </View>
                                <View style={[styles.containerAvatar, {position: 'relative', margin: 0, marginTop: 5, marginBottom: 5, padding: 0, top: 0}]}>
                                    <View style={[styles.boxAvatar, {width: helper.isLittleScreen() ? 65 : 90, borderColor: COLORS.black, margin: 0}]}>
                                        <Image source={imageUrls[currentJoueur?.avatar]} style={styles.image} resizeMode="cover" />
                                    </View>
                                </View>

                                <Text style={{fontWeight: FONTS.bold, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES["3xl"] : SIZES["4xl"], marginBottom: 5}}>{currentJoueur?.pseudo ?? ''}</Text>
                                {results && (
                                    <View>
                                        <Text style={{fontWeight: FONTS.bold, fontSize: helper.isLittleScreen() ? SIZES["lg"] : SIZES["2xl"], textAlign: 'center', marginBottom: 20}}>
                                            Réponses : {results[winnerSelected?.id]?.bonne_reponse ?? 0} / {results[winnerSelected?.id]?.total ?? 0}
                                        </Text>
                                    </View>
                                )}

                                {results && (
                                    <View style={{width: '100%', alignItems: "center"}}>
                                        <View style={{width: '100%', maxWidth: 400}}>
                                            {joueurs.map((joueur) => {
                                                if (joueur.id !== winnerSelected?.id) {
                                                    return (
                                                        <View key={joueur.id} style={{ flexDirection: 'row', justifyContent: 'space-between',  borderBottomWidth: 1, borderBottomColor: COLORS.gray}}>
                                                            <Text style={{fontWeight: FONTS.semibold, fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base}}>{joueur.pseudo}</Text>
                                                            <Text style={{fontWeight: FONTS.semibold, fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm}}>
                                                                Réponses correctes : {results[joueur.id]?.bonne_reponse ?? 0}/{results[joueur.id]?.total ?? 0}
                                                            </Text>
                                                        </View>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </View>
                                    </View>

                                )}

                            </View>
                            <View style={{width: '100%', alignItems: "center"}}>
                                <View style={{width: '100%', maxWidth: 400}}>
                                    {/*<CustomButton*/}
                                    {/*    title={'Partager'}*/}
                                    {/*    onPress={handleCloseModal}*/}
                                    {/*    backgroundColor={COLORS.blue}*/}
                                    {/*    borderColor={COLORS.blue}*/}
                                    {/*    shadowColor={COLORS.darkBlue}*/}
                                    {/*    textColor={COLORS.white}*/}
                                    {/*/>*/}
                                    <CustomButton
                                        title={'Terminer'}
                                        onPress={HandleFinal}
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>

                </SafeAreaView>
            </Modal>
            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        position: "relative",
        backgroundColor: COLORS.darkGray,
        flexDirection: "column",
        justifyContent: "center",
        paddingBottom: helper.isLittleScreen() ? 3 : 10,
        width: '100%'
    },
    containerModalExit: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "rgba(0,0,0,0.2)"
    },
    boxModalExit: {
        maxWidth: 400,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 20,
        paddingHorizontal: 15,
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.xl,
        width: '75%',
        height: helper.isLittleScreen() ? '60%' : '40%'
    },
    boxModalWinner: {
        width: '75%',
        height: helper.isLittleScreen() ? '60%' : '48%'
    },
    TextModalExit: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES["lg"] : SIZES["2xl"],
        fontWeight: FONTS.semibold,
        textAlign: "center",
        marginTop: 5
    },
    TextModalPause: {
        color: COLORS.black,
        fontSize: SIZES["3xl"],
        fontWeight: FONTS.black,
        textAlign: "center",
    },
    containerButtonsModelExit: {
        flexDirection: "column",
        width: "100%"
    },
    containerButtons: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: helper.isLittleScreen() ? 8 : 15,
        paddingHorizontal: 15,
        zIndex: 10
    },
    btnExit: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.full,
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center"
    },
    btnMusic: {
        backgroundColor: COLORS.darkGray,
        borderRadius: ROUNDED.full,
        width: 45,
        height: 45,
        justifyContent: "center",
        alignItems: "center"
    },
    btnWinner: {
        backgroundColor: COLORS.green,
        borderRadius: ROUNDED.lg,
        paddingHorizontal: 5,
        paddingVertical: 3,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    containerAvatar: {
        justifyContent: "center",
        flexDirection: "row",
        position: "absolute",
        left: 0,
        width: '100%',
        top: helper.isLittleScreen() ? 20 : 40
    },
    containerAvatar2: {
        justifyContent: "center",
        flexDirection: "row",
        width: '100%',
    },
    boxAvatar: {
        backgroundColor: COLORS.white,
        borderWidth: 3,
        borderColor: COLORS.darkGray,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ROUNDED.full,
        aspectRatio: 1,
        overflow: "hidden",
        width: helper.isLittleScreen() ? 70 : 80,
        height: helper.isLittleScreen() ? 70 : 80,
    },
    image: {
        width: '100%',
        height: '100%',
        resizeMode: "cover"
    }
})

export default HeaderPlateau;
