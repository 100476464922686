import React, { useState, useEffect } from 'react';
import {View, Image, StyleSheet, Text, SafeAreaView, Linking, ActivityIndicator} from 'react-native';
import PlateauService from '../../services/PlateauService';
import {COLORS, FONTS, ROUNDED, SIZES} from "../../constants/theme";
import CustomButton from "../../components/CustomButton";
import * as helper from "../../utils/helpers";
import ErrorNotification from "../../components/ErrorNotification";
import UserService from "../../services/UserService";


function Plateau({ navigation }) {
    const [loadingInitial, setLoadingInitial] = useState(true);
    const imageSource = helper.isTabletteScreen()
        ? require('../../../assets/general/Fond_Plateau_tablette.png')
        : require('../../../assets/general/Fond_Plateau.png');
    const [error, setError] = useState(null);
    const [currentGame, setCurrentGame] = useState(null)
    const [canOpenCurrentGame, setCanOpenCurrentGame] = useState(false)
    const [mobileAccess, setMobileAccess] = useState(false);

    const handleCreateGame = () => {
        navigation.navigate('NombreJoueurs');
    };
    const handleOpenGame = () => {
        navigation.navigate('Partie');
    };
    const handleQuit = () => {
        navigation.navigate('Accueil');
    };
    useEffect(() => {
        const checkMobileAccess = async () => {
            const checkAccess = await UserService.check_plateau_access();
            if (checkAccess) {
                setMobileAccess(checkAccess.has_access);
            }
            setLoadingInitial(false); // Mettre fin au chargement initial
        };

        checkMobileAccess();
    }, []);

    useEffect(() => {
        const getCurrentGame = async () => {
            try {
                const game = await PlateauService.getCurrentGame();
                setCanOpenCurrentGame(game && game.id);

                if(canOpenCurrentGame){
                    setCurrentGame(game)
                }
            } catch (error) {
                setError({code: 4008})
            }
        };

        getCurrentGame();
    }, []);

    return (
        <SafeAreaView style={{flex: 1}}>
            { loadingInitial ? (
                <View style={styles.container}>
                    <Image style={styles.image} source={imageSource}/>
                    <ActivityIndicator animating={true} size="large" color={COLORS.primary} style={styles.loadingIndicator} />
                </View>
            ) : (
                <View style={styles.container}>
                    <Image style={styles.image} source={imageSource}/>
                    <Text style={styles.title}>Mode Plateau</Text>
                    <View style={{width: '100%',  padding: 30, alignItems: "center", justifyContent: "center"}}>
                        { !mobileAccess ? (
                            <View style={{width: '500px', height: '300px',  padding: 30, borderRadius: ROUNDED.lg, backgroundColor: 'white', justifyContent: 'space-between'}}>
                                <View>
                                    <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.bold, marginBottom: '15px'}}>Abonnement requis au mode plateau</Text>
                                    <Text>Vous devez être abonné au mode plateau pour accéder à cette fonctionnalité. Veuillez vous abonner pour profiter pleinement de cette expérience.</Text>
                                </View>

                                <View>
                                    <CustomButton title="S'abonner" onPress={() =>
                                        Linking.openURL('https://objectifofficiersp.com/boutique')}
                                    />
                                    <CustomButton
                                        title="Quitter"
                                        onPress={handleQuit}
                                        backgroundColor={COLORS.gray}
                                        borderColor={COLORS.lightGray}
                                        shadowColor={COLORS.darkerGray}
                                        textColor={COLORS.darkerGray}
                                    />
                                </View>

                            </View>
                        ) : (
                            <View style={styles.box}>
                                <CustomButton title="Nouvelle partie" onPress={handleCreateGame}  />
                                {canOpenCurrentGame &&
                                    <CustomButton
                                        title="Reprendre une partie"
                                        textColor={COLORS.white}
                                        borderColor={COLORS.lightBlue}
                                        backgroundColor={COLORS.blue}
                                        shadowColor={COLORS.darkBlue}
                                        onPress={handleOpenGame}
                                    />
                                }
                                <CustomButton
                                    title="Quitter"
                                    onPress={handleQuit}
                                    backgroundColor={COLORS.gray}
                                    borderColor={COLORS.lightGray}
                                    shadowColor={COLORS.darkerGray}
                                    textColor={COLORS.darkerGray}
                                />
                            </View>
                        )}

                    </View>
                    <ErrorNotification
                        isVisible={error !== null}
                        code={error?.code}
                        message={error?.message}
                        onClose={() => setError(null)}
                    />
                </View>
            )}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative"
    },
    title: {
        fontSize: helper.isLittleScreen() ? SIZES["3xl"] : SIZES["4xl"],
        fontWeight: FONTS.black,
        color: COLORS.white
    },
    box: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: "rgba(229, 231, 235,0.5)",
        paddingHorizontal: 20,
        paddingVertical: helper.isLittleScreen() ? 30 : 50, borderRadius: ROUNDED.lg
    },
    image: {
        position: "absolute",
        width: '100%',
        height: '100%',
        resizeMode: "cover"
    },
    button: {
        marginBottom: 20,
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderWidth: 1,
        borderRadius: 10,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%'
    },
    buttonReprendre: {
        marginBottom: 0,
        backgroundColor: COLORS.white,
        borderColor: COLORS.primary,
    },
    buttonQuitter: {
        marginBottom: 0,
        backgroundColor: COLORS.darkGray,
        borderColor: COLORS.darkGray,
    },
    buttonTextReprendre: {
        color: COLORS.darkPrimary,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    buttonText: {
        color: COLORS.black,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
});

export default Plateau;
