import {
    Image,
    Modal,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {COLORS, FONTS, ROUNDED, SIZES} from "../constants/theme";
import {ROOT_URL} from "../constants/api";
import CustomButton from "./CustomButton";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import * as helper from "../utils/helpers";
import React, {useEffect, useState} from "react";
import UserService from "../services/UserService";
import ProfileModal from "./ProfileModal";
import ErrorNotification from "./ErrorNotification";


function ModalSearchProfile({ onClose, friends = null }) {
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [selectedFriendId, setSelectedFriendId] = useState(null);

    const handleClose = () => {
        setSearchQuery('')
        setSearchResults([])
        setIsLoading(false)
        onClose()
    }


    const handleOpenModalProfil = (user_id) => {
        setSelectedFriendId(user_id)
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setModalProfileVisible(false)
    }

    const handleSearch = async () => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
            return;
        }

        fetchUsersData()
    };

    const fetchUsersData = async () => {
        try {
            setIsLoading(true);
            const profilData = await UserService.searchUsers(searchQuery.trim())

            if(profilData){
                setSearchResults(profilData)
            }
            else{
                setError({code: '0005'})
            }
            setIsLoading(false);

        } catch (error) {
            setError({code: '0006'})
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
        }
    }, [searchQuery]);

    return (
        <SafeAreaView style={{flex: 1}}>
            <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginHorizontal: 30, marginTop: 5}}>
                <TouchableOpacity onPress={handleClose} style={{paddingVertical: 12, paddingLeft: 5, paddingRight: 15}}>
                    <FontAwesome5 name="arrow-left" size={25} />
                </TouchableOpacity>
                <Text style={{fontSize: SIZES.xl, fontWeight: FONTS.bold, color: COLORS.black1}}>Rechercher des amis</Text>
                <View></View>
            </View>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                <View style={styles.container}>
                    <View style={{flex: 1, alignItems: "center"}}>
                        <View style={{width: "100%", maxWidth: 400}}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', borderWidth: 1, backgroundColor: COLORS.gray, borderRadius: ROUNDED.lg, borderColor: COLORS.darkGray, height: 50, marginBottom: 20 }}>
                                <FontAwesome5 name="search" size={18} style={{ marginHorizontal: 10, color: COLORS.darkerGray }} />
                                <TextInput
                                    placeholder="Pseudo utilisateur..."
                                    placeholderTextColor={COLORS.darkerGray}
                                    value={searchQuery}
                                    onChangeText={setSearchQuery}
                                    onSubmitEditing={handleSearch}
                                    style={{ flex: 1, paddingHorizontal: 10 }}
                                />
                            </View>
                            {isLoading ? (
                                <ActivityIndicator size="small" color={COLORS.primary} />
                            ) : (
                                <View>
                                    {searchResults.map((item, index) => (
                                        <View key={index}>
                                            <View style={styles.containerFriends}>
                                                <View style={styles.boxInfoFriend}>
                                                    <View style={styles.containerImageProfil}>
                                                        <View style={[styles.avatarContainer]}>
                                                            {item.user_avatar && (
                                                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${item.user_avatar.replace('.svg', '.png')}`}}/>
                                                            )}
                                                        </View>
                                                        {item.rank && item.rank.split(',')[0] && (
                                                            <Image style={{width: '100%', height: '100%', resizeMode: "contain", position: "absolute"}} source={helper.getRankById(parseInt(item.rank.split(',')[0]))}/>
                                                        )}
                                                    </View>
                                                    <View>
                                                        <Text style={styles.friendName}>{item.user_name}</Text>
                                                        <Text style={[styles.friendRank]}>{item.rank ? item.rank.split(',')[1] : ''} {item.user_points} pts</Text>
                                                    </View>
                                                </View>
                                                <TouchableOpacity style={styles.buttonProfil} onPress={() => handleOpenModalProfil(item.user_id)}>
                                                    <Text style={{fontSize: SIZES.xs, textAlign: "center", fontWeight: FONTS.semibold, color: COLORS.black}}>Voir</Text>
                                                </TouchableOpacity>
                                            </View>
                                            {index < searchResults.length - 1 && <View style={styles.separator} />}
                                        </View>
                                    ))}
                                </View>
                            )}
                        </View>
                    </View>

                    <View style={styles.containerBottom}>
                        <View style={styles.bottom}>
                            <CustomButton
                                onPress={handleClose}
                                title={'Fermer'}
                                backgroundColor={COLORS.gray}
                                borderColor={COLORS.gray}
                                shadowColor={COLORS.darkerGray}
                                textColor={COLORS.darkerGray}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>


            <Modal visible={modalProfileVisible}>
                <ProfileModal onClose={handleCloseProfil} user_id={selectedFriendId} />
            </Modal>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 30,
        justifyContent: "space-between",
        marginTop: 20
    },
    containerTop: {
        alignItems: "center",
    },
    containerAvatar: {
        width: 120,
        height: 120,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: COLORS.gray,
        overflow: "hidden",
        marginBottom: helper.isLittleScreen() ? 5 : 10,
        justifyContent: "center",
        alignItems: "center",
    },
    containerBottom: {
        width: "100%",
        alignItems: "center"
    },
    bottom: {
        width: "100%",
        maxWidth: 400
    },
    profilName: {
        fontSize: SIZES['lg'],
        color: COLORS.darkerGray,
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 4 : 6
    },
    profilPoints: {
        fontSize: SIZES['xl'],
        color: COLORS.darkerGray,
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 15
    },
    containerButtons: {
        width: "100%",
        maxWidth: 400,
        alignItems: "center"
    },
    boxButtons: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    containerStats: {
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        marginTop: helper.isLittleScreen() ? 10 : 15,
    },
    boxStats: {
        alignItems: "center",
        marginHorizontal: 20
    },
    titleStats: {
        fontSize: helper.isLittleScreen() ? SIZES['xs'] : SIZES['sm'],
        fontWeight: FONTS.semibold,
        marginBottom: helper.isLittleScreen() ? 5 : 8,
        color: COLORS.darkerGray
    },
    containerRang: {
        width: 58,
        height: 58,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        borderWidth: 1,
        borderColor: COLORS.gray,
        marginBottom: helper.isLittleScreen() ? 3 : 4,
    },
    titleRank: {
        fontSize: helper.isLittleScreen() ? SIZES['sm'] : SIZES['base'],
        color: COLORS.black1,
        fontWeight: FONTS.semibold
    },
    titlePosition: {
        fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['4xl'],
        color: COLORS.black1,
        fontWeight: FONTS.bold,
    },
    containerFriends: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    boxInfoFriend: {
        flexDirection: "row",
        alignItems: "center",
    },
    friendName:{
        fontSize: SIZES.sm,
        fontWeight: FONTS.semibold,
    },
    friendRank:{
        fontSize: SIZES.xs,
        color: COLORS.darkerGray
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.gray,
        marginVertical: helper.isLittleScreen() ? 15 : 20,
        width: "100%"
    },
    containerImageProfil: {
        position: "relative",
        flexDirection: "row",
        width: helper.isLittleScreen() ? 40 : 50,
        height: helper.isLittleScreen() ? 40 : 50,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    avatarContainer: {
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 30 : 40,
        height: helper.isLittleScreen() ? 30 : 40,
        aspectRatio: 1,
        backgroundColor: COLORS.darkGray,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    },
    buttonProfil: {
        paddingVertical: 6,
        borderRadius: ROUNDED.md,
        width: 70,
        borderWidth: 1,
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
});

export default ModalSearchProfile;
