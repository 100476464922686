import React, { useState, useEffect  } from 'react';
import {
    Text,
    View,
    StyleSheet,
    Modal,
    ActivityIndicator,
    Image,
    ScrollView,
    SafeAreaView
} from 'react-native';
import { COLORS, FONTS, SIZES , ROUNDED } from '../constants/theme';
import * as helper from "../utils/helpers";
import CustomButton from "./CustomButton";
import {ROOT_URL} from "../constants/api";
import he from "he";

function ResultDefiDetailModal({defi, handleCloseModal}) {

    const renderQuestion = (question, index) => {
        const resultUser = question.user_answer
        const resultOpponent = question.defi_user_answer



        const userSelections = resultUser ? resultUser.split(',').map(Number) : [0,0,0,0];
        const opponentSelections = resultOpponent ? resultOpponent.split(',').map(Number) : [0,0,0,0];

        const userAvatar = defi.user_avatar;
        const opponentAvatar = defi.opponent_avatar;

        return (
            <View key={index} style={styles.containerQuestion}>
                <Text style={styles.question}>{index + 1}) {helper.removeHtmlTags(he.decode(question.question.question))}</Text>

                <View style={[styles.proposition, {backgroundColor: question.question.bonne_reponse1 === 1 ? COLORS.green : COLORS.white}]}>
                    { userSelections[0] === 1 && (
                        <View style={styles.boxAvatar}>
                            {userAvatar && (
                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${userAvatar.replace('.svg', '.png')}`}}/>
                            )}
                        </View>
                    )}
                    <Text>{helper.removeHtmlTags(he.decode(question.question.proposition1))}</Text>
                    { opponentSelections[0] === 1 && (
                        <View style={styles.boxAvatarOpponent}>
                            { defi.is_bot === 1 ? (
                                <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={require('../../assets/general/Robot.png')} />
                            ) : (
                                (opponentAvatar) && (
                                    <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${opponentAvatar.replace('.svg', '.png')}`}}/>
                                )
                            )}
                        </View>
                    )}
                </View>
                <View style={[styles.proposition, {backgroundColor: question.question.bonne_reponse2 === 1 ? COLORS.green : COLORS.white}]}>
                    { userSelections[1] === 1 && (
                        <View style={styles.boxAvatar}>
                            {userAvatar && (
                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${userAvatar.replace('.svg', '.png')}`}}/>
                            )}
                        </View>
                    )}
                    <Text>{helper.removeHtmlTags(he.decode(question.question.proposition2))}</Text>
                    { opponentSelections[1] === 1 && (
                        <View style={styles.boxAvatarOpponent}>
                            { defi.is_bot === 1 ? (
                                <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={require('../../assets/general/Robot.png')} />
                            ) : (
                                (opponentAvatar) && (
                                    <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${opponentAvatar.replace('.svg', '.png')}`}}/>
                                )
                            )}
                        </View>
                    )}
                </View>
                <View style={[styles.proposition, {backgroundColor: question.question.bonne_reponse3 === 1 ? COLORS.green : COLORS.white}]}>
                    { userSelections[2] === 1 && (
                        <View style={styles.boxAvatar}>
                            {userAvatar && (
                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${userAvatar.replace('.svg', '.png')}`}}/>
                            )}
                        </View>
                    )}
                    <Text>{helper.removeHtmlTags(he.decode(question.question.proposition3))}</Text>
                    { opponentSelections[2] === 1 && (
                        <View style={styles.boxAvatarOpponent}>
                            { defi.is_bot === 1 ? (
                                <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={require('../../assets/general/Robot.png')} />
                            ) : (
                                (opponentAvatar) && (
                                    <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${opponentAvatar.replace('.svg', '.png')}`}}/>
                                )
                            )}
                        </View>
                    )}
                </View>
                <View style={[styles.proposition, {backgroundColor: question.question.bonne_reponse4 === 1 ? COLORS.green : COLORS.white}]}>
                    { userSelections[3] === 1 && (
                        <View style={styles.boxAvatar}>
                            {userAvatar && (
                                <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${userAvatar.replace('.svg', '.png')}`}}/>
                            )}
                        </View>
                    )}
                    <Text>{helper.removeHtmlTags(he.decode(question.question.proposition4))}</Text>
                    { opponentSelections[3] === 1 && (
                        <View style={styles.boxAvatarOpponent}>
                            { defi.is_bot === 1 ? (
                                <Image style={{ width: '100%', height: '100%', resizeMode: "contain" }} source={require('../../assets/general/Robot.png')} />
                            ) : (
                                (opponentAvatar) && (
                                    <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${opponentAvatar.replace('.svg', '.png')}`}}/>
                                )
                            )}
                        </View>
                    )}
                </View>
            </View>
        )
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }} style={{flex: 1}}>
                <View style={styles.modalContainer}>
                    <Text style={[styles.resultatText, {color: defi.won === 1 ? COLORS.green : COLORS.red}]}>{defi.won === 1 ? "Vous avez gagné :)" : "Vous avez perdu :("}</Text>

                    <View>
                        {defi.questions.map((question, index) => (
                            renderQuestion(question, index)
                        ))}
                    </View>

                    <CustomButton
                        title={"Fermer"}
                        backgroundColor={COLORS.gray}
                        textColor={COLORS.darkerGray}
                        shadowColor={COLORS.darkGray}
                        borderColor={COLORS.lightGray}
                        onPress={handleCloseModal}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}


const styles = StyleSheet.create({
    modalContainer: {
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        paddingVertical: 15
    },
    containerResult: {
        position: "relative",
        flex: 1,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        backgroundColor: "#FAFAFA",
        paddingVertical: helper.isLittleScreen() ? 15 : 20,
        borderRadius: ROUNDED.lg,
        justifyContent: "center",
        width: "100%"
    },
    containerAvatar: {
        position: "absolute",
        width: "100%",
        height: 80,
        top: -40,
    },
    boxAvatar: {
        position: "absolute",
        left: 5,
        width: 28,
        height: 28,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
    },
    boxAvatarOpponent: {
        position: "absolute",
        right: 5,
        width: 28,
        height: 28,
        aspectRatio: 1,
        borderRadius: ROUNDED.full,
        overflow: "hidden",
        backgroundColor: "#FAFAFA",
        borderWidth: 1,
        borderColor: COLORS.darkGray,
    },
    resultatText: {
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        textAlign: "center"
    },
    containerQuestion: {
        marginBottom: 30
    },
    question: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        fontWeight: FONTS.bold,
        color: COLORS.black,
        marginBottom: 10,
    },
    proposition: {
        textAlign: "center",
        paddingHorizontal: 40,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        marginBottom: 5,
        flexDirection: "row",
        alignItems: "center"
    }
});

export default ResultDefiDetailModal;
