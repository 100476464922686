import React, {useCallback, useEffect, useState} from 'react';
import {
    View,
    Text,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    FlatList,
    Image,
    TouchableOpacity,
    Modal,
    ActivityIndicator
} from 'react-native';
import {COLORS, FONTS, ROUNDED, SIZES} from "../../constants/theme";
import * as helper from "../../utils/helpers";
import CustomButton from "../../components/CustomButton";
import {useFocusEffect, useNavigation, useRoute} from "@react-navigation/native";
import DefiService from "../../services/DefiService";
import {ROOT_URL} from "../../constants/api";
import ResultDefiModal from "../../components/ResultDefiModal";
import DefierAmiModal from "../../components/DefierAmiModal";
import {removeHtmlTags, renderLevelContent} from "../../utils/helpers";
import he from "he";
import ResultDefiDetailModal from "../../components/ResultDefiDetailModal";
import ErrorNotification from "../../components/ErrorNotification";

function DefisPlay() {
    const [error, setError] = useState(null);
    const navigation = useNavigation();
    const [openResultModal, setOpenResultModal] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isEnd, setIsEnd] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [selectedResponses, setSelectedResponses] = useState([]);
    const [validerClicked, setValiderClicked] = useState(false);
    const [reponse1Style, setReponse1Style] = useState({});
    const [reponse2Style, setReponse2Style] = useState({});
    const [reponse3Style, setReponse3Style] = useState({});
    const [reponse4Style, setReponse4Style] = useState({});
    const [reponse1CibleStyle, setReponse1CibleStyle] = useState({});
    const [reponse2CibleStyle, setReponse2CibleStyle] = useState({});
    const [reponse3CibleStyle, setReponse3CibleStyle] = useState({});
    const [reponse4CibleStyle, setReponse4CibleStyle] = useState({});
    const route = useRoute();
    const defiData = route.params;
    const [defi, setDefi] = useState(null)
    const [users, setUsers] = useState(null)
    const [defiQuestion, setDefiQuestion] = useState(null)
    const [numero, setNumero] = useState(1)
    const [isBot, setIsBot] = useState(false)

    const handleCloseDefi = () => {
        setOpenResultModal(false);
        navigation.popToTop();
    }

    useEffect( () => {
        if(defiData) {
            setDefi(defiData.defi)
            setUsers(defiData.defi.users)
            setDefiQuestion(defiData.defi.defiQuestion)
            setIsBot(defiData.defi.defiQuestion.defi.is_bot === 1)

            setIsLoadingPage(false)
        }
        else{
            setIsLoadingPage(true)
        }
    }, [defiData])


    const toggleAnswerSelection = (answerIndex) => {
        if(!validerClicked){
            if (selectedResponses.includes(answerIndex)) {
                setSelectedResponses(selectedResponses.filter((index) => index !== answerIndex));
            } else {
                setSelectedResponses([...selectedResponses, answerIndex]);
            }
        }
    };

    const nextQuestion = async () => {
        setIsLoading(true);
        setValiderClicked(true);
        const correctResponses = [
            defiQuestion.question.bonne_reponse1,
            defiQuestion.question.bonne_reponse2,
            defiQuestion.question.bonne_reponse3,
            defiQuestion.question.bonne_reponse4,
        ];
        setValiderClicked(true);

        let selectedCurrentResponses = [0, 0, 0, 0];
        for(let i = 0; i < selectedCurrentResponses.length; i++){
            selectedCurrentResponses[i] = selectedResponses.includes(i + 1) ? 1 : 0;
        }

        try {
            const resultQuestion = await DefiService.setReponseQuestion(defiQuestion.id, selectedCurrentResponses.join(','))

            if(resultQuestion){
                setIsEnd(resultQuestion.hasEnd);
                setIsFinished(resultQuestion.isFinished);

                setNumero(defiQuestion.numero + 1)

                if(!resultQuestion.hasEnd){
                    // setDefi(resultQuestion.nextQuestion)
                    setDefiQuestion(resultQuestion.nextQuestion)
                    setSelectedResponses([]);
                }

                setIsLoading(false);
                setValiderClicked(false);
            }
            else{
                setError({code: 3011})
            }

        } catch (e) {
            setError({code: 3012})
        }
    }

    return (
        <SafeAreaView style={{flex: 1}}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
                { !isLoadingPage && (
                    <View style={styles.container}>
                        <View style={{width: "100%", alignItems: 'center'}}>
                            <View style={styles.containerTop}>
                                <View style={[styles.containerAvatar, {borderWidth: 2, borderColor: COLORS.blue}]}>
                                    {users?.current.avatar && (
                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${users?.current.avatar.replace('.svg', '.png')}`}}/>
                                    )}
                                </View>
                                <View style={styles.containerLevels}>
                                    { helper.renderLevelContent(numero > 1 ? 1 : isEnd ? false : null, defiQuestion.numero >= 1, COLORS.blue) }
                                    { helper.renderLevelContent(numero > 2 ? 1 : isEnd ? false : null, defiQuestion.numero >= 2, COLORS.green) }
                                    { helper.renderLevelContent(numero > 3 ? 1 : isEnd ? false : null, defiQuestion.numero >= 3, COLORS.orange) }
                                    { helper.renderLevelContent(numero > 4 ? 1 : isEnd ? false : null, defiQuestion.numero >= 4, COLORS.purple) }
                                    { helper.renderLevelContent(numero > 5 ? 1 : isEnd ? false : null, defiQuestion.numero >= 5, COLORS.red) }
                                </View>
                                <View style={[styles.containerAvatar, {borderWidth: 2, borderColor: isBot ? COLORS.primary : COLORS.darkGray}]}>
                                    { isBot ? (
                                        <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={require('../../../assets/general/Robot.png')}/>
                                    ) : (
                                        users?.opponent?.avatar && (
                                            <Image style={{width: '100%', height: '100%', resizeMode: "contain"}} source={{uri: `${ROOT_URL}/storage/${users?.opponent.avatar.replace('.svg', '.png')}`}}/>
                                        )
                                    )}
                                </View>
                            </View>
                        </View>

                        <View style={styles.containerMain}>
                            { isLoading ? (
                                <ActivityIndicator size={"large"} color={COLORS.primary} />
                            ) : (
                                isEnd ? (
                                    <View style={{alignItems: "center"}}>
                                        <Text style={styles.title}>Vous avez terminé votre défi</Text>

                                        { isFinished ? (
                                            <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.semibold, color: COLORS.darkerGray, textAlign: "center"}}>Votre adversaire a également terminé</Text>
                                        ) : (
                                            <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.semibold, color: COLORS.darkerGray, textAlign: "center"}}>En attente des résultats de votre adversaire</Text>
                                        )
                                        }
                                    </View>
                                ) : (
                                    <View style={styles.questionContainer}>
                                        <Text style={styles.questionText}>{removeHtmlTags(he.decode(defiQuestion.question.question))}</Text>

                                        <TouchableOpacity
                                            style={[
                                                styles.responseButton,
                                                selectedResponses.includes(1) && styles.responseButtonSelected,
                                                reponse1CibleStyle,
                                                reponse1Style,
                                            ]}
                                            onPress={() => toggleAnswerSelection(1)}
                                        >
                                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(defiQuestion.question.proposition1))}</Text>
                                            </View>
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={[
                                                styles.responseButton,
                                                selectedResponses.includes(2) && styles.responseButtonSelected,
                                                reponse2CibleStyle,
                                                reponse2Style,
                                            ]}
                                            onPress={() => toggleAnswerSelection(2)}
                                        >

                                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(defiQuestion.question.proposition2))}</Text>
                                            </View>
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={[
                                                styles.responseButton,
                                                selectedResponses.includes(3) && styles.responseButtonSelected,
                                                reponse3CibleStyle,
                                                reponse3Style,
                                            ]}
                                            onPress={() => toggleAnswerSelection(3)}
                                        >
                                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(defiQuestion.question.proposition3))}</Text>
                                            </View>
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={[
                                                styles.responseButton,
                                                selectedResponses.includes(4) && styles.responseButtonSelected,
                                                reponse4CibleStyle,
                                                reponse4Style,
                                            ]}
                                            onPress={() => toggleAnswerSelection(4)}
                                        >
                                            <View style={{alignItems: "center", flexDirection: "row"}}>
                                                <Text style={styles.responseButtonText}>{removeHtmlTags(he.decode(defiQuestion.question.proposition4))}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                )
                            )}
                        </View>

                        <View style={styles.containerBottom}>
                            <View style={{width: '100%', maxWidth: 400}}>
                                { !isLoading && !validerClicked && !isEnd && (
                                    <CustomButton
                                        title={defiQuestion.numero < 5 ? "Suivant" : "Terminer"}
                                        onPress={nextQuestion}
                                    />
                                )}
                                { !isEnd ? (
                                    <CustomButton
                                        title={"Quitter"}
                                        backgroundColor={COLORS.red}
                                        textColor={COLORS.white}
                                        shadowColor={COLORS.darkRed}
                                        borderColor={COLORS.lightRed}
                                        onPress={handleCloseDefi}
                                    />
                                ) : (
                                    isFinished ? (
                                        <CustomButton
                                            title={"Voir résultat"}
                                            onPress={handleCloseDefi}
                                        />
                                    ) : (
                                        <CustomButton
                                            title={"Revenir aux défis"}
                                            onPress={handleCloseDefi}
                                        />
                                    )
                                )}
                            </View>
                        </View>
                    </View>
                )}

                <Modal visible={openResultModal}>
                    <ResultDefiDetailModal defi={defi} handleCloseModal={handleCloseDefi} />
                </Modal>

            </ScrollView>

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 30,
        paddingVertical: 20,
    },
    title: {
        color: COLORS.black,
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginTop: helper.isLittleScreen() ? 7 : 15,
        marginBottom: helper.isLittleScreen() ? 15 : 20,
    },
    containerTop: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%',
        maxWidth: 400
    },
    containerAvatar: {
        width: 50,
        height: 50,
        borderWidth: 1,
        borderColor: COLORS.darkerGray,
        borderRadius: ROUNDED.full,
        overflow: "hidden"
    },
    containerLevels: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    containerMain: {
        flex: 1,
        width: "100%",
        marginTop: 15,
    },
    containerBottom: {
        width: "100%",
        alignItems: "center"
    },
    questionContainer: {

    },
    questionText: {
        fontSize: helper.isLittleScreen() ? SIZES.base : SIZES.lg,
        fontWeight: FONTS.semibold,
        textAlign: 'center',
        marginTop: helper.isLittleScreen() ? 5 : 10,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        color: COLORS.black,
    },
    responseButton: {
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        borderColor: COLORS.darkGray,
        width: '100%',
        paddingHorizontal: helper.isLittleScreen() ? 8 : 10,
        paddingVertical: helper.isLittleScreen() ? 10 : 15,
        marginVertical: 5,
        textAlign: 'center',
        wordBreak: 'break-word'
    },
    responseButtonSelected: {
        backgroundColor: COLORS.primary,
        borderWidth: 1,
        borderColor: COLORS.primary,
    },
    responseButtonText: {
        fontSize: helper.isLittleScreen() ? SIZES.xs : SIZES.sm,
        color: COLORS.black,
        fontWeight: FONTS.semibold,
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
    },
});

export default DefisPlay;
