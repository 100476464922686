import React, { useRef, useEffect, useState } from 'react';
import {Button, View, Animated, Easing, StyleSheet, Image, Text} from "react-native";
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import * as helper from "../utils/helpers";
import CustomButton from "./CustomButton";

function ValidationModal({ groupLevel, isValid, closeModal }) {
    const [showConfetti, setShowConfetti] = useState(true);
    const [points, setPoints] = useState(0);
    const [bonneReponse, setBonneReponse] = useState(0);


    useEffect(() => {
        if (groupLevel) {
            if(groupLevel.length > 0){
                const pointsCount = groupLevel.reduce((sum, group) => {
                    return sum + group.points;
                }, 0);
                const reponse = groupLevel.reduce((sum, group) => {
                    return sum + group.is_valid;
                }, 0);
                setPoints(pointsCount);
                setBonneReponse(reponse);
            }
        }
    }, [groupLevel]);

    const renderAnimation = (delay, scaleRange, opacityRange, translateXRange, translateYRange) => {
        const scaleValue = useRef(new Animated.Value(0)).current;

        const fadeIn = () => {
            Animated.loop(
                Animated.timing(scaleValue, {
                    toValue: 1,
                    duration: delay,
                    easing: Easing.ease,
                    useNativeDriver: true,
                }),
                {
                    resetBeforeIteration: true, // to reset the animation before it starts again
                }
            ).start();
        };

        const fadeOut = () => {
            Animated.timing(scaleValue, {
                toValue: 0,
                duration: 50,
                easing: Easing.ease,
                useNativeDriver: true,
            }).start();
        };

        useEffect(() => {
            if(showConfetti){
                fadeIn()
            }
            else {
                fadeOut()
            }
        }, [showConfetti]);

        return (
            <Animated.View
                style={[styles.fadingContainer,{
                    opacity: scaleValue.interpolate({
                        inputRange: opacityRange.input,
                        outputRange: opacityRange.output
                    }),
                    transform: [
                        {
                            translateX: scaleValue.interpolate({
                                inputRange: translateXRange.input,
                                outputRange: translateXRange.output
                            }),
                        },
                        {
                            translateY: scaleValue.interpolate({
                                inputRange: translateYRange.input,
                                outputRange: translateYRange.output
                            }),
                        },
                        {
                            scale:  scaleValue.interpolate({
                                inputRange: scaleRange.input,
                                outputRange: scaleRange.output
                            }),
                        },
                    ]
                }]}
            >
                <FontAwesome name="star" size={20} color={COLORS.primary} />
            </Animated.View>
        )
    }
    const renderTirelireAnimation = () => {
        const fadeOutValue = useRef(new Animated.Value(1)).current;
        const fadeInValue = useRef(new Animated.Value(0)).current;

        Animated.parallel([
            Animated.timing(fadeOutValue, {
                toValue: 0,
                duration: 1000,
                easing: Easing.ease,
                useNativeDriver: true,
            }),
            Animated.timing(fadeInValue, {
                toValue: 1,
                duration: 1000,
                easing: Easing.ease,
                useNativeDriver: true,
            })
        ]).start();

        return (
            <View style={styles.containerTirelire}>
                <Animated.View
                    style={[{ position: "absolute" },{
                        opacity: fadeOutValue,
                    }]}
                >
                    <Image style={styles.imageTirelire} source={require('../../assets/general/tirelire_loose.png')}/>
                </Animated.View>
                <Animated.View
                    style={[{ position: "absolute" },{
                        opacity: fadeInValue,
                    }]}
                >
                    <Image style={styles.imageTirelire2} source={require('../../assets/general/tirelire_broken.png')}/>
                </Animated.View>
            </View>
        )
    }



    function renderLevelContent(index, color) {
        if (!groupLevel) {
            return null;
        }

        if (!groupLevel[index]) {
            return null;
        }

        return (
            <View style={[styles.level, {borderColor: color}]}>
                {
                    groupLevel[index]["is_valid"] === null && groupLevel[index]["can_open"] ? (
                        <FontAwesome name="star" size={helper.isLittleScreen() ? 12 : 15} color={color} />
                    ) : groupLevel[index]["is_valid"] === null && !groupLevel[index]["can_open"] ? (
                        <FontAwesome name="lock" size={helper.isLittleScreen() ? 12 : 15} color={COLORS.darkerGray} />
                    ) : groupLevel[index]["is_valid"] == 1 ? (
                        <FontAwesome name="check" size={helper.isLittleScreen() ? 12 : 15} color={color} />
                    ) : (
                        <FontAwesome name="close" size={helper.isLittleScreen() ? 12 : 15} color={color}  />
                    )
                }
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <View style={styles.containerTop}>
                { isValid ? (
                    <Image style={styles.trophy} source={require('../../assets/general/trophy_win.png')}/>
                ) : (
                    renderTirelireAnimation()
                )}

                { isValid  && (
                    <View style={styles.containerAnimation}>
                        {renderAnimation(3000, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, 80]},{input: [0, 0.5, 0.75, 1], output:[8, -60, -80, -90]})}
                        {renderAnimation(1500, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, -80]},{input: [0, 0.5, 0.75, 1], output:[12, -60, -80, -90]})}
                        {renderAnimation(2000, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, -120]},{input: [0, 0.5, 0.75, 1], output:[10, -30, -40, -35]})}
                        {renderAnimation(2000, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, -10]},{input: [0, 0.5, 1], output:[12, -40, -130]})}
                        {renderAnimation(2300, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, 50]},{input: [0, 0.5, 1], output:[10, -80,  -105]})}
                        {renderAnimation(2300, {input: [0, 1], output: [1, 0]}, {input: [0, 0.75, 1], output: [1, 0.7, 0]},{input: [0, 1], output:[0, -50]},{input: [0, 0.5, 1], output:[8, -80,  -105]})}
                    </View>
                )}
            </View>

            <View style={styles.contaierContent}>
                <View style={styles.contaierContentTop}>
                    <Text style={[styles.textResult, {color: isValid ? COLORS.green : COLORS.red}]}>{isValid ? "Félicitations !" : "Vous avez perdu :("}</Text>


                    {isValid ? (
                        <Text style={styles.textResultPhrase}>
                            Vous avez validé le niveau avec {bonneReponse} bonne(s) réponse(s) sur 5
                        </Text>
                    ) : (
                        <Text style={styles.textResultPhrase}>
                            Retentez votre chance et{"\n"} recommencer le niveau
                        </Text>
                    )}

                    <View style={{flexDirection: "row", justifyContent: "center", width: helper.isLittleScreen() ? 300 : 400}}>
                        <View style={styles.containerLevels}>
                            {renderLevelContent(5, COLORS.blue)}
                            <View style={[styles.line, {backgroundColor: COLORS.darkPrimary}]}></View>
                            {renderLevelContent(4, COLORS.green)}
                            <View style={[styles.line, {backgroundColor: COLORS.darkPrimary}]}></View>
                            {renderLevelContent(3, COLORS.orange)}
                            <View style={[styles.line, {backgroundColor: COLORS.darkPrimary}]}></View>
                            {renderLevelContent(2, COLORS.purple)}
                            <View style={[styles.line, {backgroundColor: COLORS.darkPrimary}]}></View>
                            {renderLevelContent(1, COLORS.red)}
                        </View>
                    </View>
                </View>
                { isValid ? (
                    <View style={styles.contaierContentBottom}>
                        <Text style={styles.textPhrasePoint}>Voici les points gagnés :</Text>
                        <Text style={styles.textPoint}>+{points} pts</Text>
                        <CustomButton
                            title={'Continuer'}
                            onPress={closeModal}
                            width={'60%'}
                        />
                    </View>
                ) : (

                    <View style={styles.contaierContentBottom}>
                        <CustomButton
                            backgroundColor={COLORS.red}
                            borderColor={COLORS.lightRed}
                            shadowColor={COLORS.darkRed}
                            title={'Recommencer'}
                            onPress={closeModal}
                            width={'60%'}
                        />
                    </View>
                )}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    containerTop:{
        position: "relative",
        height: "19%",
        justifyContent: "center",
        alignItems: "center"
    },
    containerAnimation: {
        justifyContent: "center",
        alignItems: 'center'
    },
    fadingContainer: {
        position: "absolute",
    },
    contaierContent:{
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: helper.isLittleScreen() ? 15 : 30,
    },
    contaierContentTop:{
        alignItems: "center",
        width: "auto"
    },
    contaierContentBottom:{
        width: "100%",
        alignItems: "center",
    },
    textResult: {
        fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['3xl'],
        fontWeight: FONTS.bold,
        color: COLORS.green
    },
    textResultPhrase: {
        textAlign: "center",
        fontSize: helper.isLittleScreen() ? SIZES['base'] : SIZES['lg'],
        marginTop: helper.isLittleScreen() ? 20 : 30,
        marginBottom: helper.isLittleScreen() ? 15 : 30,
        paddingHorizontal: 30,
        fontWeight: FONTS.medium,
    },
    containerLevels: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    level: {
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 3,
        borderRadius: ROUNDED.full,
        width: helper.isLittleScreen() ? 25 : 30,
        aspectRatio: 1,
        marginHorizontal: 3
    },
    levelBlue:{
        borderColor: COLORS.blue
    },
    line: {
        width: helper.isLittleScreen() ? '8%' : '10%',
        height:3,
        borderRadius: ROUNDED.xl
    },
    textPhrasePoint : {
        color: COLORS.darkGray,
        marginBottom: helper.isLittleScreen() ? 5 : 15,
        fontSize: helper.isLittleScreen() ? SIZES.sm : SIZES.base
    },
    textPoint : {
        color: COLORS.black,
        marginBottom: helper.isLittleScreen() ? 5 : 15,
        fontSize: helper.isLittleScreen() ? SIZES["xl"] : SIZES["2xl"],
        fontWeight: FONTS.bold
    },
    containerTirelire: {
        position: "relative",
        width: "100%",
        padding: 10,
        height: 30,
        justifyContent: "center",
        alignItems: "center"
    },
    imageTirelire: {
        zIndex: 1,
        transform: [
            { scale: helper.isLittleScreen() ? 0.65 : 0.8 }
        ],
        width: 136,
        height: 98,
    },
    imageTirelire2: {
        zIndex: 1,
        transform: [
            { scale: helper.isLittleScreen() ? 0.65 : 0.8 }
        ],
        width: 138,
        height: 105,
    },
    trophy: {
        height: 78,
        width: 79,
        resizeMode: "contain",
        zIndex: 1
    }
});

export default ValidationModal;
