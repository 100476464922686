import React from 'react';
import { Modal, View, Text, StyleSheet } from 'react-native';
import {COLORS, FONTS, ROUNDED, SIZES} from '../constants/theme';
import CustomButton from './CustomButton';
import {isLittleScreen} from "../utils/helpers";

function ModalComponent({ visible, onClose, showCloseButton = true, onValid, title, titleValid, children }) {
    return (
        <Modal visible={visible} animationType="slide" transparent={true}>
            <View style={styles.containerModal}>
                <Text style={styles.textModal}>{title}</Text>

                <View style={styles.contentModal}>
                    <View style={styles.content}>{children}</View>
                    <View style={{ alignItems: 'center' }}>

                        { titleValid && (
                            <CustomButton
                                onPress={onValid}
                                title={ titleValid }
                                width={'70%'}
                            />
                        )}
                        { showCloseButton && (
                            <CustomButton
                                onPress={onClose}
                                title={'Fermer'}
                                width={'70%'}
                                backgroundColor={COLORS.gray}
                                borderColor={COLORS.lightGray}
                                shadowColor={COLORS.darkerGray}
                                textColor={COLORS.darkerGray}
                            />
                        )}
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    containerModal : {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 30,
        backgroundColor: 'rgba(24,24,27,0.8)'
    },
    textModal : {
        textTransform: "uppercase",
        color: COLORS.white,
        fontWeight: FONTS.bold,
        marginBottom: isLittleScreen() ? 15 : 30,
        fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl']
    },
    contentModal: {
        justifyContent: "space-between",
        height: isLittleScreen() ? '70%' : '60%',
        padding: isLittleScreen() ? 8 : 10,
        width: '100%',
        maxWidth: 400,
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.xl
    },
    content: {
        flex: 1,
        marginBottom: 20,
    },
});

export default ModalComponent;
