import React, {useState, useEffect, useCallback, useContext} from 'react';
import {View, StyleSheet, FlatList, Modal, ImageBackground, SafeAreaView, Text, Linking} from 'react-native';
import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import Menu from '../components/Menu';
import LevelButton from '../components/LevelButton';
import LevelService from "../services/LevelService";
import { ActivityIndicator } from 'react-native-paper';
import {COLORS, FONTS, ROUNDED, SIZES} from "../constants/theme";
import QuestionModal from "../components/QuestionModal";
import QuestionService from "../services/QuestionService";
import HeaderSolo from "../components/HeaderSolo";
import SoloService from "../services/SoloService";
import * as helper from "../utils/helpers";
import ValidationModal from "../components/ValidationModal";
import AuthService from "../services/AuthService";
import ErrorNotification from "../components/ErrorNotification";
import NotificationService from "../services/NotificationService";
import ProfileModal from "../components/ProfileModal";
import ModalComponent from "../components/ModalComponent";
import CustomButton from "../components/CustomButton";
import UserService from "../services/UserService";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {AuthContext} from "../utils/AuthContext";


function Accueil() {
    const [error, setError] = useState(null);
    const { checkLoginStatus } = useContext(AuthContext);
    const isFocused = useIsFocused();
    const [loadingInitial, setLoadingInitial] = useState(true);
    const imageSource = helper.isTabletteScreen()
        ? require('../../assets/general/Fond_Solo_tablette.png')
        : require('../../assets/general/Fond_Solo.png');
    const [modalVisible, setModalVisible] = useState(false);
    const [levels, setLevels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState(null);
    const [currentLevel, setCurrentLevel] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [nextPage, setNextPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [user, setUser] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [modalValidationVisible, setModalValidationVisible] = useState(false)
    const [isValidate, setIsValidate] = useState(null)
    const [toggleSoundEffect, setToggleSoundEffect] = useState(true)
    const [selectedFriendId, setSelectedFriendId] = useState(null);
    const [modalProfileVisible, setModalProfileVisible] = useState(false);
    const [typeDefis, setTypeDefi] = useState('');
    const [modalDefisVisible, setModalDefisVisible] = useState(false);
    const navigation = useNavigation();
    const [mobileAccess, setMobileAccess] = useState(true)

    useFocusEffect(
        useCallback(() => {
            checkMobileAccess();
            checkEnabledAudio();
            fetchLevels();
            fetchUserData();
            checkNotifications()
        }, [])
    );

    const checkEnabledAudio = async () => {
        const enable = await helper.getGlobalBoolean('isEffectMute')
        setToggleSoundEffect(enable);
    }
    const checkMobileAccess = async () => {
        const checkAccess = await UserService.checkMobileAccess();
        if(checkAccess) {
            setMobileAccess(checkAccess.has_access)
        }
        setLoadingInitial(false)
    }

    const handleLogout = async () => {
        try {
            // Clear access token from AsyncStorage
            await AsyncStorage.removeItem('token');
            checkLoginStatus()
        } catch (error) {
            setError({code: 5004})
        }
    };

    const checkNotifications = async () => {
        try {
            const notifications = await NotificationService.getAll();

            if(notifications) {
                if(notifications.defi_abonne) {
                    if(notifications.defi_abonne.id) {
                        setModalDefisVisible(true)
                        setTypeDefi('abonne')
                    }
                }
                else if(notifications.defi_ami) {
                    if(notifications.defi_ami.id) {
                        setModalDefisVisible(true)
                        setTypeDefi('amis')
                    }
                }
                else if(notifications.demande_ami) {
                    if(notifications.demande_ami.id) {
                        setSelectedFriendId(notifications.demande_ami.user_id)
                        setModalProfileVisible(true)
                    }
                }
            }

        } catch (error) {

        }
    }


    const handleOpenModalProfil = (user_id) => {
        setModalProfileVisible(true)
    }

    const handleCloseProfil = () => {
        setModalProfileVisible(false)
    }

    const handleOpenModalDefis = () => {
        setModalDefisVisible(true)
    }

    const handleCloseDefis = () => {
        setModalDefisVisible(false)
    }

    const navigateToScreen = (screen) => {
        handleCloseDefis()
        navigation.navigate('Défis')
    }

    const fetchLevels = useCallback(async () => {
        try {
            setLoading(true);
            const levelsData = await LevelService.getLevels(0);


            if(levelsData) {
                let currentLevels = levelsData.data;
                let pageNumber = nextPage;

                if(levelsData.next_page_url){
                    pageNumber = levelsData.next_page_url.match(/page=(\d+)/)[1]
                    if(pageNumber){
                        pageNumber = parseInt(pageNumber)
                    }
                }

                setCurrentPage(levelsData.current_page)

                setLevels(currentLevels);
                setNextPage(pageNumber)
            }
            else{
                setError({code: 1007, message: "Les niveaux n'ont pas pu être chargés. Veuillez réessayer plus tard."})
            }

        } catch (error) {
            setError({code: 1002, message: "Les niveaux n'ont pas pu être chargés. Veuillez réessayer plus tard."})
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchNextLevels = async () => {
        if(currentPage !== nextPage && nextPage > 0){
            try {
                setLoadingMore(true);
                const levelsData = await LevelService.getLevels(nextPage);

                if(levelsData){
                    let currentLevels = levelsData.data;

                    if(levels.length > 0 && currentPage !== 0 && levels !== levelsData.data){
                        currentLevels = [...levels, ...levelsData.data]
                    }

                    let pageNumber = nextPage;
                    if(levelsData.next_page_url){
                        pageNumber = levelsData.next_page_url.match(/page=(\d+)/)[1]
                        if(pageNumber){
                            pageNumber = parseInt(pageNumber)
                        }
                    }

                    setCurrentPage(levelsData.current_page)
                    setLevels(currentLevels);
                    setNextPage(pageNumber)

                    setLoadingMore(false);
                }
                else{
                    setError({code: 1004, message: "Les niveaux suivant n'ont pas pu être chargés. Veuillez réessayer plus tard."})
                }

            } catch (error) {
                setError({code: 1003, message: "Les niveaux suivant n'ont pas pu être chargés. Veuillez réessayer plus tard."})
            } finally {

            }
        }
    }

    useEffect(() => {
        if (levels && currentPage === 1) {

            // Find the highest group number
            const maxGroup = Math.max(...levels.map((item) => item.group));

            // Filter objects with the highest group number
            const result = levels.filter((item) => item.group === maxGroup);

            setCurrentGroup(result);
        }
    }, [levels, currentPage]);


    const handleLevelModalClose = () => {
        fetchLevels();
        fetchUserData();
        setModalVisible(false);
        setQuestion(null);
        setCurrentLevel(null);
    };

    const handleCloseValidationModal = () => {
        fetchLevels();
        fetchUserData();
        setModalVisible(false);
        setModalValidationVisible(false);
        setQuestion(null);
        setCurrentLevel(null);

        // Delay for 2 seconds (adjust the duration as needed)
        setTimeout(() => {
            setIsValidate(null);
        }, 2000);
    };

    const handleLevelModalNext = (isValidation, isCorrect) => {
        if(!isValidation){
            setModalVisible(false);
            fetchLevels();
            fetchUserData();
            setQuestion(null);
            setCurrentLevel(null);
        }
        else{
            setModalVisible(false);
            setIsValidate(isCorrect);
            setModalValidationVisible(true);
        }
    };


    const handleValidateQuestion = () => {
        // console.log('validate handle')
    };


    const handleOnPress = async (id) => {
        const selectedLevel = levels.find((level) => level.id === id);
        setCurrentLevel(selectedLevel);


        if(selectedLevel.can_open == 1){
            try {
                setLoading(true);
                const questionData = await QuestionService.getQuestionFromLevel(selectedLevel.id);

                if(questionData) {
                    setQuestion(questionData);
                }
                else{
                    setError({code: 1001, message: "Les questions n'ont pas pu être chargées. Veuillez réessayer plus tard."})
                }

                setModalVisible(true);
            } catch (error) {
                // console.log(error);
                setError({code: 1005, message: "Les questions n'ont pas pu être chargées. Veuillez réessayer plus tard."})
            } finally {
                setLoading(false);
            }
        }
    }


    function renderLevelButton({ item }) {
        const colors = helper.getColorByCategorySolo(item.category_id)

        if(item.can_open != 1){
            colors.backgroundColor = COLORS.darkGray
            colors.darkColor = COLORS.darkerGray
        }

        return (
            <LevelButton
                level={item}
                colors={colors}
                onClose={handleLevelModalClose}
                onPress={() => handleOnPress(item.id)}
            />
        );
    }


    const fetchUserData = async () => {
        try {
            const userData = await SoloService.getUser();

            if(userData) {
                setUser(userData);
            }
            else{
                setError({code: 1011, message: "Les informations du profil n'ont pas pu être chargées. Veuillez réessayer plus tard."})
            }
        } catch (error) {
            setError({code: 1006, message: "Les informations du profil n'ont pas pu être chargées. Veuillez réessayer plus tard.`"})
        }
    }


    return (
        <SafeAreaView style={{ flex: 1 }}>
            { loadingInitial ? (
                <ActivityIndicator animating={true} size="large" color={COLORS.primary} style={styles.loadingIndicator} />
            ) : mobileAccess ? (
                <ImageBackground style={{ flex: 1 }} source={imageSource}>
                    <Menu userData={user} />
                    { levels && levels?.length > 0 && currentGroup && (
                        <View>
                            <HeaderSolo currentGroup={currentGroup} />
                        </View>
                    )}

                    {loading ? (
                        <ActivityIndicator animating={true} size="large" color={COLORS.primary} style={styles.loadingIndicator} />
                    ) : (
                        <FlatList
                            inverted
                            data={levels}
                            keyExtractor={(item) => item.id.toString()}
                            renderItem={renderLevelButton}
                            contentContainerStyle={styles.listContainer}
                            onEndReached={() => fetchNextLevels()}
                            onEndReachedThreshold={0.2}
                            ListFooterComponent={() => loadingMore ? <ActivityIndicator animating={true} size="small" color={COLORS.primary} /> : null}
                        />
                    )}

                    <Modal visible={modalVisible} >
                        <QuestionModal
                            questionData={question}
                            level={currentLevel}
                            onValidate={handleValidateQuestion}
                            handleCloseModal={handleLevelModalClose}
                            handleNextModal={handleLevelModalNext}
                            toggleSoundEffect={toggleSoundEffect}
                        />
                    </Modal>

                    <Modal visible={modalValidationVisible} >
                        <ValidationModal
                            groupLevel={currentGroup}
                            closeModal={handleCloseValidationModal}
                            isValid={isValidate}
                        />
                    </Modal>


                    <Modal visible={modalProfileVisible}>
                        <ProfileModal onClose={handleCloseProfil} user_id={selectedFriendId} />
                    </Modal>

                    { navigation && (
                        <ModalComponent
                            visible={modalDefisVisible}
                            onClose={handleCloseDefis}
                            title={typeDefis === 'amis' ? "Défi amis" : "Défi abonné"}
                            titleValid="Voir défi"
                            onValid={navigateToScreen}
                        >
                            <View style={{justifyContent: "space-between", marginTop: 20, paddingHorizontal: 15}}>
                                <Text style={[styles.resultatText, {color: COLORS.darkerGray}]}>
                                    { typeDefis === 'amis' ? "Vous avez reçu un défi amis" : "Vous avez reçu un défi abonné" }
                                </Text>


                                <Text style={{color: COLORS.black, textAlign: "center"}}>
                                    Rendez-vous sur la page de défis pour accepter ou refuser le défi.
                                </Text>
                            </View>
                        </ModalComponent>
                    )}
                </ImageBackground>
            ) : (
                <ImageBackground style={{ flex: 1 }} source={imageSource}>
                    <View style={{justifyContent: 'center', alignItems: 'center', padding: 30}}>
                        <View style={{width: '500px', height: '300px',  padding: 30, borderRadius: ROUNDED.lg, backgroundColor: 'white', justifyContent: 'space-between'}}>
                            <View>
                                <Text style={{fontSize: SIZES.lg, fontWeight: FONTS.bold, marginBottom: '15px'}}>Abonnement application requis</Text>
                                <Text>Bonjour, Vous devez être abonné à l'application pour accéder à cette fonctionnalité. Veuillez vous abonner pour profiter pleinement de cette expérience.</Text>
                            </View>

                            <View>
                                <CustomButton title="S'abonner" onPress={() =>
                                    Linking.openURL('https://objectifofficiersp.com/boutique')}
                                />
                                <CustomButton
                                    title="Se déconnecter"
                                    onPress={ handleLogout }
                                    backgroundColor={COLORS.red}
                                    borderColor={COLORS.lightRed}
                                    shadowColor={COLORS.darkRed}
                                    textColor={COLORS.white}
                                />
                            </View>

                        </View>

                    </View>
                </ImageBackground>
            )}

            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    listContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingIndicator: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    resultatText: {
        fontSize: helper.isLittleScreen() ? SIZES.xl : SIZES['2xl'],
        fontWeight: FONTS.bold,
        marginBottom: helper.isLittleScreen() ? 10 : 20,
        textAlign: "center"
    },
});

export default Accueil;
