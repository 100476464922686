import React, { useEffect, useState } from "react";
import { FlatList, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLORS, FONTS, ROUNDED, SIZES } from "../../constants/theme";
import ErrorNotification from "../../components/ErrorNotification";

const categoryColors = {
    1: COLORS.contrat.blue,
    2: COLORS.contrat.red,
    3: COLORS.contrat.green,
    4: COLORS.contrat.purple,
    5: COLORS.contrat.orange,
};

function SelectTypeGame({ route, navigation }) {
    const [error, setError] = useState(null);
    const { playersInfo } = route.params;
    const [playersGameInfo, setPlayersGameInfo] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});

    useEffect(() => {
        const tmpPlayersGameInfo = [...playersInfo];
        let id = 1;
        tmpPlayersGameInfo.forEach(obj => {
            obj.id = id.toString();
            obj.typeCategory = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 };
            id++;
        });

        setPlayersGameInfo(tmpPlayersGameInfo);
    }, [playersInfo]);

    useEffect(() => {
        if (playersGameInfo.length > 0) {
            console.log(playersGameInfo);
        }
    }, [playersGameInfo]);

    const handleGoBack = () => {
        navigation.goBack();
    };

    const handleCategorySelect = (playerId, categoryKey, newCategory) => {
        const updatedPlayersGameInfo = playersGameInfo.map(player => {
            if (player.id === playerId) {
                return {
                    ...player,
                    typeCategory: {
                        ...player.typeCategory,
                        [categoryKey]: newCategory,
                    },
                };
            }
            return player;
        });

        setPlayersGameInfo(updatedPlayersGameInfo);
    };

    const renderCategorySelector = (playerId, categoryKey) => {
        return (
            <View style={styles.categorySelector}>
                {Object.entries(categoryColors).map(([key, color]) => (
                    <TouchableOpacity
                        key={key}
                        style={[
                            styles.categoryCard,
                            { backgroundColor: color },
                            selectedCategory[playerId]?.[categoryKey] === parseInt(key) && styles.selectedCategory,
                        ]}
                        onPress={() => handleCategorySelect(playerId, categoryKey, parseInt(key))}
                    >
                        <Text style={styles.categoryText}>{key}</Text>
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    const renderPlayerModeItem = ({ item }) => (
        <View style={styles.containerItem}>
            <Text style={styles.name}>{item.pseudo}</Text>
            {Object.entries(item.typeCategory).map(([key, value]) => (
                <View key={key} style={styles.categoryContainer}>
                    <TouchableOpacity
                        style={[styles.categoryCard, { backgroundColor: categoryColors[value] }]}
                        onPress={() =>
                            setSelectedCategory(prev => ({
                                ...prev,
                                [item.id]: { ...prev[item.id], [key]: value },
                            }))
                        }
                    >
                        <Text style={styles.categoryText}>{value}</Text>
                    </TouchableOpacity>
                    {selectedCategory[item.id]?.[key] === value && renderCategorySelector(item.id, key)}
                </View>
            ))}
        </View>
    );

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: COLORS.white }}>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <Text style={styles.title}>Sélectionner le mode de partie :</Text>

                <View>
                    {playersGameInfo.length > 0 && (
                        <FlatList
                            nestedScrollEnabled
                            data={playersGameInfo}
                            renderItem={renderPlayerModeItem}
                            keyExtractor={item => item.id}
                            scrollEnabled={false}// Désactiver le défilement interne de FlatList
                        />
                    )}
                </View>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={[styles.button, styles.buttonRetour]} onPress={handleGoBack}>
                        <Text style={styles.buttonTextRetour}>Retour</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={() => console.log('handleNext')}>
                        <Text style={styles.buttonText}>Suivant</Text>
                    </TouchableOpacity>
                </View>
            </ScrollView>
            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
        paddingHorizontal: 30,
        maxWidth: 500,
        width: "100%",
        paddingBottom: 10,
    },
    title: {
        fontSize: SIZES["2xl"],
        fontWeight: FONTS.bold,
        textAlign: "center",
        marginTop: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
    },
    button: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.darkPrimary,
        borderRadius: ROUNDED.md,
        borderWidth: 1,
        paddingVertical: 10,
        paddingHorizontal: 30,
        alignItems: 'center',
        justifyContent: 'center',
        width: '48%',
    },
    buttonText: {
        color: COLORS.white,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    buttonRetour: {
        backgroundColor: COLORS.gray,
        borderRadius: ROUNDED.md,
        borderColor: COLORS.darkGray,
    },
    buttonTextRetour: {
        color: COLORS.darkerGray,
        fontWeight: FONTS.bold,
        fontSize: SIZES.lg,
    },
    containerItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        backgroundColor: '#fff',
    },
    name: {
        fontSize: 18,
    },
    categoryContainer: {
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    categoryCard: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginHorizontal: 5,
    },
    categoryText: {
        color: COLORS.white,
        fontSize: 16,
        fontWeight: 'bold',
    },
    selectedCategory: {
        borderWidth: 4,
        borderColor: 'black',
    },
    categorySelector: {
        flexDirection: 'row',
        marginTop: 10,
    },
});

export default SelectTypeGame;
