import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, Modal, Image, StyleSheet} from 'react-native';
import {CommonActions, useNavigation} from "@react-navigation/native";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { COLORS, FONTS, SIZES, ROUNDED } from '../constants/theme';
import SoloService from "../services/SoloService";
import * as helper from "../utils/helpers";
import {isLittleScreen} from "../utils/helpers";
import CustomButton from "./CustomButton";
import Roue from '../components/Roue';
import ErrorNotification from "./ErrorNotification";

function Menu({userData}) {
    const [error, setError] = useState(null);
    const navigation = useNavigation();
    const [user, setUser] = useState(null);
    const [contratDataFetched, setContratDataFetched] = useState(false); // Track if contract data has been fetched
    const [contrats, setContrats] = useState([]);
    const [contrat, setContrat] = useState(null);
    const [contratColor, setContratColor] = useState(COLORS.white)
    const [selectedContrat, setSelectedContrat] = useState(1);
    const [groupLevelsContrat, setGroupLevelsContrat] = useState([]);
    const [toValidateCount, setToValdiateCount] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [contratFinished, setContratFinished] = useState(null);
    const [modalContratFinishedVisible, setModalContratFinishedVisible] = useState(false);
    const [points, setPoints] = useState(0);
    const [bonus1, setBonus1] = useState(0);
    const [bonus2, setBonus2] = useState(0);
    const [roueTimeLeft, setRoueTimeLeft] = useState(null);
    const [canOpenRoue, setCanOpenRoue] = useState(false);
    const [modalRoueVisible, setModalRoueVisible] = useState(false);
    const [modalBonusVisible, setModalBonusVisible] = useState(false);
    const [bonusSelected, setBonusSelected] = useState(null);
    const [showClassmentAfter, setShowClassementAfter] = useState(false);


    useEffect(() => {
        if(!contratDataFetched) {
            fetchContractData();
        }
    }, []);

    useEffect(() => {
        if(userData){
            fetchUserData();
        }
    }, [userData]);


    useEffect(() => {
        if(contrat !== null){
            fetchLevelsContrat();
        }

    }, [modalVisible]);

    useEffect(() => {
        if(contratFinished !== null){
            fetchLevelsContrat();
        }

    }, [modalContratFinishedVisible]);

    useEffect(() => {
        // Démarre le compte à rebours dès que le composant est monté
        let timerId = null;
        if (roueTimeLeft && roueTimeLeft !== "00:00") {
            timerId = setInterval(() => {
                calculateTimeLeft()
                // setRoueTimeLeft(calculateTimeLeft());
            }, 1000);
        }

        if(roueTimeLeft === "00:00"){
            setCanOpenRoue(true)
            clearInterval(timerId);
        }


        // Nettoie l'intervalle quand le composant est démonté
        return () => {
            clearInterval(timerId);
        };
    }, [roueTimeLeft]);

    const calculateTimeLeft = () => {
        let [minutes, seconds] = roueTimeLeft.split(':').map(Number);

        seconds -= 1;
        if (seconds < 0) {
            minutes -= 1;
            seconds = 59;
        }

        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const updatedTimeLeft = `${formattedMinutes}:${formattedSeconds}`;

        setRoueTimeLeft(updatedTimeLeft);

        return updatedTimeLeft;
    }

    const fetchContractData = async () => {
        try {
            const contratsData = await SoloService.getContrats(); // Replace with your actual API call

            setContrats(contratsData);
            setContratDataFetched(true);
        } catch (error) {
            setError({code: 1008, message: `Les contrats n'ont pas pu être chargés. Veuillez réessayer plus tard.`})
        }
    };

    const fetchLevelsContrat = async () => {
        try {
            const levelsContrat = await SoloService.getUserLevelsContrat();
            setGroupLevelsContrat(levelsContrat)

            if(levelsContrat){
                setToValdiateCount(levelsContrat.filter(value => value !== null).length)
            }
        } catch (error) {
            setError({code: 1011, message: `Les contrats n'ont pas pu être chargés. Veuillez réessayer plus tard.`})
        }
    }

    const fetchUserData = async () => {
        try {
            setUser(userData.user);
            setPoints(userData.total_points ? userData.total_points : 0);

            setRoueTimeLeft(userData.roue_time_left)
            setCanOpenRoue(userData.roue)


            setBonus1(userData.bonus1)
            setBonus2(userData.bonus2)


            // If there is no contrat finished not viewed
            if(userData.contrat_finished === null){
                setContrat(userData.contrat);
                if(userData.contrat === null){
                    setModalVisible(true)
                    setContratColor(COLORS.darkGray)
                }
                else{
                    setContratColor(getColorById(userData.contrat.contrat_id))
                }
            }
            // If there is a contrat finished not viewed : we show result
            else{
                if(userData.contrat_finished) {
                    setShowClassementAfter(true)
                    setContratFinished(userData.contrat_finished);
                    setModalContratFinishedVisible(true);
                    setContratColor(getColorById(userData.contrat_finished.contrat_id))
                }
            }

        } catch (error) {
            setError({code: 1009, message: `Les informations du profil n'ont pas pu être chargés. Veuillez réessayer plus tard.`})
        }
    }


    const handleModalOpen = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    const handleSelectContrat = async () => {
        try {
            const contratData = await SoloService.setContrat(selectedContrat)
            setContrat(contratData)
            setContratColor(contratData.contrat_id)

            if(showClassmentAfter){
                navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [
                            {
                                name: 'Classement',
                                params: { showLastWeekClassement: true }
                            },
                        ],
                    })
                );
            }


        } catch (error) {
            setError({code: 1012})
            // console.log(error)
        }
    };

    const handleOpenRoue = () => {
        setModalRoueVisible(true)
    }

    const handleSetContratViewed = async () => {
        try {
            const contratData = await SoloService.setContratFinishedViewed()

            setContratColor(COLORS.darkGray)
            setSelectedContrat(contratFinished.contrat_id)
            setModalContratFinishedVisible(false);
            setContrat(null)
            setContratFinished(null)
            setModalVisible(true);
        } catch (error) {
            setError({code: 1013})
            // console.log(error)
        }
    };

    const handlePressBonus = (bonus) => {
        setModalBonusVisible(true)
        setBonusSelected(bonus)
    }
    const handleCloseBonus = () => {
        setModalBonusVisible(false)
        setBonusSelected(null)
    }


    const renderButtonContrat = (contratObj, id, isSelected) => {
        if(contratDataFetched) {
            isSelected = contratObj === null ? (id === selectedContrat) : isSelected

            const contratSelected = contrats.find(item => item.id === id)
            const title = contratSelected.name;
            const description = contratSelected.description;
            let colors = {main: null, dark: null};

            if(id === 1){
                colors = {main: COLORS.blue, dark: COLORS.darkBlue}
            }
            else if(id === 2) {
                colors = {main: COLORS.orange, dark: COLORS.darkOrange}
            }
            else if(id === 3){
                colors = {main: COLORS.red, dark: COLORS.darkRed}
            }

            let points = null
            if(contratSelected.point_gagnant > 0 && contratSelected.point_perdant > 0) {
                points = {plus: contratSelected.point_gagnant, moins: contratSelected.point_perdant}
            }

            return (
                <TouchableOpacity
                    disabled={contratObj !== null}
                    onPress={() => setSelectedContrat(id)}
                    style={[styles.buttonContrat, {
                        borderColor: colors.main,
                        backgroundColor: isSelected ? colors.main : COLORS.white,
                    }]}
                >
                    <Text style={[styles.textContratTitle, {color: isSelected ? COLORS.white : colors.main,}]}>{title}</Text>
                    <Text style={[styles.textContratDescription, {color: isSelected ? COLORS.white : COLORS.darkerGray}]}>{description}</Text>

                    { points && (
                        <View style={{flexDirection: "row", marginTop: isLittleScreen() ? 2 : 3}}>
                            <Text style={{fontSize: SIZES.xs, marginRight: 5, color: isSelected ? COLORS.white : COLORS.green}}>{points.plus}</Text>
                            <Text style={{fontSize: SIZES.xs, marginRight: 5, color: isSelected ? COLORS.white : COLORS.red}}>{points.moins}</Text>
                        </View>
                    )}
                </TouchableOpacity>
            )
        }
        else{
            return null;
        }

    }


    const getColorById = (id) => {
        switch (id) {
            case 1:
                return COLORS.blue;
            case 2:
                return COLORS.orange;
            case 3:
                return COLORS.red;
            default:
                return COLORS.gray;
        }
    };

    const handleRoueTourner = async (id, quantity) => {
        try {
            const bonusData = await SoloService.setResultRoue(id, quantity)
            setBonus1(bonusData.bonus1)
            setBonus2(bonusData.bonus2)
            setRoueTimeLeft(bonusData.roueTimeLeft)
            setCanOpenRoue(false)

        }
        catch (error){
            setError({code: 1014})
            // console.log(error)
        }
    }

    const handleRoueClose = () => {
        setModalRoueVisible(false)
    }

    return (
        <View style={styles.container}>
            <View style={{width: '33.33%', alignItems: "flex-start"}}>
                <TouchableOpacity onPress={handleModalOpen} style={{backgroundColor: getColorById(contrat ? contrat.contrat_id : 0), paddingVertical: 3, paddingHorizontal: 7, borderRadius: ROUNDED.full}}>
                    <Text style={{color: COLORS.white, fontWeight: FONTS.bold, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES['2xs']: SIZES['xs']}} >{contrat ? contrat.contrat.name : 'Choisir'}</Text>
                </TouchableOpacity>
            </View>
            <View style={{ alignItems: 'center', width: '33.33%' }}>
                <Text style={{ fontSize: helper.isLittleScreen() ? SIZES['sm'] : SIZES['base'], color: COLORS.white, fontWeight: FONTS.black }}>{points} pts</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '33.33%' }}>
                <TouchableOpacity disabled={!canOpenRoue} onPress={handleOpenRoue} style={{ padding: 2, alignItems: 'center', marginRight:5, marginBottom: canOpenRoue ? 0 : -5 }}>
                    <Image style={{width: canOpenRoue ? (helper.isLittleScreen() ? 20 : 25) : (helper.isLittleScreen() ? 16 : 20), height: canOpenRoue ? (helper.isLittleScreen() ? 20 : 25) : (helper.isLittleScreen() ? 16 : 20)}} source={require('../../assets/general/roue.png')}/>

                    { roueTimeLeft && !canOpenRoue && (
                        <Text style={{color: COLORS.orange, fontWeight: FONTS.bold, fontSize: SIZES["3xs"]}} >{roueTimeLeft}</Text>
                    )}
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handlePressBonus(1)} style={{ padding: 1,  flexDirection: "row", alignItems: 'center', marginRight:5 }}>
                    <Image style={{width: (helper.isLittleScreen() ? 20 : 25), height: (helper.isLittleScreen() ? 20 : 25)}} source={require('../../assets/general/refresh3.png')}/>
                    <Text style={{color: COLORS.orange, fontWeight: FONTS.bold, fontSize: SIZES["2xs"]}} >{bonus1}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handlePressBonus(2)} style={{ padding: 1,  flexDirection: "row", alignItems: 'center'}}>
                    <Image style={{width: (helper.isLittleScreen() ? 20 : 25), height: (helper.isLittleScreen() ? 20 : 25)}} source={require('../../assets/general/cible.png')}/>
                    <Text style={{color: COLORS.blue, fontWeight: FONTS.bold, fontSize: SIZES["2xs"]}} >{bonus2}</Text>
                </TouchableOpacity>
            </View>

            <Modal visible={modalRoueVisible} animationType="slide" transparent={true}>
                <View style={styles.containerModal}>
                    <Roue handleTourner={handleRoueTourner} handleClose={handleRoueClose} />
                </View>
            </Modal>

            <Modal visible={modalVisible} animationType="slide" onRequestClose={handleModalClose} transparent={true}>
                <View style={styles.containerModal}>
                    <Text style={styles.textModal}>{contrat === null ? "SÉLECTIONNER UN CONTRAT" : "VOTRE CONTRAT"}</Text>
                    <View style={styles.containerContrat}>
                        { contrat === null ? (
                            <View>
                                {renderButtonContrat(contrat,1,false)}
                                {renderButtonContrat(contrat,2,false)}
                                {renderButtonContrat(contrat,3,false)}
                            </View>
                        ) : (
                            <View>
                                <View>
                                    {contrat?.contrat_id === 1 && renderButtonContrat(contrat,1, true)}
                                    {contrat?.contrat_id === 2 && renderButtonContrat(contrat,2, true)}
                                    {contrat?.contrat_id === 3 && renderButtonContrat(contrat,3, true)}
                                </View>

                                { groupLevelsContrat.length > 0 && contrat.contrat.nb_niveau_validation > 0 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contrat.contrat.nb_niveau_validation, ((groupLevelsContrat.length) >= 4 ? 4 : groupLevelsContrat.length)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index] && groupLevelsContrat[index] !== null ? COLORS.green : COLORS.darkGray}]}>
                                                <Text style={styles.textLevel}>{index + 1}</Text>

                                                { groupLevelsContrat[index] && groupLevelsContrat[index] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                { groupLevelsContrat.length > 0 && contrat.contrat.nb_niveau_validation > 4 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contrat.contrat.nb_niveau_validation, ((groupLevelsContrat.length - 4) >= 4 ? 4 : groupLevelsContrat.length - 4)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index + 4] && groupLevelsContrat[index + 4] !== null ? COLORS.green : COLORS.darkGray}]}>
                                                <Text style={styles.textLevel}>{index + 5}</Text>

                                                { groupLevelsContrat[index + 5] && groupLevelsContrat[index + 5] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index + 5]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                { groupLevelsContrat.length > 0 && contrat.contrat.nb_niveau_validation > 8 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contrat.contrat.nb_niveau_validation, ((groupLevelsContrat.length - 8) >= 8 ? 8 : groupLevelsContrat.length - 8)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index + 8] && groupLevelsContrat[index + 8] !== null ? COLORS.green : COLORS.darkGray}]}>
                                                <Text style={styles.textLevel}>{index + 9}</Text>

                                                { groupLevelsContrat[index + 9] && groupLevelsContrat[index + 9] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index + 9]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                {
                                    groupLevelsContrat.length > 0 && toValidateCount !== null && (
                                        <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['base'] : SIZES['lg'], fontWeight: FONTS.semibold}}>
                                            { toValidateCount === groupLevelsContrat.length ?
                                                "Félicitations ! Vous avez validé votre contrat" :
                                                `Il vous reste ${groupLevelsContrat.length - toValidateCount} niveaux à valider pour compléter votre contrat`
                                            }
                                        </Text>
                                    )
                                }



                            </View>
                        )}
                        <View style={{alignItems: "center"}}>
                            <CustomButton
                                onPress={contrat === null ? handleSelectContrat : handleModalClose}
                                title={contrat === null ? 'Sélectionner' : 'Fermer'}
                                width={'70%'}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal visible={modalContratFinishedVisible} animationType="slide" onRequestClose={handleSetContratViewed} transparent={true}>
                <View style={styles.containerModal}>
                    <Text style={styles.textModal}>Résultat contrat</Text>
                    <View style={styles.containerContrat}>
                        { contratFinished !== null && (
                            <View>
                                <View>
                                    {contratFinished?.contrat_id === 1 && renderButtonContrat(contratFinished,1, true)}
                                    {contratFinished?.contrat_id === 2 && renderButtonContrat(contratFinished,2, true)}
                                    {contratFinished?.contrat_id === 3 && renderButtonContrat(contratFinished,3, true)}
                                </View>

                                { groupLevelsContrat.length > 0 && contratFinished.contrat.nb_niveau_validation > 0 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contratFinished.contrat.nb_niveau_validation, ((groupLevelsContrat.length) >= 4 ? 4 : groupLevelsContrat.length)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index] && groupLevelsContrat[index] !== null ? COLORS.green : COLORS.red}]}>
                                                <Text style={styles.textLevel}>{index + 1}</Text>

                                                { groupLevelsContrat[index] && groupLevelsContrat[index] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                { groupLevelsContrat.length > 0 && contratFinished.contrat.nb_niveau_validation > 4 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contratFinished.contrat.nb_niveau_validation, ((groupLevelsContrat.length - 4) >= 4 ? 4 : groupLevelsContrat.length - 4)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index + 4] && groupLevelsContrat[index + 4] !== null ? COLORS.green : COLORS.red}]}>
                                                <Text style={styles.textLevel}>{index + 5}</Text>

                                                { groupLevelsContrat[index + 5] && groupLevelsContrat[index + 5] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index + 5]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                { groupLevelsContrat.length > 0 && contratFinished.contrat.nb_niveau_validation > 8 && (
                                    <View style={styles.containerContratLevels}>
                                        {[...Array(Math.min(contratFinished.contrat.nb_niveau_validation, ((groupLevelsContrat.length - 8) >= 8 ? 8 : groupLevelsContrat.length - 8)))].map((_, index) => (
                                            <View key={index} style={[styles.containerLevel, {backgroundColor: groupLevelsContrat[index + 8] && groupLevelsContrat[index + 8] !== null ? COLORS.green : COLORS.red}]}>
                                                <Text style={styles.textLevel}>{index + 9}</Text>

                                                { groupLevelsContrat[index + 9] && groupLevelsContrat[index + 9] !== null && (
                                                    <Text style={styles.textLevelCount}>{groupLevelsContrat[index + 9]['valid_count']}/5</Text>
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                )}

                                {
                                    groupLevelsContrat.length > 0 && (
                                        <View>
                                            { contratFinished.is_valid === 1 ?
                                                (
                                                    <View style={{alignItems: 'center'}}>
                                                        <Text style={{color: COLORS.green, fontWeight: FONTS.black, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['3xl']}}>+{contratFinished.contrat.point_gagnant}pts</Text>
                                                        <FontAwesome name="smile-o" size={helper.isLittleScreen() ? 35 : 45} color={COLORS.black} style={{marginVertical: helper.isLittleScreen() ? 5 : 10}} />
                                                        <Text style={{color: COLORS.black1, fontWeight: FONTS.bold, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['3xl']}}>Félicitation !</Text>
                                                    </View>
                                                ) : (
                                                    <View style={{alignItems: 'center'}}>
                                                        <Text style={{color: COLORS.red, fontWeight: FONTS.black, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES['2xl'] : SIZES['3xl']}}>-{contratFinished.contrat.point_perdant}pts</Text>
                                                        <FontAwesome name="frown-o" size={helper.isLittleScreen() ? 35 : 45} color={COLORS.black} style={{marginVertical: helper.isLittleScreen() ? 5 : 10}} />
                                                        <Text style={{color: COLORS.black1, fontWeight: FONTS.bold, textAlign: "center", fontSize: helper.isLittleScreen() ? SIZES['sm'] : SIZES['base']}}>Vous n'avez pas validé les niveaux requis pour compléter le contrat</Text>
                                                    </View>
                                                )
                                            }
                                        </View>
                                    )
                                }



                            </View>
                        )}
                        <View style={{alignItems: "center"}}>
                            <CustomButton
                                onPress={handleSetContratViewed}
                                title={'Continuer'}
                                width={'70%'}
                            />
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal visible={modalBonusVisible} animationType="slide" transparent={true}>
                <View style={styles.containerModal}>
                    <Text style={styles.textModal}>Bonus</Text>

                    <View style={styles.containerContrat}>
                        { bonusSelected === 1 && (
                            <View style={{flex: 1, alignItems: "center", padding: 5, justifyContent: "center"}}>
                                <Image style={{width: helper.isLittleScreen() ? 60 : 70, height: helper.isLittleScreen() ? 60 : 70, marginBottom: isLittleScreen() ? 8 : 15}} source={require('../../assets/general/refresh_xxl.png')}/>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl'], fontWeight: FONTS.semibold, marginVertical: isLittleScreen() ? 8 : 15}}>
                                    Switch
                                </Text>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], marginBottom: isLittleScreen() ? 3 : 5}}>
                                    Change la question actuelle d’un niveau par une autre aléatoirement.
                                </Text>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], fontWeight: FONTS.semibold}}>
                                    N’est pas possible pour les niveaux de validation
                                </Text>
                            </View>
                        )}
                        { bonusSelected === 2 && (
                            <View style={{flex: 1, alignItems: "center", padding: 5, justifyContent: "center"}}>
                                <Image style={{width: helper.isLittleScreen() ? 80 : 90, height: helper.isLittleScreen() ? 80 : 90, marginBottom: isLittleScreen() ? 8 : 15}} source={require('../../assets/general/cible_xxl.png')}/>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl'], fontWeight: FONTS.semibold, marginVertical: isLittleScreen() ? 8 : 15}}>
                                    Cible
                                </Text>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], marginBottom: isLittleScreen() ? 5 : 10}}>
                                    Sélectionne la bonne réponse ou une des bonnes réponses de la question.
                                </Text>
                                <Text style={{textAlign: "center", fontSize: isLittleScreen() ? SIZES['xs'] : SIZES['sm'], fontWeight: FONTS.semibold}}>
                                    N’est pas possible pour les niveaux de validation
                                </Text>
                            </View>
                        )}

                        <View style={{alignItems: "center"}}>
                            <CustomButton
                                onPress={handleCloseBonus}
                                title={"C'est compris"}
                                width={'70%'}
                            />
                        </View>
                    </View>
                </View>
            </Modal>
            <ErrorNotification
                isVisible={error !== null}
                code={error?.code}
                message={error?.message}
                onClose={() => setError(null)}
            />
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingTop: helper.isLittleScreen() ? 5 : 10,
        paddingBottom: helper.isLittleScreen() ? 5 : 10,
        backgroundColor: COLORS.black1
    },
    containerModal : {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 30,
        backgroundColor: 'rgba(24,24,27,0.8)'
    },
    textModal : {
        textTransform: "uppercase",
        color: COLORS.white,
        fontWeight: FONTS.bold,
        marginBottom: isLittleScreen() ? 15 : 30,
        fontSize: isLittleScreen() ? SIZES['xl'] : SIZES['2xl']
    },
    containerContrat: {
        justifyContent: "space-between",
        height: isLittleScreen() ? '70%' : '60%',
        padding: isLittleScreen() ? 8 : 10,
        width: '100%',
        maxWidth: 400,
        backgroundColor: COLORS.white,
        borderRadius: ROUNDED.xl
    },
    buttonContrat : {
        padding: isLittleScreen() ? 8 : 10,
        borderWidth: 2,
        borderRadius: ROUNDED.xl,
        justifyContent: "center",
        height: isLittleScreen() ? 70 : 90,
        marginBottom: isLittleScreen() ? 15 : 30
    },
    textContratTitle : {
        fontWeight: FONTS.bold,
        fontSize: isLittleScreen() ? SIZES.base : SIZES.lg
    },
    textContratDescription : {
        fontSize: isLittleScreen() ? SIZES.xs : SIZES.sm
    },
    containerContratLevels : {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    containerLevel : {
        width: helper.isLittleScreen() ? 50 : 60,
        margin: 5,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ROUNDED.lg,
        aspectRatio: 1,
        backgroundColor: COLORS.darkGray,
        position: "relative"
    },
    textLevel : {
        color: COLORS.black,
        fontSize: SIZES["xl"],
        fontWeight: FONTS.bold,
    },
    textLevelCount : {
        color: COLORS.black,
        fontSize: SIZES["3xs"],
        position: "absolute",
        bottom: 0.5
    },

});

export default Menu;
